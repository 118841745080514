import React, { FC, ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import { Permissions } from '../../screens/main-admin-page/model/permissions-formatter';
import { useNavigate } from 'react-router-dom';
import { pages } from './menu-buttons-data';

interface MenuContextProps {
  children: ReactNode;
  permission: Permissions | string | null;
}

export const MenuContext: FC<MenuContextProps> = (props) => {
  const token = localStorage.getItem('access');
  const navigate = useNavigate();
  // const parts = window.location.pathname.split('/');
  // const path = parts[parts.length - 1];
  let routeMenuArray: any[] = [];
  const [buttonState, setButtonState] = useState();

  const permission: string | null = localStorage.getItem('permission');

  const permissionArr = permission?.split(',');

  routeMenuArray = pages.filter((elem) =>
    permissionArr?.includes(elem.permission)
  );

  const handlePress = (button: any) => {
    navigate(`${button.page}`, {
      state: {
        link: button.link,
      },
    });
    setButtonState(button);
  };

  return (
    <Grid minH="100vh" p={3}>
      <VStack w="full" spacing={2} bg="white" align="flex-start">
        <VStack align="flex-start" h="full">
          <Flex>
            {token && routeMenuArray.length > 1 && (
              <Center
                w="240px"
                bg="#21284C"
                h="70rem"
                alignItems="flex-start"
                justifyContent="flex-end"
                paddingTop="35px"
              >
                <Grid templateColumns="1">
                  {routeMenuArray.map((button) => (
                    <GridItem key={button.id} marginTop="27px">
                      <Button
                        style={{
                          width: button.page === '/cook' ? '180px' : '200px',
                          marginLeft: button.page === '/cook' ? '50px' : '30px',
                          borderRadius: '3px',
                        }}
                        color="white"
                        bg={button === buttonState ? '#2557B8' : '#5A6184'}
                        h="50px"
                        marginLeft="27px"
                        w="213px"
                        whiteSpace="initial"
                        onClick={() => handlePress(button)}
                      >
                        {button.title}
                      </Button>
                    </GridItem>
                  ))}
                </Grid>
              </Center>
            )}
            <Box marginLeft="30px">{props.children}</Box>
          </Flex>
        </VStack>
      </VStack>
    </Grid>
  );
};
