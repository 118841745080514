import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';

interface ButtonNewInterface {
  title: string;
  onClick: () => void;
  isDisabled?: boolean;
}

export const ButtonNew: FC<ButtonNewInterface> = ({
  title,
  onClick,
  isDisabled,
}) => (
  <Button disabled={isDisabled} colorScheme="blue" onClick={onClick}>
    {title}
  </Button>
);
