import {
  ShopStateEmpty,
  ShopStateError,
  ShopStateHasData,
  ShopStateInitial,
  ShopStateLoading,
} from './state-creator';
import { ShopArray } from '../../interface';

export function getInitialState(): ShopStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): ShopStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): ShopStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): ShopStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(response: ShopArray): ShopStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
