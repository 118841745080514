import {
  MainManagerPageStateEmpty,
  MainManagerPageStateError,
  MainManagerPageStateHasData,
  MainManagerPageStateInitial,
  MainManagerPageStateLoading,
} from './cooking-page-state';
import { OrderItem } from '../interface/types';
import { Product } from './product';

export function getInitialState(): MainManagerPageStateInitial {
  return { type: 'INITIAL', data: null, error: null };
}

export function getLoadingState(data: []): MainManagerPageStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): MainManagerPageStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): MainManagerPageStateError {
  return { type: 'ERROR', data: null, error };
}

export function getHasDataState(
  response: Array<OrderItem>
): MainManagerPageStateHasData {
  return {
    type: 'HAS_DATA',
    data: response.map((data) => new Product(data)),
    error: null,
  };
}
