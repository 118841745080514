import {
  AdminPageStateEmpty,
  AdminPageStateError,
  AdminPageStateHasData,
  AdminPageStateInitial,
  AdminPageStateLoading,
} from './admin-page-state';
import { UsersArray } from '../interface/types';

export function getInitialState(): AdminPageStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): AdminPageStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): AdminPageStateEmpty {
  return { type: 'EMPTY', data: {}, error: null };
}

export function getErrorState(error: Error): AdminPageStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(response: UsersArray): AdminPageStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
