import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { styles } from './product-weight-block-styles';
import { Block } from '../../../interface/type';

export const ProductWeightBlock: FC<Block> = ({ title }) => (
  <Box as="button" style={styles.workShopBody}>
    <Text style={styles.textWorkShow}>{title}</Text>
  </Box>
);
