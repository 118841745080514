export const styles = {
  rowFlex: {
    alightItem: 'center',
    justifyContent: 'center',
  },
  cancelButton: {
    marginTop: '50px',
  },
  titleStyleFont: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: 'Montserrat',
    color: '#1D2532',
  },
  title: {
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'Montserrat',
    color: '#000000',
    marginTop: '5px',
    marginRight: '50px',
  },
};
