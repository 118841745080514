import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';

interface ButtonAddProps {
  onClick?: () => void;
  isActiveAssemble: boolean;
  title: string;
}

export const ButtonAdd: FC<ButtonAddProps> = ({
  onClick,
  isActiveAssemble,
  title,
}) => (
  <Button
    onClick={onClick}
    colorScheme={isActiveAssemble ? 'blue' : 'gray'}
    variant={isActiveAssemble ? 'solid' : 'outline'}
    whiteSpace="initial"
  >
    {title}
  </Button>
);
