import { NomenclatureType } from '../../packing-shop/interface/interface';

export type Block = {
  title: number;
  gray?: boolean;
};

export type ButtonType = {
  title: string;
};

export type ForProductDTO = {
  id: number;
  productTitle: string;
};

export enum CookingPlace {
  Initial = '',
  Cold = 'COLD',
  Hot = 'HOT',
  Meat = 'MEAT',
  Vegetables = 'VEGETABLES',
}

export type AssemblyChartsItemsArray = Array<AssemblyChartsItemsResponse>;
export type AssemblyProductDetailArray = Array<AssemblyProductDetailResponse>;
export type DateArray = Array<DateIsApproved>;

export type AssemblyChartsItemsResponse = {
  id: number;
  productTitle: string;
  count: number;
  complete: number;
  orderPacking?: number | null;
  orderCooking?: number | null;
  hasChildren: boolean;
  forProduct: ForProductDTO;
  wait: [CookingPlace];
  label: string | null;
  available: number;
  type: NomenclatureType;
};

export type AssemblyProductDetailResponse = {
  id: number;
  productTitle: string;
  count: number;
  complete: number;
  children: AssemblyChartItemChildResponse[] | [];
};

export type AssemblyChartItemChildResponse = {
  id: number;
  productTitle: string;
  weightCooked: number;
  weightPerItem: number;
  cookingPlacesData: CookingPlacesData[];
  weightRaw: number;
  count: number;
  complete: number;
  cookingPlaceType: CookingPlace;
  children: AssemblyChartItemChildResponse[] | [];
  available: number;
};

export type CookingPlacesData = {
  cookingPlace: CookingPlace;
  count: number;
  complete: number;
};

export type DateIsApproved = {
  date: string;
  isApproved: boolean;
};
