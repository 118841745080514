/**
 * Subscription interface
 */
export interface ISubscription {
  /**
   * Removes a subscription and stops notifying a subscriber
   */
  unsubscribe: () => void;
}

/**
 * Subscriber interface
 */
export interface ISub<T> {
  subscribe: (this: this, subscriber: (value: T) => void) => ISubscription;
}

/**
 * Publisher interface
 */
export interface IPub<T> {
  next: (this: this, value: T) => void;
}

export class PubSub<T> implements IPub<T>, ISub<T> {
  private readonly subscribers = new Set<(value: T) => void>();

  public subscribe(this: this, subscriber: (value: T) => void): ISubscription {
    this.subscribers.add(subscriber);
    return {
      unsubscribe: () => {
        this.subscribers.delete(subscriber);
      },
    };
  }

  public next(value: T) {
    // eslint-disable-next-line no-restricted-syntax
    for (const subscriber of this.subscribers) {
      subscriber(value);
    }
  }
}
