import React, { FC } from 'react';
import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { styles } from './modal-styles';
import { ButtonNew } from '../../../../components/core/button';
import Select from 'react-select';
import { KitchenChiefModel } from '../../model';

interface ModalElementInterface {
  modalTitle: string;
  isOpen: boolean;
  modalClose: () => void;
  quantity: number;
  id: number;
}

export const ModalSplitIcon: FC<ModalElementInterface> =
  KitchenChiefModel.modelClient((props) => {
    const handleEdit = () => {
      props.model.splitOrder(props.id, props.modalClose);
    };

    const options = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ];

    return (
      <Modal onClose={props.modalClose} isOpen={props.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid spacing="20px">
              <Flex>
                <Text style={styles.title} w="105px">
                  Общее количество
                </Text>
                <Text style={styles.title} fontSize="2rem">
                  {props.quantity}
                </Text>
              </Flex>

              <Flex>
                <Text style={styles.title}>Деление</Text>
                {/* here */}
                <Select
                  options={options}
                  onChange={(data) => {
                    if (data !== null) {
                      props.model.handleSelect(props.quantity, data.value);
                    }
                  }}
                />
              </Flex>
              {Array.from(props.model.parts.values()).map((item, i) => (
                <Input
                  key={`keyIndex${i}`}
                  value={item.inputValue}
                  onChange={(e) => item.setValue(e.target.value)}
                  onBlur={() => props.model.setError('')}
                />
              ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <ButtonNew onClick={handleEdit} title="Сохранить" />
            <Text fontSize="15px" color="tomato" marginLeft="20px">
              {props.model.errorMessage}
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  });
