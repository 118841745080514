import React, { FC } from 'react';
import {
  Button,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { styles } from './modal-delete-styles';
import { ButtonNew } from '../../button';

interface ModalElementInterface {
  modalTitle: string;
  isOpen: boolean;
  modalClose: () => void;
  handleDelete: () => void;
}

export const ModalDelete: FC<ModalElementInterface> = (props) => {
  function handleDeleteUser() {
    props.modalClose();
    props.handleDelete();
  }
  return (
    <Modal onClose={props.modalClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          style={styles.titleStyleFont}
        >{`Удалить пользователя ${props.modalTitle}?`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={6} />
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button style={styles.cancelButton} onClick={props.modalClose}>
              Отменить
            </Button>
            <div style={{ marginTop: '50px', marginLeft: '30px' }}>
              <ButtonNew onClick={handleDeleteUser} title="Да, удалить" />
            </div>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
