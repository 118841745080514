import { makeAutoObservable } from 'mobx';
import { Plan } from '../../interface';

export class PlanClassResponse {
  public constructor(public plan: Plan) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public quantity() {
    return this.plan.count;
  }

  public setQuantity(data: string) {
    this.plan.count = Number(data);
  }
}
