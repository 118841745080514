export const styles = {
  rowFlex: {
    alightItem: 'center',
    justifyContent: 'center',
  },
  cancelButton: {
    marginTop: '50px',
  },
  titleStyleFont: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: 'Montserrat',
    color: '#1D2532',
  },
  title: {
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'Montserrat',
    color: '#000000',
    marginTop: '5px',
    marginRight: '50px',
  },

  numberBox: {
    width: '80px',
    height: '80px',
    backgroundColor: '#EBEBEB',
    borderRadius: '4px',
    alightItem: 'center',
    justifyContent: 'center',
  },
  buttonTextStyle: {
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '48px',
    fontFamily: 'Montserrat',
    color: '#21284C',
  },

  inputText: {
    width: '350px',
    height: '110px',
    borderColor: '#C11D1D',
    borderWidth: '1.5px',
    fontFamily: 'Montserrat',
    fontSize: '50px',
    fontWeight: '400',
    lineHeight: '54px',
    color: '#000000',
  },

  inputTextError: {
    width: '350px',
    height: '110px',
    borderColor: '#C11D1D',
    borderWidth: '1.5px',
    fontFamily: 'Montserrat',
    fontSize: '50px',
    fontWeight: '400',
    lineHeight: '54px',
    color: '#C11D1D',
  },

  volumeNumbers: {
    fontWeight: '400',
    fontSize: '25px',
    lineHeight: '30px',
    fontFamily: 'Montserrat',
    color: '#000000',
    marginLeft: '12px',
  },

  checkBox: {
    marginTop: '108px',
  },

  workShopBodyInitial: {
    backgroundColor: '#2557B8',
    paddingTop: '18px',
    paddingBottom: '18px',
    paddingLeft: '52.5px',
    paddingRight: '52.5px',
    borderRadius: '4px',
    width: '291px',
    height: '60px',
    alightItem: 'center',
    justifyContent: 'center',
    lineHeight: '20px',
    fontWeight: '400',
    fontSize: '20px',
    color: '#F2F2F2',
    fontFamily: 'Montserrat',
  },
  textWorkShow: {
    lineHeight: '24px',
    fontWeight: '500',
    fontSize: '20px',
    color: '#F2F2F2',
    fontFamily: 'Montserrat',
  },
};
