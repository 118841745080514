import { makeAutoObservable } from 'mobx';
import { PlanResponse } from '../../interface';
import { PlanClassResponse } from './plan-class-response';

export class PlanClass {
  // private readonly _httpService = new HttpService();

  private _isHidePlanElements = false;

  public get isHidePlanElements() {
    return this._isHidePlanElements;
  }

  public setIsHidePlanElements() {
    if (this._isHidePlanElements === false) {
      this._isHidePlanElements = true;
    } else {
      this._isHidePlanElements = false;
    }
  }

  public constructor(public source: PlanResponse) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public plan() {
    return this.source.plan.map((data) => new PlanClassResponse(data));
  }
}
