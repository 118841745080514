import { useEffect, useMemo, useState } from 'react';
import { AssemblyChartsItemsArray } from '../interface/type';

export const useFilter = (arr: any) => {
  const [filterData, setFilterData] = useState<AssemblyChartsItemsArray>([]);
  const [masterData, setMasterData] = useState<AssemblyChartsItemsArray>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilterData(arr);
    setMasterData(arr);
  }, [arr]);

  const searchFilter = (data: any) => {
    const text = data.target.value;
    if (text) {
      const newData = masterData.filter(
        (filteredItem: { productTitle: string }) => {
          const itemData = filteredItem.productTitle
            ? filteredItem.productTitle.toUpperCase()
            : ''.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
        }
      );
      setFilterData(newData);
      setSearch(text);
    } else {
      setFilterData(masterData);
      setSearch(text);
    }
  };

  const clearFilter = () => {
    setMasterData([]);
    setFilterData([]);
    setSearch('');
  };

  return useMemo(
    () => ({
      filterData,
      search,
      searchFilter,
      clearFilter,
    }),
    [filterData, search, searchFilter, clearFilter]
  );
};
