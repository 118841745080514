import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CookPageModel } from '../model';
import { PlateWithNumber } from '../../../components/core/plate-with-number';
import { PeriodicityBlock } from './periodicity-block/periodicity-block';
import { ModalKitchener } from './modal-with-blur/modal-kitchener';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { useSessionActions } from '../../../services/session-action/session-action';
import {
  AssemblyChartsItemsArray,
  CookingPlace,
  ForProductDTO,
} from '../interface/type';
import { useFilter } from '../hooks/useFilter';
import Select from 'react-select';
import { NomenclatureType } from '../../packing-shop/interface/interface';
import { PlanValueSelect } from '../../production-plan/interface';

let linkPage = '';
let planSave: PlanValueSelect | undefined;
let filterButtonSave: NomenclatureType | undefined;

export const CookPageView = CookPageModel.modelClient((props) => {
  const { signOut } = useSessionActions();
  const initialArray: AssemblyChartsItemsArray | [] | Record<string, never> =
    props.model.cookingPlace.data;

  let filteredArray: AssemblyChartsItemsArray | [] | Record<string, never> =
    initialArray;

  const { searchFilter, search, filterData, clearFilter } =
    useFilter(filteredArray);

  const permission = localStorage.getItem('permission');
  const statusOptions = props.model.dates.data.map((data) => ({
    label: `Заказ на ${data.date} - ${
      data.isApproved ? 'Утвержден' : 'Не утвержден'
    }`,
    status: data.isApproved,
    date: data.date,
  }));
  const styleMap: any = {
    false: '#C11D1D',
    true: '#2557B8',
  };
  const colourStyles: any = {
    singleValue: (
      provided: {
        boxSizing: string;
        color: string;
        gridArea: string;
        label: string;
        marginLeft: number;
        marginRight: number;
        maxWidth: string;
        overflow: string;
        textOverflow: string;
        whiteSpace: string;
      },
      { data }: { data: { date: string; label: string; status: string } }
    ) => ({
      ...provided,
      color: styleMap[data.status] ?? 'defaultColor',
      fontWeight: 'bold',
    }),
  };

  const arrString = JSON.stringify(props.model.cookingPlace.data);
  const normalArray: AssemblyChartsItemsArray = JSON.parse(arrString);

  const handlePressFilterButton = async (data: NomenclatureType) => {
    const handlePressButton = async () => {
      await props.model.getCookingPlace();
      filteredArray = props.model.cookingPlace.data.filter(
        (el) => el.type === data
      );
      props.model.setCookingDate(filteredArray);
      return props.model.setFilterButton(data);
    };

    const handleUnclickButton = async () => {
      await props.model.getCookingPlace();
      filteredArray = initialArray;
    };

    switch (props.model.filterButton) {
      case NomenclatureType.Dish:
        if (data === NomenclatureType.Prepared) {
          return handlePressButton();
        }
        handleUnclickButton();
        return props.model.setFilterButton(NomenclatureType.UNKNOWN);
        break;
      case NomenclatureType.UNKNOWN:
        filteredArray = normalArray.filter((el) => el.type === data);
        props.model.setCookingDate(filteredArray);
        return props.model.setFilterButton(data);
        break;
      case NomenclatureType.Prepared:
        if (data === NomenclatureType.Dish) {
          return handlePressButton();
        }
        handleUnclickButton();
        return props.model.setFilterButton(NomenclatureType.UNKNOWN);
        break;
      default:
        return null;
        break;
    }
  };
  const { state }: any = useLocation();

  if (state !== null) {
    const { link, savedData, filterButton } = state;
    if (savedData) {
      planSave = savedData;
    }
    if (filterButton) {
      filterButtonSave = filterButton;
    }
    linkPage = link;
    props.model.setLink(linkPage);
  }

  useEffect(() => {
    if (filterButtonSave) {
      props.model.setFilterButton(filterButtonSave);
    }
  }, [filterButtonSave]);

  useEffect(() => {
    if (planSave) {
      props.model.setPlanDate(planSave);
      props.model.setDate(planSave.date);
    }
  }, [planSave]);

  useEffect(() => {
    props.model.getCookDataByClickOnPage(linkPage);
    console.log('linkPage', linkPage);
  }, [linkPage]);

  function getCookPlace() {
    switch (linkPage) {
      case `HOT`:
        return 'Горячий цех';
      case 'COLD':
        return 'Холодный цех';
      case 'VEGETABLES':
        return 'Овощной цех';
      case 'CONFECTIONERY':
        return 'Кондитерский цех';
      case 'MEAT':
        return 'Мясной цех';
      default:
        return 'Цех';
    }
  }

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        {permission && <Heading>{getCookPlace()}</Heading>}

        <Flex w="50rem" alignItems="center" justifyContent="center">
          <Button
            // disabled={props.model.filterButton === NomenclatureType.Prepared}
            colorScheme={
              props.model.filterButton === NomenclatureType.Dish
                ? 'blue'
                : 'gray'
            }
            // variant="outline"
            style={{ fontFamily: 'Montserrat' }}
            onClick={() => handlePressFilterButton(NomenclatureType.Dish)}
          >
            Блюда
          </Button>
          <Box marginLeft="20px" />

          <Button
            // disabled={props.model.filterButton === NomenclatureType.Dish}
            colorScheme={
              props.model.filterButton === NomenclatureType.Prepared
                ? 'blue'
                : 'gray'
            }
            // variant="outline"
            style={{ fontFamily: 'Montserrat' }}
            onClick={() => handlePressFilterButton(NomenclatureType.Prepared)}
          >
            Полуфабрикаты
          </Button>
          <Box marginLeft="20px" />
          <Box style={{ width: '350px' }}>
            {props.model.selectedPlanValue &&
              props.model.dates.type === 'HAS_DATA' && (
                <Select
                  isSearchable={false}
                  value={props.model.selectedPlanValue}
                  placeholder="Выбрать дату"
                  styles={colourStyles}
                  options={statusOptions}
                  onChange={(data: any) => {
                    if (data) {
                      props.model.setDate(data.date);
                      props.model.setPlanDate(data);
                    }
                  }}
                />
              )}
          </Box>
        </Flex>

        <SimpleGrid>
          <Button colorScheme="red" onClick={signOut}>
            Выйти
          </Button>
        </SimpleGrid>
      </Flex>
      <Box h="24px" />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="tel"
          placeholder="Начните вводить название продукта"
          onChange={searchFilter}
          value={search}
        />
      </InputGroup>
      <Box h="24px" />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">Продукт</Th>
            <Th textAlign="center">Осталось приготовить</Th>
            <Th textAlign="center">Очередность</Th>
            <Th textAlign="center"> Для какого блюда</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {filterData &&
            filterData?.map((item) => (
              <ItemRow
                key={item.id}
                {...item}
                model={props.model}
                clearFilter={clearFilter}
              />
            ))}
        </Tbody>
      </Table>
    </div>
  );
});

type ItemRowProps = {
  id: number;
  productTitle: string;
  count: number;
  complete: number;
  orderPacking?: number | null;
  orderCooking?: number | null;
  hasChildren: boolean;
  forProduct: ForProductDTO;
  wait: [CookingPlace];
  label: string | null;
  model: CookPageModel;
  available: number;
  clearFilter: () => void;
};

const ItemRow = (props: ItemRowProps) => {
  const navigate = useNavigate();

  const permission = localStorage.getItem('permission');
  const permissionArr = permission?.split(',');

  const department = permission.split('_').shift();
  const stringData = JSON.stringify(props.model.selectedPlanValue);
  let savedData = '';
  if (stringData) {
    savedData = JSON.parse(stringData);
  } else {
    savedData = '';
  }

  const navigateToProductDetails = (id: number, parentCount: number) => {
    navigate(`/product/${id}`, {
      state: {
        parentCount,
        previousPage: '/cook',
        link: permissionArr?.length === 1 ? department : linkPage,
        savedData,
        filterButton: props.model.filterButton,
      },
    });
  };

  function round(value: number, step: number) {
    // eslint-disable-next-line no-unused-expressions
    step || (step = 1.0);
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
  }

  return (
    <>
      <Tr key={props.id}>
        <Td
          onClick={() => navigateToProductDetails(props.id, props.count)}
          textAlign="left"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link>{props.productTitle}</Link>
        </Td>
        <Td textAlign="left">
          <Center>
            <PlateWithNumber
              title={round(props.count - props.complete, 0.0001)}
            />
          </Center>
        </Td>
        <Td isNumeric>
          <Center>
            {props.orderCooking && (
              <PeriodicityBlock title={props.orderCooking} />
            )}
          </Center>
        </Td>
        <Td
          onClick={() => navigateToProductDetails(props.id, props.count)}
          textAlign="left"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link>{props?.forProduct?.productTitle}</Link>
        </Td>
        <Td>
          <Center>
            <ModalKitchener
              available={props.available}
              onChangeCount={props.model.setCookCount}
              modalTitle={props?.forProduct?.productTitle}
              onChangeLabel={props.model.setIsAddLabel}
              handleSubmit={() =>
                props.model.completeAssemblyChartItem(props.id)
              }
            />
          </Center>
        </Td>
      </Tr>
    </>
  );
};
