import React from 'react';
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  Icon,
  SearchIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { MainAdminPageModel } from '../model';
import { ModalEdit } from '../../../components/core/modal/modal-edit-user';
import { ModalDelete } from '../../../components/core/modal/modal-delete-user';
import { ModalAddUser } from '../../../components/core/modal/modal-add-user';
import {
  Permissions,
  permissionsFormatter,
} from '../model/permissions-formatter';
import { useSessionActions } from '../../../services/session-action/session-action';
import { useFilter } from '../hooks/useFilter';
import { ModalEditPassword } from '../../../components/core/modal/modal-edit-password-user';
import uuid from 'react-uuid';

const CircleIcon = () => (
  <Icon width="8px" viewBox="0 0 16 16">
    <circle r="4" cx="8" cy="8" />
  </Icon>
);

export const MainAdminPage = MainAdminPageModel.modelClient((props) => {
  const handleInputChangeName = (e: any) => {
    const inputValue = e.target.value;
    props.model.setName(inputValue);
  };

  const handleInputChangeLogin = (e: any) => {
    const inputValue = e.target.value;
    props.model.setLogin(inputValue);
  };

  const handleInputChangePassword = (e: any) => {
    const inputValue = e.target.value;
    props.model.setPassword(inputValue);
  };

  const handleInputChangeAccess = (access: Permissions) => {
    if (props.model.permissions.includes(access)) {
      console.log(props.model.permissions.includes(access));
      props.model.deletePermission(access);
    } else {
      props.model.setPermissions(access);
    }
  };

  const [isModalAddUserOpen, setIsModelAddUserOpen] = React.useState(false);
  function handleModalAddUserOpen() {
    setIsModelAddUserOpen(true);
  }

  function handleModalAddUserClose() {
    setIsModelAddUserOpen(false);
  }

  const { signOut } = useSessionActions();

  const { search, searchFilter, filterData } = useFilter(
    props.model.usersList.data
  );

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading>Список пользователей</Heading>
        <SimpleGrid>
          <Button
            colorScheme="blue"
            onClick={handleModalAddUserOpen}
            leftIcon={<AddIcon />}
          >
            Добавить
          </Button>
          <Button marginTop="12px" colorScheme="red" onClick={signOut}>
            Выйти
          </Button>
        </SimpleGrid>
      </Flex>
      <Box h="24px" />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="tel"
          placeholder="Начните вводить имя пользователя"
          onChange={searchFilter}
          value={search}
        />
      </InputGroup>
      <Box h="24px" />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="left">Имя</Th>
            <Th textAlign="left">Логин</Th>
            <Th textAlign="left">Пароль</Th>
            <Th textAlign="center">Уровень доступа</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {filterData &&
            filterData.map((item) => (
              <ItemRow key={item.id} {...item} model={props.model} />
            ))}
        </Tbody>
      </Table>

      <ModalAddUser
        handleAddUser={props.model.createUser}
        modalTitle="Add user"
        isOpen={isModalAddUserOpen}
        modalClose={handleModalAddUserClose}
        handleInputChangeName={handleInputChangeName}
        handleInputChangeLogin={handleInputChangeLogin}
        handleInputChangePassword={handleInputChangePassword}
        handleInputChangeAccess={handleInputChangeAccess}
        buttonDisable={props.model.isButtonDisabled}
        activeButtons={props.model.permissions}
      />
    </div>
  );
});

type ItemRowProps = {
  id: number;
  name: string;
  login: string;
  permissions: Array<Permissions>;
  model: MainAdminPageModel;
};

const ItemRow = (props: ItemRowProps) => {
  const [isModalEditOpen, setModalEditOpen] = React.useState(false);
  const [isModalDeleteOpen, setIsModelDeleteOpen] = React.useState(false);

  function handleModalOpen() {
    setModalEditOpen(true);
  }

  function handleModalClose() {
    setModalEditOpen(false);
  }

  function handleModalDeleteOpen() {
    setIsModelDeleteOpen(true);
  }

  function handleModalDeleteClose() {
    setIsModelDeleteOpen(false);
  }

  function handleDeleteItem(item: number) {
    props.model.deleteUser(item);
  }

  const handleInputChangeName = (data: string) => {
    props.model.setName(data);
  };

  const handleInputChangeLogin = (data: string) => {
    props.model.setLogin(data);
  };

  const handleInputChangePassword = (password: string) => {
    props.model.setPassword(password);
  };

  const handleInputChangeAccess = (access: Permissions) => {
    if (props.model.permissions.includes(access)) {
      console.log(props.model.permissions.includes(access));
      props.model.deletePermission(access);
    } else {
      props.model.setPermissions(access);
    }
  };

  return (
    <>
      <Tr>
        <Td>{props.name}</Td>
        <Td>{props.login}</Td>
        <Td>
          <Flex alignItems="center">
            <CircleIcon />
            <CircleIcon />
            <CircleIcon />
            <CircleIcon />
            <CircleIcon />
            <CircleIcon />

            <Box w="8px" />
            {/* <IconButton */}
            {/*  size="sm" */}
            {/*  variant="ghost" */}
            {/*  aria-label="Show password" */}
            {/*  icon={<ViewIcon height={4} width={4} color="#00000072" />} */}
            {/* /> */}
            <ModalEditPassword
              modalTitle={`Изменить пароль у ${props.name}?`}
              handleInputChangePassword={handleInputChangePassword}
              handlePress={() => props.model.changeUserPassword(props.id)}
            >
              <EditIcon />
            </ModalEditPassword>
          </Flex>
        </Td>

        {props.permissions[0] && (
          <Td textAlign="center">
            {props.permissions.map((data, index) => (
              <Text textAlign="left" key={uuid()}>
                {index + 1}. {permissionsFormatter(data).toString()}{' '}
              </Text>
            ))}
          </Td>
        )}

        <Td width={120}>
          <ButtonGroup spacing="4px">
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Search database"
              icon={<EditIcon height={4} width={4} color="#00000072" />}
              onClick={() => handleModalOpen()}
            />

            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Search database"
              icon={<DeleteIcon height={4} width={4} color="#00000072" />}
              onClick={handleModalDeleteOpen}
            />
          </ButtonGroup>
        </Td>
      </Tr>
      <ModalEdit
        nameData={props.name}
        login={props.login}
        handleEditUser={() => props.model.editUser(props.id)}
        modalTitle="edit"
        isOpen={isModalEditOpen}
        modalClose={handleModalClose}
        handleInputChangeName={handleInputChangeName}
        handleInputChangeLogin={handleInputChangeLogin}
        handleInputChangeAccess={handleInputChangeAccess}
        buttonDisable={props.model.isButtonDisabled}
        activeButtons={props.model.permissions}
      />

      <ModalDelete
        modalTitle="edit"
        isOpen={isModalDeleteOpen}
        modalClose={handleModalDeleteClose}
        handleDelete={() => handleDeleteItem(props.id)}
      />
    </>
  );
};
