import { Button, CircularProgress } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Step } from '../modal-with-blur/modal-kitchener';

interface ProgressButtonProps {
  percentage: number;
  handleCancel: () => void;
  onOpen: () => void;
  step: Step;
  title?: string;
  disabled?: boolean;
  available: number;
}

export const ProgressButton: FC<ProgressButtonProps> = ({
  percentage,
  handleCancel,
  onOpen,
  step,
  title = 'Готово',
  disabled,
  available,
}) => (
  <>
    {step === Step.Initial && (
      <Button
        onClick={onOpen}
        colorScheme="blue"
        variant="solid"
        disabled={disabled}
      >
        {title}
      </Button>
    )}
    {step === Step.InProgress && (
      <Button
        rightIcon={
          <CircularProgress
            value={Number(percentage.toFixed(2))}
            size="30px"
            thickness="4px"
          />
        }
        colorScheme="blue"
        variant="outline"
        onClick={handleCancel}
      >
        Отменить
      </Button>
    )}
    {step === Step.Done && available === 0 && (
      <Button colorScheme="blue" variant="ghost" disabled>
        Готово
      </Button>
    )}
  </>
);
