import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ProductCardModel } from '../model';
import { ProductWeightBlock } from './product-weight-block';
import { GrayInformBlock } from './gray-inform-block';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cookingPlaceFormatter } from '../../model/cooking-place-formatter';
import {
  AssemblyChartItemChildResponse,
  CookingPlace,
  CookingPlacesData,
} from '../../interface/type';
import { SearchIcon } from '@chakra-ui/icons';
import { useSessionActions } from '../../../../services/session-action/session-action';
import { ButtonBack } from './button-back';
import { ModalKitchener } from '../../view/modal-with-blur/modal-kitchener';

function round(value: number, step: number) {
  // eslint-disable-next-line no-unused-expressions
  step || (step = 1.0);
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

const permissionString = localStorage.getItem('permission');
const permission: string[] | undefined = permissionString?.split(',');

export const ProductCardView = ProductCardModel.modelClient((props) => {
  const params = useParams<{ productId: string }>();
  const id: string | undefined = params.productId;

  const { state }: any = useLocation();
  const { previousPage, savedData, link, filterButton } = state;

  console.log('savedDate', savedData);

  useEffect(() => {
    props.model.getAssemblyProductDetail(id);
  }, [id, props.model]);

  const { signOut } = useSessionActions();
  const navigate = useNavigate();

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <SimpleGrid>
          <Box w="50%">
            <ButtonBack
              title="Вернуться назад"
              onClick={() =>
                navigate(previousPage, {
                  state: {
                    savedData,
                    link,
                    filterButton,
                  },
                })
              }
            />
            <Box marginTop="12px" />
            {/* <Heading w="200px">Смена от 21 апреля</Heading> */}
          </Box>
          <Flex>
            <Heading noOfLines={3} maxWidth="500px">
              {props.model.finishedProduct?.productTitle}
            </Heading>
            <Box marginLeft="130px" />
            <GrayInformBlock
              title={`${props.model.finishedProduct?.count} порций`}
            />
          </Flex>
        </SimpleGrid>

        <SimpleGrid>
          <Button marginTop="12px" colorScheme="red" onClick={signOut}>
            Выйти
          </Button>
        </SimpleGrid>
      </Flex>
      <Box h="24px" />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input type="tel" placeholder="Начните вводить название продукта" />
      </InputGroup>
      <Box h="24px" />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">Продукт</Th>
            <Th textAlign="center">Вес сырого продукта</Th>
            <Th textAlign="center">Вес готового продукта</Th>
            <Th textAlign="center">Вес на единицу</Th>
            <Th textAlign="center">Готовность</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.model.assemblyDetailCard.data.map((item) => (
            <ItemRow key={item.id} {...item} />
          ))}
        </Tbody>
      </Table>
    </div>
  );
});

type ItemRowProps = {
  id: number;
  productTitle: string;
  weightCooked: number;
  weightPerItem: number;
  cookingPlacesData: CookingPlacesData[];
  weightRaw: number;
  count: number;
  complete: number;
  cookingPlaceType: CookingPlace;
  children: AssemblyChartItemChildResponse[] | [];
  available: number;
};

const ItemRow: FC<ItemRowProps> = ProductCardModel.modelClient((props) => {
  const { state }: any = useLocation();
  const { parentCount } = state;
  const checkPermissionsArray = permission?.filter((permission) =>
    permission.includes(props.cookingPlaceType)
  );

  return (
    <React.Fragment key={props.id}>
      <Tr>
        <Td textAlign="left">
          <Flex>
            <Text as="b">{props.productTitle}</Text>
            <Box marginLeft="40px" />
            <Box marginLeft="20px" />
          </Flex>
        </Td>
        <Td textAlign="center">
          {round(props.weightRaw * parentCount, 0.00000001)}
        </Td>
        <Td isNumeric>
          <Center>
            <ProductWeightBlock
              title={round(props.weightCooked * parentCount, 0.00000001)}
            />
          </Center>
        </Td>
        <Td textAlign="center" justifyContent="center" alignItems="center">
          {props.weightPerItem}
        </Td>
        <Td>
          {checkPermissionsArray && checkPermissionsArray?.length > 0 && (
            <Center>
              <ModalKitchener
                productDetail
                buttonAvailable={checkPermissionsArray.length > 0}
                available={props.available}
                onChangeCount={props.model.setCookCount}
                modalTitle={props.productTitle}
                onChangeLabel={props.model.setIsAddLabel}
                handleSubmit={() =>
                  props.model.completeAssemblyChartItem(props.id)
                }
              />
            </Center>
          )}
        </Td>
      </Tr>
      {props.children &&
        props.children.map((item) => (
          <ChildrenItems item={item} key={item.id} parentCount={parentCount} />
        ))}
    </React.Fragment>
  );
});

interface ChildProps {
  item: AssemblyChartItemChildResponse;
  parentCount: number;
  children?: AssemblyChartItemChildResponse[] | [];
}

const ChildrenItems: FC<ChildProps> = ProductCardModel.modelClient((props) => {
  const checkPermissionsArray = permission?.filter((permission) =>
    permission.includes(props.item.cookingPlaceType)
  );

  return (
    <>
      <Tr key={props.item.id}>
        <Td textAlign="left" as="i">
          <Flex>
            <Text marginLeft="40px">{props.item.productTitle}</Text>
            <Box marginLeft="20px" />
          </Flex>
        </Td>
        <Td textAlign="center">
          {round(props.item.weightRaw * Number(props?.parentCount), 0.00000001)}
        </Td>
        <Td isNumeric>
          <Center>
            <ProductWeightBlock
              title={round(
                props.item.weightCooked * Number(props?.parentCount),
                0.00000001
              )}
            />
          </Center>
        </Td>
        <Td textAlign="center" justifyContent="center" alignItems="center">
          {props.item.weightPerItem}
        </Td>

        <Td>
          {checkPermissionsArray && checkPermissionsArray?.length > 0 && (
            <Center>
              <ModalKitchener
                productDetail
                buttonAvailable={checkPermissionsArray?.length > 0}
                available={props.item.available}
                onChangeCount={props.model.setCookCount}
                modalTitle={props.item.productTitle}
                onChangeLabel={props.model.setIsAddLabel}
                handleSubmit={() =>
                  props.model.completeAssemblyChartItem(props.item.id)
                }
              />
            </Center>
          )}
        </Td>
      </Tr>
      {props.item.children &&
        props.item.children.map((item) => (
          <ChildrenItemsSecondLevel
            item={item}
            key={item.id}
            parentCount={props.parentCount}
          />
        ))}
    </>
  );
});

const ChildrenItemsSecondLevel: FC<ChildProps> = ProductCardModel.modelClient(
  (props) => {
    const checkPermissionsArray = permission?.filter((permission) =>
      permission.includes(props.item.cookingPlaceType)
    );
    return (
      <>
        <Tr key={props.item.id}>
          <Td textAlign="left">
            <Flex>
              <Text marginLeft="60px">{props.item.productTitle}</Text>
              <Box marginLeft="40px" />
              <Box marginLeft="20px" />
              {props.item.cookingPlaceType && (
                <GrayInformBlock
                  title={`из ${cookingPlaceFormatter(
                    props.item.cookingPlaceType
                  )} ${props.item.complete} / ${props.item.count}`}
                />
              )}
            </Flex>
          </Td>
          <Td textAlign="center">
            {round(
              props.item.weightRaw * Number(props?.parentCount),
              0.00000001
            )}
          </Td>
          <Td isNumeric>
            <Center>
              <ProductWeightBlock
                title={round(
                  props.item.weightCooked * Number(props?.parentCount),
                  0.00000001
                )}
              />
            </Center>
          </Td>
          <Td textAlign="center" justifyContent="center" alignItems="center">
            {props.item.weightPerItem}
          </Td>

          <Td>
            {checkPermissionsArray && checkPermissionsArray.length > 0 && (
              <Center>
                <ModalKitchener
                  productDetail
                  buttonAvailable={checkPermissionsArray.length > 0}
                  available={props.item.available}
                  onChangeCount={props.model.setCookCount}
                  modalTitle={props.item.productTitle}
                  onChangeLabel={props.model.setIsAddLabel}
                  handleSubmit={() =>
                    props.model.completeAssemblyChartItem(props.item.id)
                  }
                />
              </Center>
            )}
          </Td>
        </Tr>
        {props.item.children &&
          props.item.children.map((item) => (
            <ChildrenItemsThirdLevel
              item={item}
              key={item.id}
              parentCount={props.parentCount}
            />
          ))}
      </>
    );
  }
);

const ChildrenItemsThirdLevel: FC<ChildProps> = (props) => (
  <>
    <Tr key={props.item.id}>
      <Td textAlign="center">
        <Flex as="i" marginLeft="80px">
          {props.item.productTitle}
          <Box marginLeft="40px" />
          <Box marginLeft="20px" />
          {props.item.cookingPlaceType && (
            <GrayInformBlock
              title={`из ${cookingPlaceFormatter(
                props.item.cookingPlaceType
              )} ${props.item.complete} / ${props.item.count}`}
            />
          )}
        </Flex>
      </Td>
      <Td textAlign="center">
        {round(props.item.weightRaw * Number(props?.parentCount), 0.00000001)}
      </Td>
      <Td isNumeric>
        <Center>
          <ProductWeightBlock
            title={round(
              props.item.weightCooked * Number(props?.parentCount),
              0.00000001
            )}
          />
        </Center>
      </Td>
      <Td textAlign="center" justifyContent="center" alignItems="center">
        {props.item.weightPerItem}
      </Td>
    </Tr>
    {props.item.children &&
      props.item.children.map((item) => (
        <ChildrenItemsSecondLevel
          item={item}
          key={item.id}
          parentCount={props.parentCount}
        />
      ))}
  </>
);
