import { useEffect, useMemo, useState } from 'react';
import { UsersArray } from '../interface/types';

export const useFilter = (arr: any) => {
  const [filterData, setFilterData] = useState<UsersArray>([]);
  const [masterData, setMasterData] = useState<UsersArray>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilterData(arr);
    setMasterData(arr);
  }, [arr]);

  const searchFilter = (data: any) => {
    const text = data.target.value;
    if (text) {
      const newData = masterData.filter((filteredItem: { name: string }) => {
        const itemData = filteredItem.name
          ? filteredItem.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilterData(newData);
      setSearch(text);
    } else {
      setFilterData(masterData);
      setSearch(text);
    }
  };

  return useMemo(
    () => ({
      filterData,
      search,
      searchFilter,
    }),
    [filterData, search, searchFilter]
  );
};
