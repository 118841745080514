import { makeAutoObservable } from 'mobx';
import { HttpService } from '../../../services/http-service';
import { ItemsResponse } from '../interface';
import React from 'react';

export class Items {
  private readonly _httpService = new HttpService();

  private _quantity = '';

  private _product: ItemsResponse = {
    sku: '',
    title: '',
  };

  public constructor(public source: ItemsResponse) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public get sku() {
    return this.source.sku;
  }

  public get product() {
    return this._product;
  }

  public setProduct(product: ItemsResponse) {
    this._product = product;
  }

  public get title() {
    return this.source.title;
  }

  public setTitle(title: string) {
    this.source.title = title;
  }

  public get quantity() {
    return this._quantity;
  }

  public setQuantity(event: React.ChangeEvent<HTMLInputElement>) {
    this._quantity = event.target.value.toString();
  }
}
