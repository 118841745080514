import { makeAutoObservable } from 'mobx';
import { StoredSession } from '../contexts/session-store';

export class LocalStorageStore<T extends object | null> {
  private readonly key: string;

  private _value: StoredSession;

  public get value() {
    return this._value;
  }

  public constructor(key: string, initialValue: T) {
    makeAutoObservable(this, {}, { autoBind: true });
    const valueFromStorage = localStorage.getItem(key);
    this.key = key;
    this._value = valueFromStorage
      ? JSON.parse(valueFromStorage)
      : initialValue;
  }

  public updateValue(value: StoredSession) {
    if (value === null) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, JSON.stringify(value));
    }

    this._value = value;
  }
}
