/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Permissions } from './permissions-formatter';
import { HttpService } from '../../../services/http-service';
import * as stateCreators from './state-creator';
import { AdminPageStates } from './admin-page-state';
import { UsersArray } from '../interface/types';
import { toast } from 'react-toastify';

export class MainAdminPageModel {
  private _isLoading = false;

  private readonly _httpService = new HttpService();

  private _usersData: AdminPageStates = stateCreators.getInitialState();

  public get usersList() {
    return this._usersData;
  }

  private _name = '';

  public get name() {
    return this._name;
  }

  public setName(value: string) {
    this._name = value;
  }

  private _login = '';

  // private _permisions: CookingPlace = CookingPlace.Initial;

  public get login() {
    return this._login;
  }

  public setLogin(value: string) {
    this._login = value;
  }

  private _password = '';

  public get password() {
    return this._password;
  }

  public setPassword(value: string) {
    this._password = value;
  }

  private _permissions: Permissions[] = [];

  public get permissions() {
    return this._permissions;
  }

  // public setPermissions(value: Permissions) {
  //
  // }

  public deletePermission(el: Permissions) {
    const set = new Set([...this._permissions]);
    set.delete(el);
    this._permissions = Array.from(set);
  }

  public setPermissions(value: Permissions) {
    const set = new Set([...this._permissions]);
    set.add(value);
    this._permissions = Array.from(set);
    console.log(this._permissions);
  }

  public clearPermissions() {
    this._permissions = [];
  }

  public setNewArray(array: Permissions[]) {
    this._permissions = array;
  }

  public get isButtonDisabled() {
    return (
      !this._name ||
      !this._login ||
      !this._password ||
      !this._permissions ||
      this._isLoading
    );
  }

  private refreshState() {
    this._name = '';
    this._login = '';
    this._password = '';
    this._permissions = [];
  }

  public async getDataUsers() {
    try {
      this._isLoading = true;
      const url = '/api/users';

      await this._httpService.get<UsersArray>(url).then((res) => {
        if (res.data) {
          runInAction(() => {
            this._usersData = stateCreators.getHasDataState(res.data);
          });
        }
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public async createUser() {
    console.log('this.formatData(),', this.formatData());
    try {
      runInAction(() => {
        this._isLoading = true;
      });

      const url = '/api/users';
      await this._httpService
        .post(url, {
          data: this.formatData(),
        })
        .then((res) => {
          console.log('res create user', res.status);
          this.getDataUsers();
          this.refreshState();
        });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    } finally {
      runInAction(() => {
        this._isLoading = false;
        this.clearPermissions();
      });
    }
  }

  public async changeUserPassword(id: number) {
    const newPassword = {
      password: this._password,
    };

    try {
      this._isLoading = true;
      const url = `/api/users/${id}/password`;
      await this._httpService
        .put(url, {
          data: newPassword,
        })
        .then((res) => {
          console.log('res', res.status);
        });
    } catch (e) {
      console.log('error change password', e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public async editUser(id: number) {
    try {
      this._isLoading = true;
      const url = '/api/users';

      await this._httpService
        .put(url, {
          data: this.formatDataEdit(id),
        })
        .then((res) => {
          console.log('res status edit:', res.status);
          // this.changeUserPassword(id);
          this.getDataUsers();
          this.refreshState();
        });
    } catch (e) {
      console.log('error edit user', e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  private formatDataEdit(id?: number): {
    password: string;
    permissions: Permissions[];
    name: string;
    id: number | undefined;
    login: string;
  } {
    const newUser = {
      name: this._name,
      login: this._login,
      password: this._password,
      permissions: this._permissions.map((data) => data),
      id,
    };

    console.log('NEW USER', this._permissions);
    return newUser;
  }

  private formatData(): {
    password: string;
    permissions: Permissions[];
    name: string;
    login: string;
  } {
    const newUser = {
      name: this._name,
      login: this._login,
      password: this._password,
      permissions: this._permissions,
    };
    return newUser;
  }

  public async deleteUser(id: number) {
    try {
      this._isLoading = true;
      const url = `/api/users/${id}`;

      await this._httpService.delete(url).then((res) => {
        console.log('res delete', res.status);
        this.getDataUsers();
      });
    } catch (e) {
      console.log('error', e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new MainAdminPageModel(), []);
    useEffect(() => {
      model.getDataUsers();
    }, [model]);

    return model;
  }

  private static ModelContext = React.createContext<MainAdminPageModel | null>(
    null
  );

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = MainAdminPageModel.makeModel();

    return (
      <MainAdminPageModel.ModelContext.Provider value={model}>
        {props.children}
      </MainAdminPageModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: MainAdminPageModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(MainAdminPageModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
