/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { HttpService } from '../../../services/http-service';
import {
  ArrayDates,
  ArrayPlan,
  ItemsArray,
  ParsingStatusResponse,
  PlanResponse,
  PlanValueSelect,
  ShopArray,
  UpdatePlanData,
} from '../interface';
import { ItemsStates } from './items/state-creator';
import * as itemState from './items/items-state';
import { ShopStates } from './shop/state-creator';
import * as shopState from './shop/shop-state';
import { DatesStates } from './dates/state-creator';
import * as datesState from './dates/dates-state';
import { Product } from './product';
import * as parsingState from './parsing-status/parsing-state';
import { parsingStates } from './parsing-status/state-creator';
import { PlanStates } from './plan/state-creator';
import * as planState from './plan/plan-state';
import { toast } from 'react-toastify';
import { errorToast } from '../../../components/core/error-toast/error-toast';

export class ProductionPlanModel {
  private readonly _httpService = new HttpService();

  private _shops: ShopStates = shopState.getInitialState();

  private _items: ItemsStates = itemState.getInitialState();

  private _dates: DatesStates = datesState.getInitialState();

  private _dateSave: string | null = null;

  private _dateSaveModal: string | null = null;

  private _pickedItems: Array<Product> = [
    new Product({
      sku: '',
      title: '',
      quantity: '',
      id: '',
    }),
  ];

  private _shopId: string | undefined = undefined;

  private _disabledButtonCopy = true;

  private _parsingStateData: parsingStates = parsingState.getInitialState();

  private _planDataState: PlanStates = planState.getInitialState();

  private _planDateForTotalRender: Array<PlanResponse> = [];

  private _selectedPlanValue: PlanValueSelect | undefined = undefined;

  private _approve = false;

  private _refreshButtonDisabled = false;

  private _hideTotalOrder = false;

  private _buttonAvailableApprovePlan = false;

  private _modalButtonSaveDisabled = false;

  // private _quantity = '';

  public get items() {
    return this._items;
  }

  public get shops() {
    return this._shops;
  }

  public get dates() {
    return this._dates;
  }

  public get pickedItems() {
    return this._pickedItems;
  }

  public get parsingStates() {
    return this._parsingStateData;
  }

  public get plan() {
    return this._planDataState;
  }

  public get planForTotalRender() {
    return this._planDateForTotalRender;
  }

  public get approve() {
    return this._approve;
  }

  public get disabledButtonCopy() {
    return this._disabledButtonCopy;
  }

  public get dateSave() {
    return this._dateSave;
  }

  public get dateSaveModal() {
    return this._dateSaveModal;
  }

  public get refreshButtonDisabled() {
    return this._refreshButtonDisabled;
  }

  public get hideTotalOrder() {
    return this._hideTotalOrder;
  }

  public get buttonAvailableApprovePlan() {
    return this._buttonAvailableApprovePlan;
  }

  public get modalButtonSaveDisabled() {
    return this._modalButtonSaveDisabled;
  }

  public get selectedPlanValue() {
    return this._selectedPlanValue;
  }

  public setDate(date: string) {
    if (date) {
      runInAction(() => {
        this._dateSave = date;
        this.getPlanByDate(date);
        this.parsingStatus(date);
      });
    }
  }

  public setDateModal(date: string) {
    if (date) {
      runInAction(() => {
        this._dateSaveModal = date;
        this.parsingStatus(date);
      });
    }
  }

  public setApprove(approve: boolean) {
    runInAction(() => {
      this._approve = approve;
    });
  }

  public setShopId(id: string | undefined) {
    this._shopId = id;
    this._disabledButtonCopy = false;
    console.log('ID SHOP', this._shopId);
  }

  // public get quantity() {
  //   return this._quantity;
  // }
  //
  // public setQuantity(data: string) {
  //   this._quantity = data;
  // }

  public setPickedProduct(id: string) {
    const product = {
      sku: '',
      title: '',
      quantity: '',
      id,
    };
    this._pickedItems.push(new Product(product));
  }

  public setDisabledButtonCopy() {
    this._disabledButtonCopy = true;
  }

  public setHideTotalOrder() {
    if (this._hideTotalOrder) {
      this._hideTotalOrder = false;
    } else {
      this._hideTotalOrder = true;
    }
  }

  public cancelModalAddModels() {
    this._pickedItems = [
      new Product({
        sku: '',
        title: '',
        quantity: '',
        id: '',
      }),
    ];
  }

  public setModalButtonSaveDisabled(state: boolean) {
    this._modalButtonSaveDisabled = state;
  }

  public setPlanDate(planValue: PlanValueSelect) {
    if (planValue) {
      this.getPlanByDate(planValue.date);
      this.parsingStatus(planValue.date);
      return (this._selectedPlanValue = planValue);
    }
    return null;
  }

  private async getShops() {
    try {
      const url = '/life/shops';
      const { data } = await this._httpService.get<ShopArray>(url);
      if (data) {
        runInAction(() => {
          this._shops = shopState.getHasDataState(data);
        });
      }
    } catch (e: any) {
      errorToast(e);
    }
  }

  private async getItems() {
    try {
      const url = '/life/items';
      const { data } = await this._httpService.get<ItemsArray>(url);
      if (data) {
        runInAction(() => {
          this._items = itemState.getHasDataState(data);
        });
      }
    } catch (e: any) {
      errorToast(e);
    }
  }

  private async getDates() {
    try {
      const url = '/life/plan/dates';
      const { data } = await this._httpService.get<ArrayDates>(url);
      if (data) {
        runInAction(() => {
          this._dates = datesState.getHasDataState(data);
          if (data[0]) {
            this._dateSave = data[0]?.date;
            this.setPlanDate({
              label: `Заказ на ${data[0]?.date} - ${
                data[0]?.approved ? 'Утвержден' : 'Не утвержден'
              }`,
              status: data[0]?.approved ?? false,
              date: data[0]?.date ?? '',
            });
          }
        });
      }
    } catch (e: any) {
      errorToast(e);
    }
  }

  public async getPlanByDate(date: string | null) {
    try {
      const url = `/life/plan?date=${date}`;
      const { data } = await this._httpService.get<ArrayPlan>(url);
      if (data) {
        runInAction(() => {
          this._planDataState = planState.getHasDataState(data);
          this._planDateForTotalRender = data;
        });
      }
    } catch (e: any) {
      errorToast(e);
    }
  }

  public async postPlan(close: () => void, date: string | null) {
    try {
      if (date) {
        const newDateForSave: PlanValueSelect = {
          label: `Заказ на ${date} - 'Не утвержден'`,
          status: false,
          date,
        };
        const url = `/life/plan?date=${date}&replace=APPEND`;
        const { data } = await this._httpService.post(url, {
          data: this.formatData(),
        });
        if (data) {
          await this.getDates();
          await this.getPlanByDate(date);
          this.setDate(newDateForSave.date);
          this.setPlanDate(newDateForSave);
        }
      }
    } catch (e) {
      errorToast(e);
    } finally {
      await this.getDates();
      close();
    }
  }

  private formatData(): {
    shopId: string | undefined;
    plan: { count: string; sku: string; title: string }[];
  }[] {
    const dataPose = [
      {
        shopId: this._shopId,
        plan: this._pickedItems.map((data) => ({
          sku: data.sku,
          title: data.title,
          count: data.quantity,
        })),
      },
    ];
    return dataPose;
  }

  public async updatePlan(data: Array<UpdatePlanData>) {
    try {
      const url = `/life/plan?date=${this._dateSave}&replace=SELF`;
      await this._httpService
        .post(url, {
          data,
        })
        .then((res) => {
          this.getPlanByDate(this._dateSave);
        })
        .catch((error) => {
          errorToast(error);
          console.log('Error: update', error.response.data.description);
        });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public async planApprove(close: () => void) {
    this._buttonAvailableApprovePlan = true;
    try {
      const url = `/life/plan/approve?date=${this._dateSave}`;
      await this._httpService
        .post(url)
        .then((res) => {
          console.log('planApprove status: ', res.status);
          this._buttonAvailableApprovePlan = false;
          toast.success('План успешно утвержден', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          this.getDates();
          close();
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          this._buttonAvailableApprovePlan = false;
          close();
        });
    } catch (e: any) {
      errorToast(e);
    }
  }

  public async parsingStatus(date: string) {
    try {
      const url = `/life/plan/parsingStatus?date=${date}`;
      await this._httpService
        .get<ParsingStatusResponse>(url)
        .then((res) => {
          console.log('parsingStatus status: ', res.data);
          runInAction(() => {
            this._parsingStateData = parsingState.getHasDataState(res.data);
          });
        })
        .catch((error) => {
          errorToast(error);
        });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public async copyLastOrder() {
    try {
      const url = `/life/plan/last?shopId=${this._shopId}`;
      await this._httpService
        .get<PlanResponse>(url)
        .then((res) => {
          console.log('parsingStatus status: ', res.data);
          if (res.data.plan && res.data.plan.length === 0) {
            toast.info('Нет прошлого заказа', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          } else {
            res.data.plan.map((data) =>
              runInAction(() => {
                this._pickedItems.push(
                  new Product({
                    id: data.id.toString(),
                    sku: data.sku,
                    title: data.title,
                    quantity: data.count.toString(),
                  })
                );
              })
            );
          }
        })
        .catch((error) => {
          errorToast(error);
        });
    } catch (e: any) {
      errorToast(e);
    }
  }

  public async planRefresh() {
    this._refreshButtonDisabled = true;
    console.log('this._dateSave', this._dateSave);
    try {
      const url = `/life/plan/refresh?date=${this._dateSave}`;
      await this._httpService
        .post(url)
        .then((res) => {
          console.log('planRefresh status: ', res.status);
          this.getPlanByDate(this._dateSave);
          this.getDates();
          this._refreshButtonDisabled = false;
        })
        .catch((error) => {
          errorToast(error);
          this._refreshButtonDisabled = false;
        });
    } catch (e: any) {
      errorToast(e);
    }
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new ProductionPlanModel(), []);
    useEffect(() => {
      model.getShops();
      model.getItems();
      model.getDates();
    });

    return model;
  }

  private static ModelContext = React.createContext<ProductionPlanModel | null>(
    null
  );

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = ProductionPlanModel.makeModel();

    return (
      <ProductionPlanModel.ModelContext.Provider value={model}>
        {props.children}
      </ProductionPlanModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: ProductionPlanModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(ProductionPlanModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
