export enum Permissions {
  Initial = '',
  Root = 'ROOT',
  OrderManager = 'ORDER_MANAGEMENT',
  Packer = 'PACKING',
  ColdDepartment = 'COLD_DEPARTMENT',
  HotDepartment = 'HOT_DEPARTMENT',
  VegetablesDepartment = 'VEGETABLES_DEPARTMENT',
  Confectionery = 'CONFECTIONERY_DEPARTMENT',
  MeatDepartment = 'MEAT_DEPARTMENT',
  PlanManager = 'PLAN_MANAGEMENT',
  InternalPlanManager = 'INTERNAL_PLAN_MANAGEMENT',
}

export const permissionsFormatter = (permissions: Permissions[0]) => {
  switch (permissions) {
    case Permissions.Root:
      return 'Root Admin';
    case Permissions.Packer:
      return 'Фасовщик';
    case Permissions.ColdDepartment:
      return 'Холодный цех';
    case Permissions.HotDepartment:
      return 'Горячий цех';
    case Permissions.MeatDepartment:
      return 'Мясной цех';
    case Permissions.Confectionery:
      return 'Кондитерский цех';
    case Permissions.VegetablesDepartment:
      return 'Овощной цех';
    case Permissions.OrderManager:
      return 'Управление производством';
    case Permissions.PlanManager:
      return 'Управление планом';
    case Permissions.InternalPlanManager:
      return 'Управление внутренним заказом';
    default:
      return 'ошибка';
    // throw new NeverError(permissions);
  }
};

export const reversePermissionsFormatter = (permissions: string) => {
  switch (permissions) {
    case 'Root Admin':
      return Permissions.Root;
    case 'Фасовщик':
      return Permissions.Packer;
    case 'Холодный цех':
      return Permissions.ColdDepartment;
    case 'Горячий цех':
      return Permissions.HotDepartment;
    case 'Мясной цех':
      return Permissions.MeatDepartment;
    case 'Кондитерский цех':
      return Permissions.Confectionery;
    case 'Овощной цех':
      return Permissions.VegetablesDepartment;
    case 'Управление производством':
      return Permissions.OrderManager;
    case 'Управление планом':
      return Permissions.PlanManager;
    case 'Управление внутренним заказом':
      return Permissions.InternalPlanManager;
    default:
      return 'ошибка';
    // throw new NeverError(permissions);
  }
};
