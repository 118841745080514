export type Block = {
  title: number;
};

export type ButtonType = {
  title: string;
};

export type ForProductDTO = {
  id: number;
  productTitle: string;
};

export enum CookingPlace {
  Initial = '',
  Cold = 'COLD',
  Hot = 'HOT',
  Meat = 'MEAT',
}

export type AssemblyChartsItemsArray = Array<AssemblyChartsItemsResponse>;
// export type AssemblyProductDetailArray = Array<AssemblyProductDetailResponse>

export type AssemblyChartsItemsResponse = {
  id: number;
  productTitle: string;
  count: number;
  complete: number;
  orderPacking: number;
  orderCooking: number;
  hasChildren: boolean;
  forProduct: ForProductDTO;
  cookingPlaces: [CookingPlace];
  label: string | null;
  available: number;
  type: NomenclatureType;
};

export enum NomenclatureType {
  Goods = 'GOODS', // товар
  Dish = 'DISH', // блюдо
  Prepared = 'PREPARED', // заготовка
  Service = 'SERVICE', // услуга
  Modifier = 'MODIFIER', // модификатор
  Outer = 'OUTER', // внешние товары
  Petrol = 'PETROL', // Топливо
  Rate = 'RATE', // Тариф
  UNKNOWN = 'UNKNOWN', // Неизвестно или не установлено в iiko
}

export type AssemblyProductDetailResponse = {
  id: number;
  productTitle: string;
  count: number;
  complete: number;
  children: AssemblyChartItemChildResponse;
};

export type AssemblyChartItemChildResponse = {
  id: number;
  productTitle: string;
  weightCooked: number;
  weightPerItem: number;
  cookingPlacesData: CookingPlacesData[];
  weightRaw: number;
  count: number;
  complete: number;
};

export type CookingPlacesData = {
  cookingPlace: CookingPlace;
  count: number;
  complete: number;
};
