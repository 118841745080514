import {
  DateStateEmpty,
  DateStateError,
  DateStateHasData,
  DateStateInitial,
  DateStateLoading,
} from './date-state';
import { DateArray } from '../../interface/type';

export function getInitialState(): DateStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): DateStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): DateStateEmpty {
  return { type: 'EMPTY', data: {}, error: null };
}

export function getErrorState(error: Error): DateStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(response: DateArray): DateStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
