import React, { FC, ReactNode, useState } from 'react';
import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { styles } from './modal-edit-password-styles';
import { ButtonNew } from '../../button';

interface ModalElementInterface {
  modalTitle: string;
  children: ReactNode;
  handleInputChangePassword: (e: string) => void;
  handlePress: () => void;
}

export const ModalEditPassword: FC<ModalElementInterface> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [passwordValue, setPasswordValuie] = useState('');

  const handleSavePassword = (e: any) => {
    setPasswordValuie(e.target.value);
  };

  const handleSubmit = () => {
    props.handleInputChangePassword(passwordValue);
    props.handlePress();
    onClose();
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Button
        alignItems="center"
        justifyContent="center"
        backgroundColor="transparent"
        onClick={onOpen}
      >
        {props.children}
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={styles.titleStyleFont}>
            {props.modalTitle}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Text style={styles.title}>Пароль</Text>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Задайте пароль"
                  onChange={handleSavePassword}
                />
                <InputRightElement width="5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleShowPassword}
                    marginRight="10px"
                  >
                    {showPassword ? 'Скрыть' : 'Показать'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button style={styles.cancelButton} onClick={onClose}>
                Отменить
              </Button>
              <div style={{ marginTop: '50px', marginLeft: '30px' }}>
                <ButtonNew onClick={handleSubmit} title="Сохранить" />
              </div>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
