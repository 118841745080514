export const styles = {
  blockContainer: {
    backgroundColor: '#fff',
    width: '390px',
    height: '83px',
    borderWidth: '2px',
    borderRadius: '3px',
    borderColor: '#2557B8',
    alightItem: 'center',
    justifyContent: 'center',
  },

  blockText: {
    lineHeight: '32px',
    fontWeight: '500',
    fontSize: '32px',
    color: '#2557B8',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 24,
    paddingBottom: 24,
    fontFamily: 'Montserrat',
  },
};
