import {
  CookingPageStateEmpty,
  CookingPageStateError,
  CookingPageStateHasData,
  CookingPageStateInitial,
  CookingPageStateLoading,
} from './cooking-page-state';
import { AssemblyChartsItemsArray } from '../interface/type';

export function getInitialState(): CookingPageStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): CookingPageStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): CookingPageStateEmpty {
  return { type: 'EMPTY', data: {}, error: null };
}

export function getErrorState(error: Error): CookingPageStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(
  response: AssemblyChartsItemsArray
): CookingPageStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
