import { ParsingStatusResponse } from '../../interface';
import {
  parsingStateEmpty,
  parsingStateError,
  parsingStateHasData,
  parsingStateInitial,
  parsingStateLoading,
} from './state-creator';

export function getInitialState(): parsingStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(
  data: ParsingStatusResponse
): parsingStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): parsingStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): parsingStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(
  response: ParsingStatusResponse
): parsingStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
