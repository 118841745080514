// ../../../react-shim.js
import React from "react";

// src/select.tsx
import { useFormControl } from "@chakra-ui/form-control";
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  layoutPropNames,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
var dataAttr = (condition) => condition ? "" : void 0;

// ../../utilities/object-utils/dist/index.esm.js
function split(object, keys) {
  const picked = {};
  const omitted = {};
  for (const [key, value] of Object.entries(object)) {
    if (keys.includes(key))
      picked[key] = value;
    else
      omitted[key] = value;
  }
  return [picked, omitted];
}

// src/select.tsx
import { cloneElement, isValidElement } from "react";

// src/select-field.tsx
import { chakra, forwardRef } from "@chakra-ui/system";
var SelectField = forwardRef(function SelectField2(props, ref) {
  const { children, placeholder, className, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra.select, {
    ...rest,
    ref,
    className: cx("chakra-select", className)
  }, placeholder && /* @__PURE__ */ React.createElement("option", {
    value: ""
  }, placeholder), children);
});
SelectField.displayName = "SelectField";

// src/select.tsx
var Select = forwardRef2((props, ref) => {
  var _a;
  const styles = useMultiStyleConfig("Select", props);
  const {
    rootProps,
    placeholder,
    icon,
    color,
    height,
    h,
    minH,
    minHeight,
    iconColor,
    iconSize,
    ...rest
  } = omitThemingProps(props);
  const [layoutProps, otherProps] = split(rest, layoutPropNames);
  const ownProps = useFormControl(otherProps);
  const rootStyles = {
    width: "100%",
    height: "fit-content",
    position: "relative",
    color
  };
  const fieldStyles = {
    paddingEnd: "2rem",
    ...styles.field,
    _focus: {
      zIndex: "unset",
      ...(_a = styles.field) == null ? void 0 : _a["_focus"]
    }
  };
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    className: "chakra-select__wrapper",
    __css: rootStyles,
    ...layoutProps,
    ...rootProps
  }, /* @__PURE__ */ React.createElement(SelectField, {
    ref,
    height: h ?? height,
    minH: minH ?? minHeight,
    placeholder,
    ...ownProps,
    __css: fieldStyles
  }, props.children), /* @__PURE__ */ React.createElement(SelectIcon, {
    "data-disabled": dataAttr(ownProps.disabled),
    ...(iconColor || color) && { color: iconColor || color },
    __css: styles.icon,
    ...iconSize && { fontSize: iconSize }
  }, icon));
});
Select.displayName = "Select";
var DefaultIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 24 24",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
}));
var IconWrapper = chakra2("div", {
  baseStyle: {
    position: "absolute",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    top: "50%",
    transform: "translateY(-50%)"
  }
});
var SelectIcon = (props) => {
  const { children = /* @__PURE__ */ React.createElement(DefaultIcon, null), ...rest } = props;
  const clone = cloneElement(children, {
    role: "presentation",
    className: "chakra-select__icon",
    focusable: false,
    "aria-hidden": true,
    style: {
      width: "1em",
      height: "1em",
      color: "currentColor"
    }
  });
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    ...rest,
    className: "chakra-select__icon-wrapper"
  }, isValidElement(children) ? clone : null);
};
SelectIcon.displayName = "SelectIcon";
export {
  DefaultIcon,
  Select,
  SelectField
};
