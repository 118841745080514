export const styles = {
  workShopBody: {
    backgroundColor: '#C11D1D',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '10px',
    paddingLeft: '10px',
    borderRadius: '3px',
  },
  workShopBodySecond: {
    backgroundColor: '#C11D1D',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '10px',
    paddingLeft: '10px',
    borderRadius: '3px',
    opacity: 0.6,
  },

  workShopBodyThird: {
    backgroundColor: '#BCBCBC',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '10px',
    paddingLeft: '10px',
    borderRadius: '3px',
    opacity: 0.6,
  },

  textWorkShow: {
    lineHeight: '16px',
    fontWeight: '400',
    fontSize: '20px',
    color: '#F0F0F0',
    fontFamily: 'Montserrat',
  },
};
