import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { styles } from './modal-styles';
import { ButtonNew } from '../../button';
import { Permissions } from '../../../../screens/main-admin-page/model/permissions-formatter';
import { ButtonAdd } from '../modal-add-user/button-add';
import { AddIcon } from '@chakra-ui/icons';

interface ModalElementInterface {
  modalTitle: string;
  isOpen: boolean;
  modalClose: () => void;
  handleInputChangeName: (e: any) => void;
  handleInputChangeLogin: (e: any) => void;
  handleInputChangeAccess: (e: any) => void;
  buttonDisable: boolean;
  handleEditUser: () => void;
  nameData?: string;
  login: string;
  activeButtons: Permissions[];
}

export const ModalEdit: FC<ModalElementInterface> = (props) => {
  const handleEdit = () => {
    props.modalClose();
    props.handleEditUser();
  };

  const [isButtonsVisible, setIsVisible] = useState(false);
  const [nameDate, setNameData] = useState(props.nameData);
  const [login, setLoginData] = useState(props.login);

  const handleButtonPress = (data: Permissions) => {
    props.handleInputChangeAccess(data);
    props.handleInputChangeName(nameDate);
    props.handleInputChangeLogin(login);
  };

  const handleChangeName = (e: any) => {
    const inputValue = e.target.value;
    setNameData(inputValue);
    props.handleInputChangeName(inputValue);
  };

  const handleChangeLogin = (e: any) => {
    const inputValue = e.target.value;
    setLoginData(inputValue);
    props.handleInputChangeLogin(inputValue);
  };

  return (
    <Modal
      onClose={props.modalClose}
      isOpen={props.isOpen}
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid spacing="20px">
            <Flex>
              <Text style={styles.title} w="70px">
                Имя
              </Text>
              <Input
                value={nameDate}
                placeholder="Введите имя"
                onChange={handleChangeName}
              />
            </Flex>

            <Flex>
              <Text style={styles.title} w="70px">
                Логин
              </Text>
              <Input
                value={login}
                placeholder="Введите логин"
                onChange={handleChangeLogin}
              />
            </Flex>

            <Flex>
              <Text style={styles.title}>Доступ</Text>
              <SimpleGrid>
                <ButtonAdd
                  onClick={() => handleButtonPress(Permissions.OrderManager)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.OrderManager
                  )}
                  title="Управление производством"
                />
                <Box margin="8px" />
                <ButtonAdd
                  title="Фасовщик"
                  onClick={() => handleButtonPress(Permissions.Packer)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.Packer
                  )}
                />
                <Box margin="8px" />
                {/* <ButtonAdd */}
                {/*  title="Кладовщик" */}
                {/*  onClick={handleStoreKeeper} */}
                {/*  isActiveAssemble={isActiveAssemble.StoreKeeper} */}
                {/* /> */}
                <ButtonAdd
                  onClick={() => handleButtonPress(Permissions.MeatDepartment)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.MeatDepartment
                  )}
                  title="Мясной цех"
                />
              </SimpleGrid>

              {isButtonsVisible && (
                <>
                  <SimpleGrid marginLeft="8px" marginRight="8px">
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.ColdDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.ColdDepartment
                      )}
                      title="Холодный цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.HotDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.HotDepartment
                      )}
                      title="Горячий цех"
                    />
                    <Box margin="8px" />

                    <ButtonAdd
                      onClick={() => handleButtonPress(Permissions.PlanManager)}
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.PlanManager
                      )}
                      title="Управление планом"
                    />
                    <Box margin="8px" />
                  </SimpleGrid>
                  <SimpleGrid marginLeft="8px" marginRight="8px">
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.VegetablesDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.VegetablesDepartment
                      )}
                      title="Овощной цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.Confectionery)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.Confectionery
                      )}
                      title="Кондитерский цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.InternalPlanManager)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.InternalPlanManager
                      )}
                      title="Внутренний заказ"
                    />
                    <Box margin="8px" />
                  </SimpleGrid>
                </>
              )}

              <Button
                marginLeft="8px"
                colorScheme="blue"
                onClick={() => setIsVisible(!isButtonsVisible)}
              >
                <AddIcon />
              </Button>
            </Flex>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <ButtonNew onClick={handleEdit} title="Сохранить" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
