import React, { FC } from 'react';
import { Box, Text, Flex, Center } from '@chakra-ui/react';
import { styles } from './button-back-styles';
import { ArrowBack } from '../icons/arrow-back';

interface ButtonBackInterface {
  title: string;
  onClick: () => void;
}

export const ButtonBack: FC<ButtonBackInterface> = ({ title, onClick }) => (
  <Box onClick={onClick} style={styles.blockContainer}>
    <Flex>
      <Center>
        <Box marginLeft="24px" />
        <ArrowBack />
        <Text style={styles.blockText} textAlign="center">
          {title}
        </Text>
      </Center>
    </Flex>
  </Box>
);
