import {
  PlanStateEmpty,
  PlanStateError,
  PlanStateHasData,
  PlanStateInitial,
  PlanStateLoading,
} from './state-creator';
import { PlanResponse } from '../../interface';
import { PlanClass } from './plan-class';

export function getInitialState(): PlanStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): PlanStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): PlanStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): PlanStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(
  response: Array<PlanResponse>
): PlanStateHasData {
  return {
    type: 'HAS_DATA',
    data: response.map((data) => new PlanClass(data)),
    error: null,
  };
}
