/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { AssemblyProductDetailResponse } from '../../interface/type';
import { HttpService } from '../../../../services/http-service';
import { ProductCardStates } from './state-creator';
import * as stateCreators from './product-card-page-state';
import { toast } from 'react-toastify';

export class ProductCardModel {
  private readonly _httpService = new HttpService();
  //
  // private _isLoading = false;

  private _id: string | null = null;

  private _assemblyDetailedCard: ProductCardStates =
    stateCreators.getInitialState();

  private _finishedProduct: AssemblyProductDetailResponse | null = null;

  public get assemblyDetailCard() {
    return this._assemblyDetailedCard;
  }

  public get finishedProduct() {
    return this._finishedProduct;
  }

  private _countProduct = '';

  public get countProduct() {
    return this._countProduct;
  }

  public setCookCount(count: string) {
    this._countProduct = count;
    this._error = '';
  }

  private _error = '';

  public setError(error: string) {
    this._error = error;
  }

  public get error() {
    return this._error;
  }

  private _isAddLabel = false;

  public get isAddLabel() {
    return this._isAddLabel;
  }

  public setIsAddLabel(data: boolean) {
    this._isAddLabel = data;
  }

  public async getAssemblyProductDetail(id?: string) {
    if (id) {
      this._id = id;
    }
    try {
      // this._isLoading = true;
      const url = `/api/assemblyChartsItems/${id}`;

      await this._httpService
        .get<AssemblyProductDetailResponse>(url)
        .then((res) => {
          console.log('cookingPlacesData,', res.data.children, res.status);
          if (res.data) {
            runInAction(() => {
              this._finishedProduct = res.data;
              this._assemblyDetailedCard = stateCreators.getHasDataState(
                res.data.children
              );
            });
          }
        });
    } catch (e) {
      console.log('error get assembly charts', e);
    }
  }

  public async completeAssemblyChartItem(id: number) {
    try {
      console.log('ID', id);

      const data = {
        id,
        count: this._countProduct,
        isAddLabel: this._isAddLabel,
      };
      console.log('data', data);
      const url = `/api/assemblyChartsItems`;
      await this._httpService
        .post(url, {
          data,
        })
        .then((res) => {
          console.log('Status post:', res.status);
          this.getAssemblyProductDetail(this._id ?? '');
        });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
      this.setError(e?.response?.data.message);
      this.getAssemblyProductDetail(this._id ?? '');
    }
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new ProductCardModel(), []);
    return model;
  }

  private static ModelContext = React.createContext<ProductCardModel | null>(
    null
  );

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = ProductCardModel.makeModel();

    return (
      <ProductCardModel.ModelContext.Provider value={model}>
        {props.children}
      </ProductCardModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: ProductCardModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(ProductCardModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
