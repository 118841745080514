import React, { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { KitchenChiefModel } from '../model';
import { PlateWithNumber } from '../../../components/core/plate-with-number';
import { SplitIcon } from '../icons/split-icon';
import { ModalSplitIcon } from './split-modal';
import { CloseIcon } from '../icons/close-icon';
import { ItemRowProps } from '../interface/types';
import { useSessionActions } from '../../../services/session-action/session-action';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';

const InputFilter = observer(
  (props: { value: string; onChange: (value: string) => void }) => (
    <Input
      // type="tel"
      placeholder="Начните вводить название продукта"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  )
);

export const KitchenLeaderPage = KitchenChiefModel.modelClient((props) => {
  const { signOut } = useSessionActions();

  const statusOptions = props.model.dates.data.map((data) => ({
    label: `Заказ на ${data.date} - ${
      data.isApproved ? 'Утвержден' : 'Не утвержден'
    }`,
    value: Date.now(),
    status: data.isApproved,
    date: data.date,
  }));
  const styleMap: any = {
    false: '#C11D1D',
    true: '#2557B8',
  };
  const colourStyles: any = {
    singleValue: (
      provided: {
        boxSizing: string;
        color: string;
        gridArea: string;
        label: string;
        marginLeft: number;
        marginRight: number;
        maxWidth: string;
        overflow: string;
        textOverflow: string;
        whiteSpace: string;
      },
      { data }: { data: { date: string; label: string; status: string } }
    ) => ({
      ...provided,
      color: styleMap[data.status] ?? 'defaultColor',
      fontWeight: 'bold',
    }),
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <SimpleGrid columns={1} spacing={10}>
          {/* <Heading>Смена от 21 апреля</Heading> */}
          <Heading>Управление циклом приготовления</Heading>
          <Box style={{ width: '350px' }}>
            {props.model.dates.type === 'HAS_DATA' && (
              <Select
                defaultValue={() => {
                  if (
                    props.model.dates.type === 'HAS_DATA' &&
                    props.model.dates?.data[0]
                  ) {
                    props.model.setDate(props.model.dates?.data[0].date);
                    return {
                      label: `Заказ на ${
                        props.model.dates?.data[0].date ?? null
                      } - ${
                        props.model.dates?.data[0].isApproved
                          ? 'Утвержден'
                          : 'Не утвержден'
                      }`,
                      value: Date.now(),
                      status: props.model.dates?.data[0].isApproved,
                      date: props.model.dates?.data[0].date,
                    };
                  }
                  return null;
                }}
                placeholder="Выбрать дату"
                styles={colourStyles}
                options={statusOptions}
                onChange={(data: any) => {
                  if (data) {
                    props.model.setDate(data.date);
                  }
                }}
              />
            )}
          </Box>
        </SimpleGrid>
        <Button marginTop="12px" colorScheme="red" onClick={signOut}>
          Выйти
        </Button>
      </Flex>
      <Box h="24px" />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" marginRight="20px" />
        </InputLeftElement>
        <InputFilter
          value={props.model.search}
          onChange={props.model.setSearch}
        />
      </InputGroup>
      <Box h="24px" />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">Продукт</Th>
            <Th textAlign="left">Количество</Th>
            <Th textAlign="center">Очередность упаковки</Th>
            <Th textAlign="center">Очередность готовки</Th>
            <Th textAlign="center">Заказчик</Th>
            <Th textAlign="center">Город</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.model.foundData !== null &&
            props.model.foundData.map((item) => (
              <ItemRow item={item} key={item.source.id} />
            ))}
        </Tbody>
      </Table>
    </div>
  );
});

const ItemRow = KitchenChiefModel.modelClient<ItemRowProps>((props) => {
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);

  function handleOpenSplitModal() {
    setIsSplitModalOpen(true);
    props.model.setError('');
  }

  function handleCloseSplitModal() {
    setIsSplitModalOpen(false);
  }

  return (
    <>
      <Tr>
        <Td textAlign="left">{props.item.source.productTitle}</Td>
        <Td textAlign="left">
          <Flex>
            <Center>
              <PlateWithNumber title={props.item.source.count} />
              <Box marginLeft="40px" />
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="Search database"
                icon={<SplitIcon />}
                onClick={handleOpenSplitModal}
              />
            </Center>
          </Flex>
        </Td>
        <Td textAlign="center">
          <Input
            w="90px"
            value={props.item.inputQueuePacking}
            onChange={(e) => props.item.setInputQueuePacking(e.target.value)}
            onBlur={() => props.item.setQueuePacking()}
          />
        </Td>
        <Td textAlign="center">
          <Input
            w="90px"
            value={props.item.inputQueueCooking}
            onChange={(e) => props.item.setInputQueueCooking(e.target.value)}
            onBlur={() => props.item.setQueueCooking()}
          />
        </Td>
        <Td textAlign="center">{props.item.source.label}</Td>
        <Td textAlign="center">{props.item.source.city}</Td>
      </Tr>

      {props.item.children.map((item) => (
        <ChildrenItems item={item} key={item.source.id} />
      ))}

      <ModalSplitIcon
        id={props.item.source.id}
        quantity={Number(props.item.source.count)}
        isOpen={isSplitModalOpen}
        modalTitle="edit"
        modalClose={handleCloseSplitModal}
      />
    </>
  );
});

const ChildrenItems = KitchenChiefModel.modelClient<ItemRowProps>((props) => (
  <Tr>
    <Td textAlign="left">
      <Text marginLeft="20px" as="i">
        {props.item.source.productTitle}
      </Text>
    </Td>
    <Td textAlign="left">
      <Flex>
        <Center>
          <PlateWithNumber title={props.item.source.count} gray />
          <Box marginLeft="40px" />
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="Search database"
            icon={<CloseIcon />}
            onClick={() => props.item.deleteOrder(props.model.getOrders)}
          />
        </Center>
      </Flex>
    </Td>
    <Td textAlign="center">
      <Input
        w="90px"
        value={props.item.inputQueuePacking}
        onChange={(e) => props.item.setInputQueuePacking(e.target.value)}
        onBlur={() => props.item.setQueuePacking()}
      />
    </Td>
    <Td textAlign="center">
      <Input
        w="90px"
        value={props.item.inputQueueCooking}
        onChange={(e) => props.item.setInputQueueCooking(e.target.value)}
        onBlur={() => props.item.setQueueCooking()}
      />
    </Td>
    <Td textAlign="center">{props.item.source.label}</Td>
    <Td textAlign="center">{props.item.source.city}</Td>
  </Tr>
));
