import React from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ProductionPlanModel } from '../../model';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale';
import { ProgressBar } from 'react-loader-spinner';

registerLocale('ru', ru);

// import Select from 'react-select';

export const ApprovePlan = ProductionPlanModel.modelClient((props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        backgroundColor="transparent"
      >
        <Button
          disabled={props.model.buttonAvailableApprovePlan}
          whiteSpace="initial"
          onClick={onOpen}
          colorScheme="blue"
          marginLeft="16px"
        >
          {props.model.buttonAvailableApprovePlan ? (
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor="#F4442E"
              barColor="#51E5FF"
            />
          ) : (
            'Утвердить план'
          )}
        </Button>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            <Text mt="20px" textAlign="center">
              Вы точно проверили и сохранили позиции? После согласования по
              этому заказу будут созданы накладные в iiko
            </Text>
          </ModalHeader>
          <ModalBody>
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap="35rem"
              marginBottom="30px"
            >
              <GridItem>
                <Button onClick={onClose} size="lg" colorScheme="red">
                  Отменить
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  onClick={() => props.model.planApprove(onClose)}
                  size="lg"
                  colorScheme="blue"
                >
                  Утвердить
                </Button>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
