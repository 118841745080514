import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { styles } from './gray-inform-block-styles';

interface GrayInformBlockInterface {
  title: string;
}

export const GrayInformBlock: FC<GrayInformBlockInterface> = ({ title }) => (
  <Button
    colorScheme="blue"
    variant="outline"
    disabled
    style={styles.blockTextTitle}
    whiteSpace="initial"
  >
    {title}
  </Button>
);
