import { useEffect } from 'react';
import { SessionStore } from '../contexts/session-store';

export const useSessionRedirect = (params: {
  onNavigateToDefault: () => void;
  onNavigateToAuth: () => void;
  isAuthRoute: boolean;
}) => {
  const sessionStore = SessionStore.use();

  useEffect(() => {
    const subscription = sessionStore.subscribe((sessionState) => {
      const hasSession = Boolean(sessionState.session);
      if (params.isAuthRoute && hasSession) {
        params.onNavigateToDefault();
        return;
      }

      if (!params.isAuthRoute && !hasSession) {
        params.onNavigateToAuth();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [
    sessionStore,
    params.onNavigateToAuth,
    params.onNavigateToDefault,
    params.isAuthRoute,
  ]);
};
