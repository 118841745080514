import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { styles } from './periodicity-block-styles';
import { Block } from '../../interface/type';

export const PeriodicityBlock: FC<Block> = ({ title }) => {
  if (title === 1) {
    return (
      <Box as="button" style={styles.workShopBody}>
        <Text style={styles.textWorkShow}>{title}</Text>
      </Box>
    );
  }
  if (title === 2) {
    return (
      <Box as="button" style={styles.workShopBodySecond}>
        <Text style={styles.textWorkShow}>{title}</Text>
      </Box>
    );
  }
  return (
    <Box as="button" style={styles.workShopBodyThird}>
      <Text style={styles.textWorkShow}>{title}</Text>
    </Box>
  );
};
