// ../../react-shim.js
import React from "react";

// src/table.tsx
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/table.tsx
import { createContext } from "@chakra-ui/react-context";
var [TableStylesProvider, useTableStyles] = createContext({
  name: `TableStylesContext`,
  errorMessage: `useTableStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Table />" `
});
var Table = forwardRef((props, ref) => {
  const styles = useMultiStyleConfig("Table", props);
  const { className, ...tableProps } = omitThemingProps(props);
  return /* @__PURE__ */ React.createElement(TableStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.table, {
    role: "table",
    ref,
    __css: styles.table,
    className: cx("chakra-table", className),
    ...tableProps
  }));
});
Table.displayName = "Table";

// src/table-container.tsx
import { chakra as chakra2, forwardRef as forwardRef2 } from "@chakra-ui/system";
var TableContainer = forwardRef2((props, ref) => {
  const { overflow, overflowX, className, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ref,
    className: cx("chakra-table__container", className),
    ...rest,
    __css: {
      display: "block",
      whiteSpace: "nowrap",
      WebkitOverflowScrolling: "touch",
      overflowX: overflow ?? overflowX ?? "auto",
      overflowY: "hidden",
      maxWidth: "100%"
    }
  });
});

// src/table-caption.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
var TableCaption = forwardRef3((props, ref) => {
  const { placement = "bottom", ...rest } = props;
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra3.caption, {
    ...rest,
    ref,
    __css: {
      ...styles.caption,
      captionSide: placement
    }
  });
});
TableCaption.displayName = "TableCaption";

// src/thead.tsx
import { chakra as chakra4, forwardRef as forwardRef4 } from "@chakra-ui/system";
var Thead = forwardRef4((props, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra4.thead, {
    ...props,
    ref,
    __css: styles.thead
  });
});

// src/tbody.tsx
import { chakra as chakra5, forwardRef as forwardRef5 } from "@chakra-ui/system";
var Tbody = forwardRef5((props, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra5.tbody, {
    ...props,
    ref,
    __css: styles.tbody
  });
});

// src/tfooter.tsx
import { chakra as chakra6, forwardRef as forwardRef6 } from "@chakra-ui/system";
var Tfoot = forwardRef6((props, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra6.tfoot, {
    ...props,
    ref,
    __css: styles.tfoot
  });
});

// src/th.tsx
import { chakra as chakra7, forwardRef as forwardRef7 } from "@chakra-ui/system";
var Th = forwardRef7(({ isNumeric, ...rest }, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra7.th, {
    ...rest,
    ref,
    __css: styles.th,
    "data-is-numeric": isNumeric
  });
});

// src/tr.tsx
import { chakra as chakra8, forwardRef as forwardRef8 } from "@chakra-ui/system";
var Tr = forwardRef8((props, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra8.tr, {
    role: "row",
    ...props,
    ref,
    __css: styles.tr
  });
});

// src/td.tsx
import { chakra as chakra9, forwardRef as forwardRef9 } from "@chakra-ui/system";
var Td = forwardRef9(({ isNumeric, ...rest }, ref) => {
  const styles = useTableStyles();
  return /* @__PURE__ */ React.createElement(chakra9.td, {
    role: "gridcell",
    ...rest,
    ref,
    __css: styles.td,
    "data-is-numeric": isNumeric
  });
});
export {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useTableStyles
};
