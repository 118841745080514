/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { HttpService } from '../../../services/http-service';
import { toast } from 'react-toastify';

export class AuthorizationModel {
  private readonly _httpService = new HttpService();

  //
  protected _isLoading = false;

  private _login = '';

  public get login() {
    return this._login;
  }

  public setLogin(value: string) {
    this._login = value;
  }

  private _passwordState = '';

  public get password() {
    return this._passwordState;
  }

  public setPassword(value: string) {
    this._passwordState = value;
  }

  // public get isButtonDisabled() {
  //   return !this._login || !this._passwordState || this._isLoading;
  // }

  private async getMe() {
    try {
      const url = '/api/users/me';
      await this._httpService.get(url).then((res: any) => {
        if (res?.data) {
          const permission = res.data.permissions;
          console.log('PERMISSION GET ME', permission);

          this.onAuthorize(this._login, this._passwordState, permission);
        }
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public async authorization() {
    try {
      runInAction(() => {
        this._isLoading = true;
      });
      const url = '/api/login';
      await this._httpService
        .post(url, {
          data: this.formatData(),
        })
        .then((res) => {
          if (res.status === 200) {
            this.onAuthorize(this._login, this._passwordState);
            this.getMe();
          }
          return res;
        });
    } catch (e) {
      console.log(e);
      // todo: somehow handle this error and show an alert to the user
      // throw e instanceof Error ? e : new Error('Failed to register');
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  private formatData() {
    return {
      login: this._login,
      password: this._passwordState,
    };
  }

  private constructor(
    private readonly onAuthorize: (
      username: string,
      password: string,
      permission?: string
    ) => void
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel(
    onAuthorize: (
      username: string,
      password: string,
      permission?: string
    ) => void
  ) {
    const model = React.useMemo(
      () => new AuthorizationModel(onAuthorize),
      [onAuthorize]
    );
    return model;
  }

  private static ModelContext = React.createContext<AuthorizationModel | null>(
    null
  );

  public static Provider(
    props: React.PropsWithChildren<
      object & {
        onAuthorize: (
          username: string,
          password: string,
          permission?: string
        ) => Promise<void>;
      }
    >
  ) {
    const model = AuthorizationModel.makeModel(props.onAuthorize);

    return (
      <AuthorizationModel.ModelContext.Provider value={model}>
        {props.children}
      </AuthorizationModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: AuthorizationModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(AuthorizationModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
