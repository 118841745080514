import { makeAutoObservable } from 'mobx';

interface ProductProps {
  sku: string;
  title: string;
  quantity: string;
  id: string;
}

export class Product {
  // private readonly _httpService = new HttpService();

  public constructor(public source: ProductProps) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public get sku() {
    return this.source.sku;
  }

  public get title() {
    return this.source.title;
  }

  public get id() {
    return this.source.id;
  }

  public setTitle(product: string) {
    this.source.title = product;
  }

  public get quantity() {
    return this.source.quantity;
  }

  public setQuantity(quantity: string) {
    this.source.quantity = quantity;
  }

  public setSKU(sku: string) {
    this.source.sku = sku;
  }
}
