import { CookingPlace } from '../interface/type';

export const cookingPlaceFormatter = (cookingPlace: CookingPlace) => {
  switch (cookingPlace) {
    case CookingPlace.Hot:
      return 'Горячий цех';
    case CookingPlace.Cold:
      return 'Холодный цех';
    case CookingPlace.Meat:
      return 'Мясной цех';
    case CookingPlace.Vegetables:
      return 'Овощной цех';
    default:
      return 'ошибка';
    // throw new NeverError(permissions);
  }
};
