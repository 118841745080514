import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { styles } from './plate-with-number-styles';
import { Block } from '../../../screens/сook-page/interface/type';

export const PlateWithNumber: FC<Block> = ({ title, gray }) => (
  <Box as="button" style={gray ? styles.grayWorkShopBody : styles.workShopBody}>
    <Text style={styles.textWorkShow}>{title}</Text>
  </Box>
);
