// ../../react-shim.js
import React from "react";

// src/circular-progress.tsx
import { chakra as chakra3 } from "@chakra-ui/system";

// src/progress.utils.tsx
import { keyframes } from "@chakra-ui/system";
function valueToPercent(value, min, max) {
  return (value - min) * 100 / (max - min);
}
var spin = keyframes({
  "0%": {
    strokeDasharray: "1, 400",
    strokeDashoffset: "0"
  },
  "50%": {
    strokeDasharray: "400, 400",
    strokeDashoffset: "-100"
  },
  "100%": {
    strokeDasharray: "400, 400",
    strokeDashoffset: "-260"
  }
});
var rotate = keyframes({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
});
var progress = keyframes({
  "0%": { left: "-40%" },
  "100%": { left: "100%" }
});
var stripe = keyframes({
  from: { backgroundPosition: "1rem 0" },
  to: { backgroundPosition: "0 0" }
});
function getProgressProps(options) {
  const {
    value = 0,
    min,
    max,
    valueText,
    getValueText,
    isIndeterminate
  } = options;
  const percent = valueToPercent(value, min, max);
  const getAriaValueText = () => {
    if (value == null)
      return void 0;
    return typeof getValueText === "function" ? getValueText(value, percent) : valueText;
  };
  return {
    bind: {
      "data-indeterminate": isIndeterminate ? "" : void 0,
      "aria-valuemax": max,
      "aria-valuemin": min,
      "aria-valuenow": isIndeterminate ? void 0 : value,
      "aria-valuetext": getAriaValueText(),
      role: "progressbar"
    },
    percent,
    value
  };
}

// src/shape.tsx
import { chakra } from "@chakra-ui/system";
var Shape = (props) => {
  const { size, isIndeterminate, ...rest } = props;
  return /* @__PURE__ */ React.createElement(chakra.svg, {
    viewBox: "0 0 100 100",
    __css: {
      width: size,
      height: size,
      animation: isIndeterminate ? `${rotate} 2s linear infinite` : void 0
    },
    ...rest
  });
};
Shape.displayName = "Shape";

// src/circle.tsx
import { chakra as chakra2 } from "@chakra-ui/system";
var Circle = (props) => /* @__PURE__ */ React.createElement(chakra2.circle, {
  cx: 50,
  cy: 50,
  r: 42,
  fill: "transparent",
  ...props
});
Circle.displayName = "Circle";

// src/circular-progress.tsx
var CircularProgress = (props) => {
  const {
    size = "48px",
    max = 100,
    min = 0,
    valueText,
    getValueText,
    value,
    capIsRound,
    children,
    thickness = "10px",
    color = "#0078d4",
    trackColor = "#edebe9",
    isIndeterminate,
    ...rest
  } = props;
  const progress2 = getProgressProps({
    min,
    max,
    value,
    valueText,
    getValueText,
    isIndeterminate
  });
  const determinant = isIndeterminate ? void 0 : (progress2.percent ?? 0) * 2.64;
  const strokeDasharray = determinant == null ? void 0 : `${determinant} ${264 - determinant}`;
  const indicatorProps = isIndeterminate ? {
    css: { animation: `${spin} 1.5s linear infinite` }
  } : {
    strokeDashoffset: 66,
    strokeDasharray,
    transitionProperty: "stroke-dasharray, stroke",
    transitionDuration: "0.6s",
    transitionTimingFunction: "ease"
  };
  const rootStyles = {
    display: "inline-block",
    position: "relative",
    verticalAlign: "middle",
    fontSize: size
  };
  return /* @__PURE__ */ React.createElement(chakra3.div, {
    className: "chakra-progress",
    ...progress2.bind,
    ...rest,
    __css: rootStyles
  }, /* @__PURE__ */ React.createElement(Shape, {
    size,
    isIndeterminate
  }, /* @__PURE__ */ React.createElement(Circle, {
    stroke: trackColor,
    strokeWidth: thickness,
    className: "chakra-progress__track"
  }), /* @__PURE__ */ React.createElement(Circle, {
    stroke: color,
    strokeWidth: thickness,
    className: "chakra-progress__indicator",
    strokeLinecap: capIsRound ? "round" : void 0,
    opacity: progress2.value === 0 && !isIndeterminate ? 0 : void 0,
    ...indicatorProps
  })), children);
};
CircularProgress.displayName = "CircularProgress";

// src/progress.tsx
import {
  chakra as chakra4,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { createContext } from "@chakra-ui/react-context";
var [ProgressStylesProvider, useProgressStyles] = createContext({
  name: `ProgressStylesContext`,
  errorMessage: `useProgressStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Progress />" `
});
var ProgressFilledTrack = (props) => {
  const { min, max, value, isIndeterminate, ...rest } = props;
  const progress2 = getProgressProps({ value, min, max, isIndeterminate });
  const styles = useProgressStyles();
  const trackStyles = {
    height: "100%",
    ...styles.filledTrack
  };
  return /* @__PURE__ */ React.createElement(chakra4.div, {
    style: {
      width: `${progress2.percent}%`,
      ...rest.style
    },
    ...progress2.bind,
    ...rest,
    __css: trackStyles
  });
};
var Progress = (props) => {
  var _a;
  const {
    value,
    min = 0,
    max = 100,
    hasStripe,
    isAnimated,
    children,
    borderRadius: propBorderRadius,
    isIndeterminate,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    ...rest
  } = omitThemingProps(props);
  const styles = useMultiStyleConfig("Progress", props);
  const borderRadius = propBorderRadius ?? ((_a = styles.track) == null ? void 0 : _a.borderRadius);
  const stripeAnimation = { animation: `${stripe} 1s linear infinite` };
  const shouldAddStripe = !isIndeterminate && hasStripe;
  const shouldAnimateStripe = shouldAddStripe && isAnimated;
  const css = {
    ...shouldAnimateStripe && stripeAnimation,
    ...isIndeterminate && {
      position: "absolute",
      willChange: "left",
      minWidth: "50%",
      animation: `${progress} 1s ease infinite normal none running`
    }
  };
  const trackStyles = {
    overflow: "hidden",
    position: "relative",
    ...styles.track
  };
  return /* @__PURE__ */ React.createElement(chakra4.div, {
    borderRadius,
    __css: trackStyles,
    ...rest
  }, /* @__PURE__ */ React.createElement(ProgressStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(ProgressFilledTrack, {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    min,
    max,
    value,
    isIndeterminate,
    css,
    borderRadius
  }), children));
};
Progress.displayName = "Progress";

// src/progress-label.tsx
import { chakra as chakra5 } from "@chakra-ui/system";
var ProgressLabel = (props) => {
  const styles = useProgressStyles();
  const labelStyles = {
    top: "50%",
    left: "50%",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    ...styles.label
  };
  return /* @__PURE__ */ React.createElement(chakra5.div, {
    ...props,
    __css: labelStyles
  });
};
ProgressLabel.displayName = "ProgressLabel";

// src/circular-progress-label.tsx
import { chakra as chakra6 } from "@chakra-ui/system";
var CircularProgressLabel = chakra6("div", {
  baseStyle: {
    fontSize: "0.24em",
    top: "50%",
    left: "50%",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    transform: "translate(-50%, -50%)"
  }
});
CircularProgressLabel.displayName = "CircularProgressLabel";
export {
  CircularProgress,
  CircularProgressLabel,
  Progress,
  ProgressLabel,
  useProgressStyles
};
