/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Part } from './part';
import { HttpService } from '../../../services/http-service';
import * as stateCreators from './state-creator';
import { MainManagerPageStates } from './cooking-page-state';
import { OrderItemArray } from '../interface/types';
import { toast } from 'react-toastify';
import { DatePageStates } from '../../сook-page/model/date-state/date-state';
import * as dateState from './date-state/state-creator';
import { DateArray } from '../../сook-page/interface/type';

export class KitchenChiefModel {
  private readonly _httpService = new HttpService();

  private _search = '';

  private _errorMessage = '';

  private _dates: DatePageStates = dateState.getInitialState();

  private _parts: Map<number, Part> = new Map();

  private _ordersStore: MainManagerPageStates = stateCreators.getInitialState();

  private _dateSave: string | null = null;

  public get search() {
    return this._search;
  }

  public get dates() {
    return this._dates;
  }

  public get parts() {
    return this._parts;
  }

  public get ordersStore() {
    return this._ordersStore;
  }

  public get errorMessage() {
    return this._errorMessage;
  }

  public get foundData() {
    if (!this.search) {
      return this.ordersStore.data;
    }
    if (this.ordersStore.type === 'HAS_DATA') {
      return this.ordersStore.data.filter((item) =>
        item.source.productTitle
          .toUpperCase()
          .includes(this.search.toUpperCase())
      );
    }
    return this.ordersStore.data;
  }

  public setError(error: string) {
    this._errorMessage = error;
  }

  public breakIntoParts = (number: number, parts: number) => {
    const remainder = number % parts;
    const baseValue = (number - remainder) / parts;

    return Array(parts)
      .fill(baseValue)
      .fill(baseValue + 1, parts - remainder);
  };

  public setSearch(value: string) {
    this._search = value;
  }

  public setDate(date: string) {
    this._dateSave = date;
    this.getOrders(date);
    console.log('DATE SAVE', this._dateSave);
  }

  public handleSelect = (totalQuantity: number, value: number) => {
    const mapData: Array<[number, Part]> = this.breakIntoParts(
      totalQuantity,
      value
    ).map((value, index) => [
      index + 1,
      new Part({ currentValue: value, maxValue: totalQuantity, id: index + 1 }),
    ]);

    this._parts = new Map(mapData);
    // this.setCounts(
    //   Array.from(this._parts.values()).map((data) => data.inputValue)
    // );
  };

  public async splitOrder(id: number, modalClose: () => void) {
    const arr = Array.from(this._parts.values()).map((data) => data.inputValue);
    try {
      const url = `/api/assemblyChartsItems/order?id=${id}&counts=${arr.toString()}`;
      await this._httpService.post(url).then((res) => {
        if (res.status === 200) {
          runInAction(() => {
            this.setError('');
            this.getOrders();
            modalClose();
          });
        }
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
      if (e?.response.data) {
        runInAction(() => {
          this._errorMessage = e?.response.data.message;
        });
      }
    }
  }

  public async getOrders(date?: string) {
    try {
      const url = date
        ? `/api/assemblyChartsItems/order?date=${date}`
        : `/api/assemblyChartsItems/order`;
      await this._httpService.get<OrderItemArray>(url).then((res) => {
        if (res.data) {
          runInAction(() => {
            this._ordersStore = stateCreators.getHasDataState(res.data);
          });
        }
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public getDates() {
    try {
      this._httpService
        .get<DateArray>('api/assemblyChartsItems/dates')
        .then((res) => {
          runInAction(() => {
            console.log(res.data);
            this._dates = dateState.getHasDataState(res.data);
          });
        });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new KitchenChiefModel(), []);
    // const token = SessionStore.use().sessionState.session;
    useEffect(() => {
      model.getOrders();
      model.getDates();
    }, [model]);

    return model;
  }

  private static ModelContext = React.createContext<KitchenChiefModel | null>(
    null
  );

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = KitchenChiefModel.makeModel();

    return (
      <KitchenChiefModel.ModelContext.Provider value={model}>
        {props.children}
      </KitchenChiefModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: KitchenChiefModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(KitchenChiefModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
