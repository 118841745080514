/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as stateCreators from './state-creator';
import { SessionStore } from '../../../services/session-action/contexts/session-store';
import { HttpService } from '../../../services/http-service';
import { CookingPageStates } from './cooking-page-state';
import { AssemblyChartsItemsArray, DateArray } from '../interface/type';
import { DatePageStates } from './date-state/date-state';
import * as dateState from './date-state/state-creator';
import { NomenclatureType } from '../../packing-shop/interface/interface';
import { Permissions } from '../../main-admin-page/model/permissions-formatter';
import { PlanValueSelect } from '../../production-plan/interface';
import { errorToast } from '../../../components/core/error-toast/error-toast';

export class CookPageModel {
  private readonly _httpService = new HttpService();

  protected _isLoading = false;

  private _lingUrl: string | null = null;

  private _dateSave: string | null = null;

  private _cookingPlaceStore: CookingPageStates =
    stateCreators.getInitialState();

  private _isAddLabel = false;

  private _filterButtons: NomenclatureType = NomenclatureType.UNKNOWN;

  private _selectedPlanValue: PlanValueSelect | undefined = undefined;

  public setCookingDate(data: AssemblyChartsItemsArray) {
    this._cookingPlaceStore = stateCreators.getHasDataState(data);
  }

  public setFilterButton(filter: NomenclatureType) {
    this._filterButtons = filter;
  }

  private _dates: DatePageStates = dateState.getInitialState();

  public get dates() {
    return this._dates;
  }

  public get filterButton() {
    return this._filterButtons;
  }

  public setLink(link: string) {
    this._lingUrl = link;
  }

  public setDate(date: string) {
    runInAction(() => {
      this._dateSave = date;
      this.getCookingPlace();
    });
  }

  private _error = '';

  public setIsAddLabel(data: boolean) {
    this._isAddLabel = data;
  }

  private _countProduct = '';

  public setCookCount(count: string) {
    this._countProduct = count;
    this._error = '';
  }

  public get cookingPlace() {
    return this._cookingPlaceStore;
  }

  public setError(error: string) {
    this._error = error;
  }

  public get error() {
    return this._error;
  }

  public get selectedPlanValue() {
    return this._selectedPlanValue;
  }

  public setPlanDate(planValue: PlanValueSelect) {
    if (planValue) {
      return (this._selectedPlanValue = planValue);
    }
    return null;
  }

  public async getCookingPlace() {
    const dateSave = `${
      this._dateSave !== null ? `&date=${this._dateSave}` : ''
    }`;

    const uriLink = `/api/assemblyChartsItems?cookingPlace=${this._lingUrl}${dateSave}`;

    this._isLoading = true;
    const urlHot = `${
      this._lingUrl !== null
        ? uriLink
        : `/api/assemblyChartsItems?cookingPlace=HOT${dateSave}`
    }`;

    const urlCold = `${
      this._lingUrl !== null
        ? uriLink
        : `/api/assemblyChartsItems?cookingPlace=COLD${dateSave}`
    }${dateSave}`;

    const urlMeat = `${
      this._lingUrl !== null
        ? uriLink
        : `/api/assemblyChartsItems?cookingPlace=MEAT${dateSave}`
    }${dateSave}`;

    const urlVegetable = `${
      this._lingUrl !== null
        ? uriLink
        : `/api/assemblyChartsItems?cookingPlace=VEGETABLES${dateSave}`
    }${dateSave}`;

    const urlConfectionery = `${
      this._lingUrl !== null
        ? uriLink
        : `/api/assemblyChartsItems?cookingPlace=CONFECTIONERY${dateSave}`
    }${dateSave}`;

    const permission = localStorage.getItem('permission');
    function pickUrl() {
      switch (permission) {
        case Permissions.ColdDepartment:
          return urlCold;
        case Permissions.MeatDepartment:
          return urlMeat;
        case Permissions.HotDepartment:
          return urlHot;
        case Permissions.VegetablesDepartment:
          return urlVegetable;
        case Permissions.Confectionery:
          return urlConfectionery;
        default:
          return urlHot;
      }
    }
    try {
      await this._httpService
        .get<AssemblyChartsItemsArray>(pickUrl())
        .then((res) => {
          // console.log('RES get', res.data, res.status);
          if (res.data) {
            runInAction(() => {
              this._cookingPlaceStore = stateCreators.getHasDataState(res.data);
            });
          }
        });
    } catch (e: any) {
      console.log(' e.response.data.message', e.response);
      errorToast(e);
      this.setError(e?.message);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public async completeAssemblyChartItem(id: number) {
    try {
      this._isLoading = true;
      const url = `/api/assemblyChartsItems`;
      await this._httpService
        .post(url, {
          data: {
            id,
            count: this._countProduct,
            isAddLabel: this._isAddLabel,
          },
        })
        .then((res) => {
          console.log('Status post:', res.status);
          this.getCookingPlace();
        })
        .catch((e: any) => {
          errorToast(e);
          this.setError(e?.response?.data.message);
        });
    } catch (e: any) {
      console.log(e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public getDates() {
    try {
      this._httpService
        .get<DateArray>('api/assemblyChartsItems/dates')
        .then((res) => {
          runInAction(() => {
            this._dates = dateState.getHasDataState(res.data);
            console.log('this._savedPlanValue', this._selectedPlanValue);
            if (this._selectedPlanValue === undefined) {
              this.setPlanDate({
                label: `Заказ на ${res.data[0]?.date} - ${
                  res.data[0]?.isApproved ? 'Утвержден' : 'Не утвержден'
                }`,
                status: res.data[0]?.isApproved ?? false,
                date: res.data[0]?.date ?? '',
              });
            }
          });
        });
    } catch (e: any) {
      errorToast(e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public getCookDataByClickOnPage = async (link: string) => {
    if (link) {
      const dateSave = `${
        this._dateSave !== null ? `&date=${this._dateSave}` : ''
      }`;
      const newLink = `/api/assemblyChartsItems?cookingPlace=${link}
    ${dateSave}`;

      try {
        await this._httpService
          .get<AssemblyChartsItemsArray>(newLink)
          .then((res) => {
            // console.log('RES get', res.data, res.status);
            if (res.data) {
              runInAction(() => {
                this._cookingPlaceStore = stateCreators.getHasDataState(
                  res.data
                );
              });
            }
          });
      } catch (e: any) {
        errorToast(e);
      } finally {
        runInAction(() => {
          this._isLoading = false;
        });
      }
    }
  };

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new CookPageModel(), []);
    const token = SessionStore.use().sessionState.session;
    useEffect(() => {
      if (token) {
        model.getCookingPlace();
        model.getDates();
      }
    });
    return model;
  }

  private static ModelContext = React.createContext<CookPageModel | null>(null);

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = CookPageModel.makeModel();

    return (
      <CookPageModel.ModelContext.Provider value={model}>
        {props.children}
      </CookPageModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: CookPageModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(CookPageModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
