/* eslint-disable react-hooks/rules-of-hooks */
import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import * as React from 'react';

import { LocalStorageStore } from '../loca-storage';
import { ISub, PubSub } from '../hook/use-subscription';
import { observer } from 'mobx-react-lite';
import { Permissions } from '../../../screens/main-admin-page/model/permissions-formatter';

export interface StoredSession {
  /**
   * expiry timestamp in seconds
   */
  expiresAt: number;
}

interface SessionState {
  session?: StoredSession;
  error?: string;
}

export class SessionStore implements ISub<SessionState> {
  private static LS_TOKEN_KEY = 'token';

  private readonly sessionPubSub = new PubSub<SessionState>();

  private readonly lsSession = new LocalStorageStore<StoredSession | null>(
    SessionStore.LS_TOKEN_KEY,
    null
  );

  private _permissions: Permissions | null | string = null;

  private readonly reactionDisposer: IReactionDisposer;

  private _sessionState: SessionState = {
    error: undefined,
    session: this.lsSession.value ?? undefined,
  };

  public get permissions() {
    return this._permissions;
  }

  public setPermission(permission: Permissions) {
    this._permissions = permission;
  }

  public get sessionState() {
    return this._sessionState;
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this._permissions = localStorage.getItem('permission');
    this.reactionDisposer = reaction(
      () => this._sessionState,
      (store) => {
        this.lsSession.updateValue(store.session ?? null);
        this.sessionPubSub.next(store ?? null);
      }
    );
  }

  public setSession(session: StoredSession) {
    this._sessionState = {
      error: undefined,
      session,
    };
  }

  public unsetSession() {
    localStorage.removeItem('access');
    this._sessionState = { error: undefined, session: undefined };
    this._permissions = null;
  }

  public setSessionError(errorMessage: string) {
    this._sessionState = { error: errorMessage, session: undefined };
  }

  public static Context = React.createContext<SessionStore | null>(null);

  public static Provider(props: React.PropsWithChildren<object>) {
    const value = SessionStore.useThis();
    return (
      <SessionStore.Context.Provider value={value}>
        {props.children}
      </SessionStore.Context.Provider>
    );
  }

  private dispose() {
    this.reactionDisposer();
  }

  private static useThis() {
    const sessionStore = React.useMemo(() => new SessionStore(), []);

    React.useEffect(
      () => () => {
        sessionStore.dispose();
      },
      [sessionStore]
    );

    return sessionStore;
  }

  public static use() {
    const sessionStore = React.useContext(SessionStore.Context);
    if (sessionStore === null) {
      throw new Error(
        'No `SessionStoreContext` found. Make sure to provide this context first.'
      );
    }

    return sessionStore;
  }

  public subscribe(subscriber: (value: SessionState) => void) {
    subscriber(this._sessionState);
    return this.sessionPubSub.subscribe(subscriber);
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: SessionStore }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(SessionStore.Context);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
