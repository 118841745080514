import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { styles } from './modal-styles';
import { ButtonNew } from '../../button';
import { ButtonAdd } from './button-add';
import { AddIcon } from '@chakra-ui/icons';
import { Permissions } from '../../../../screens/main-admin-page/model/permissions-formatter';

interface ModalElementInterface {
  modalTitle: string;
  isOpen: boolean;
  modalClose: () => void;
  handleInputChangeName: (e: any) => void;
  handleInputChangeLogin: (e: any) => void;
  handleInputChangePassword: (e: any) => void;
  handleInputChangeAccess: (access: Permissions) => void;
  handleAddUser: () => void;
  buttonDisable: boolean;
  activeButtons: Permissions[];
}

export const ModalAddUser: FC<ModalElementInterface> = (props) => {
  const handleAdd = () => {
    props.modalClose();
    props.handleAddUser();
  };

  const [isButtonsVisible, setIsVisible] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  const handleButtonPress = (data: Permissions) => {
    props.handleInputChangeAccess(data);
  };

  return (
    <Modal
      onClose={props.modalClose}
      isOpen={props.isOpen}
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid spacing="20px">
            <Flex>
              <Text style={styles.title} w="70px">
                Имя
              </Text>
              <Input
                placeholder="Введите имя"
                onChange={props.handleInputChangeName}
              />
            </Flex>

            <Flex>
              <Text style={styles.title} w="70px">
                Логин
              </Text>
              <Input
                placeholder="Введите логин"
                onChange={props.handleInputChangeLogin}
              />
            </Flex>

            <Flex>
              <Text style={styles.title} w="70px">
                Пароль
              </Text>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Задайте пароль"
                  onChange={props.handleInputChangePassword}
                />
                <InputRightElement width="5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClick}
                    marginRight="10px"
                  >
                    {showPassword ? 'Скрыть' : 'Показать'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>

            <Flex>
              <Text style={styles.title}>Доступ</Text>
              <SimpleGrid>
                <ButtonAdd
                  onClick={() => handleButtonPress(Permissions.OrderManager)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.OrderManager
                  )}
                  title="Управление производством"
                />
                <Box margin="8px" />
                <ButtonAdd
                  title="Фасовщик"
                  onClick={() => handleButtonPress(Permissions.Packer)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.Packer
                  )}
                />
                <Box margin="8px" />
                {/* <ButtonAdd */}
                {/*  title="Кладовщик" */}
                {/*  onClick={handleStoreKeeper} */}
                {/*  isActiveAssemble={isActiveAssemble.StoreKeeper} */}
                {/* /> */}
                <ButtonAdd
                  onClick={() => handleButtonPress(Permissions.MeatDepartment)}
                  isActiveAssemble={props.activeButtons.includes(
                    Permissions.MeatDepartment
                  )}
                  title="Мясной цех"
                />
              </SimpleGrid>

              {isButtonsVisible && (
                <>
                  <SimpleGrid marginLeft="8px" marginRight="8px">
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.ColdDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.ColdDepartment
                      )}
                      title="Холодный цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.HotDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.HotDepartment
                      )}
                      title="Горячий цех"
                    />

                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() => handleButtonPress(Permissions.PlanManager)}
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.PlanManager
                      )}
                      title="Управление планом"
                    />
                    <Box margin="8px" />
                  </SimpleGrid>
                  <SimpleGrid marginLeft="8px" marginRight="8px">
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.VegetablesDepartment)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.VegetablesDepartment
                      )}
                      title="Овощной цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.Confectionery)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.Confectionery
                      )}
                      title="Кондитерский цех"
                    />
                    <Box margin="8px" />
                    <ButtonAdd
                      onClick={() =>
                        handleButtonPress(Permissions.InternalPlanManager)
                      }
                      isActiveAssemble={props.activeButtons.includes(
                        Permissions.InternalPlanManager
                      )}
                      title="Внутренний заказ"
                    />
                    <Box margin="8px" />
                  </SimpleGrid>
                </>
              )}

              <Button
                marginLeft="8px"
                colorScheme="blue"
                onClick={() => setIsVisible(!isButtonsVisible)}
              >
                <AddIcon />
              </Button>
            </Flex>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <ButtonNew
            onClick={handleAdd}
            title="Добавить пользователя"
            isDisabled={props.buttonDisable}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
