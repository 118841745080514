export const styles = {
  workShopBody: {
    backgroundColor: '#5A6184',
    paddingLeft: '25.5px',
    paddingRight: '25.5px',
    paddingTop: '4px',
    paddingBottom: '4px',
    borderRadius: '3px',
  },

  grayWorkShopBody: {
    backgroundColor: '#909196',
    paddingLeft: '25.5px',
    paddingRight: '25.5px',
    paddingTop: '4px',
    paddingBottom: '4px',
    borderRadius: '3px',
  },

  textWorkShow: {
    lineHeight: '16px',
    fontWeight: '400',
    fontSize: '20px',
    color: '#F0F0F0',
    fontFamily: 'Montserrat',
    width: '90px',
  },
};
