import React from 'react';

export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0035 11.9999L22.9183 3.08483C23.0536 2.95415 23.1615 2.79782 23.2357 2.62498C23.31 2.45215 23.3491 2.26625 23.3507 2.07815C23.3523 1.89004 23.3165 1.7035 23.2453 1.52939C23.174 1.35529 23.0688 1.19712 22.9358 1.0641C22.8028 0.931087 22.6446 0.825895 22.4705 0.754664C22.2964 0.683433 22.1099 0.647589 21.9218 0.649223C21.7337 0.650858 21.5478 0.689939 21.3749 0.764185C21.2021 0.838431 21.0458 0.946356 20.9151 1.08166L12 9.99639L3.08528 1.08166C2.81863 0.820871 2.45989 0.675757 2.08692 0.677811C1.71395 0.679865 1.35684 0.828922 1.09308 1.09263C0.82932 1.35634 0.680201 1.71343 0.678081 2.0864C0.675961 2.45937 0.821012 2.81814 1.08176 3.08483L9.99684 11.9999L1.08176 20.915C0.9478 21.046 0.841165 21.2023 0.768025 21.3748C0.694885 21.5473 0.656693 21.7326 0.655661 21.92C0.654629 22.1074 0.690778 22.2931 0.762013 22.4664C0.833249 22.6397 0.938155 22.7971 1.07066 22.9296C1.20316 23.0621 1.36063 23.167 1.53395 23.2382C1.70727 23.3094 1.89299 23.3455 2.08036 23.3444C2.26773 23.3434 2.45303 23.3051 2.62552 23.232C2.79802 23.1588 2.95429 23.0521 3.08528 22.9182L12 14.0034L20.9151 22.9182C21.1917 23.1948 21.5544 23.3332 21.9167 23.3332C22.279 23.3332 22.642 23.1948 22.9183 22.9182C23.1838 22.6525 23.333 22.2922 23.333 21.9166C23.333 21.5409 23.1838 21.1807 22.9183 20.915L14.0035 11.9999Z"
      fill="#5A6184"
    />
  </svg>
);
