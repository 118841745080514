import * as React from 'react';

import { useSignIn } from './hook/sign-in';
import { useSignOut } from './hook/sign-out';

interface SessionActionsContextValue {
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const SessionActionsContext =
  React.createContext<SessionActionsContextValue | null>(null);

export const SessionActionsContextProvider = (props: {
  children?: React.ReactNode;
}) => {
  const signIn = useSignIn();
  const signOut = useSignOut();

  // TokenRefresher.use();

  const value = React.useMemo<SessionActionsContextValue>(
    () => ({ signIn, signOut }),
    []
  );

  return (
    <SessionActionsContext.Provider value={value}>
      {props.children}
    </SessionActionsContext.Provider>
  );
};

export const useSessionActions = () => {
  const value = React.useContext(SessionActionsContext);

  if (!value) {
    throw new Error(
      'No `SessionActionsContext` found. Make sure to provide context first.'
    );
  }

  return value;
};
