import { makeAutoObservable } from 'mobx';
import { OrderItem } from '../interface/types';
import { HttpService } from '../../../services/http-service';
import { toast } from 'react-toastify';

export class Product {
  public children: Array<Product>;

  private readonly _httpService = new HttpService();

  public constructor(public source: OrderItem) {
    this.children = this.source.children.map((data) => new Product(data));
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async productTitle() {
    return this.source.productTitle;
  }

  public async setQueuePacking() {
    try {
      const url = `/api/assemblyChartsItems/${this.source.id}?order_packing=${this.source.orderPacking}`;
      await this._httpService.put(url).then((res) => {
        console.log('res status', res.data, res.status);
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public async setQueueCooking() {
    try {
      const url = `/api/assemblyChartsItems/${this.source.id}?order_cooking=${this.source.orderCooking}`;
      await this._httpService.put(url).then((res) => {
        console.log('res status', res.data, res.status);
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public async deleteOrder(data: () => void) {
    try {
      const url = `/api/assemblyChartsItems/${this.source.id}`;
      await this._httpService.delete(url).then((res) => {
        console.log('res.status delete', res.status);
        data();
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }

  public get inputQueuePacking() {
    if (this.source.orderPacking) {
      return this.source.orderPacking.toString();
    }
    return '';
  }

  public setInputQueuePacking(value: string) {
    // todo: add additional checks for NaN
    this.source.orderPacking = Number(value);
  }

  public get inputQueueCooking() {
    if (this.source.orderCooking) {
      return this.source.orderCooking.toString();
    }
    return '';
  }

  public setInputQueueCooking(value: string) {
    // todo: add additional checks for NaN
    this.source.orderCooking = Number(value);
  }
}
