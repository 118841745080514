import { makeAutoObservable } from 'mobx';
import { PartObject } from '../interface/types';

export class Part {
  public constructor(public source: PartObject) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public get inputValue() {
    return this.source.currentValue.toString();
  }

  public setValue(value: string) {
    // todo: add additional checks for NaN
    this.source.currentValue = Number(value);
  }
}
