// src/components/accordion.ts
import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from "@chakra-ui/styled-system";
var { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
var baseStyleContainer = defineStyle({
  borderTopWidth: "1px",
  borderColor: "inherit",
  _last: {
    borderBottomWidth: "1px"
  }
});
var baseStyleButton = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal",
  fontSize: "md",
  _focusVisible: {
    boxShadow: "outline"
  },
  _hover: {
    bg: "blackAlpha.50"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  px: "4",
  py: "2"
});
var baseStylePanel = defineStyle({
  pt: "2",
  px: "4",
  pb: "5"
});
var baseStyleIcon = defineStyle({
  fontSize: "1.25em"
});
var baseStyle = definePartsStyle({
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon
});
var accordionTheme = defineMultiStyleConfig({ baseStyle });

// src/components/alert.ts
import { alertAnatomy as parts2 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers2,
  cssVar
} from "@chakra-ui/styled-system";
import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle2, defineMultiStyleConfig: defineMultiStyleConfig2 } = createMultiStyleConfigHelpers2(parts2.keys);
var $fg = cssVar("alert-fg");
var $bg = cssVar("alert-bg");
var baseStyle2 = definePartsStyle2({
  container: {
    bg: $bg.reference,
    px: "4",
    py: "3"
  },
  title: {
    fontWeight: "bold",
    lineHeight: "6",
    marginEnd: "2"
  },
  description: {
    lineHeight: "6"
  },
  icon: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "6"
  },
  spinner: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "5"
  }
});
function getBg(props) {
  const { theme: theme2, colorScheme: c } = props;
  const lightBg = getColor(theme2, `${c}.100`, c);
  const darkBg = transparentize(`${c}.200`, 0.16)(theme2);
  return mode(lightBg, darkBg)(props);
}
var variantSubtle = definePartsStyle2((props) => {
  const { colorScheme: c } = props;
  const fg = mode(`${c}.500`, `${c}.200`)(props);
  return {
    container: {
      [$bg.variable]: getBg(props),
      [$fg.variable]: `colors.${fg}`
    }
  };
});
var variantLeftAccent = definePartsStyle2((props) => {
  const { colorScheme: c } = props;
  const fg = mode(`${c}.500`, `${c}.200`)(props);
  return {
    container: {
      [$bg.variable]: getBg(props),
      [$fg.variable]: `colors.${fg}`,
      paddingStart: "3",
      borderStartWidth: "4px",
      borderStartColor: $fg.reference
    }
  };
});
var variantTopAccent = definePartsStyle2((props) => {
  const { colorScheme: c } = props;
  const fg = mode(`${c}.500`, `${c}.200`)(props);
  return {
    container: {
      [$bg.variable]: getBg(props),
      [$fg.variable]: `colors.${fg}`,
      pt: "2",
      borderTopWidth: "4px",
      borderTopColor: $fg.reference
    }
  };
});
var variantSolid = definePartsStyle2((props) => {
  const { colorScheme: c } = props;
  const bg = mode(`${c}.500`, `${c}.200`)(props);
  const fg = mode(`white`, `gray.900`)(props);
  return {
    container: {
      [$bg.variable]: `colors.${bg}`,
      [$fg.variable]: `colors.${fg}`,
      color: $fg.reference
    }
  };
});
var variants = {
  subtle: variantSubtle,
  "left-accent": variantLeftAccent,
  "top-accent": variantTopAccent,
  solid: variantSolid
};
var alertTheme = defineMultiStyleConfig2({
  baseStyle: baseStyle2,
  variants,
  defaultProps: {
    variant: "subtle",
    colorScheme: "blue"
  }
});

// src/components/avatar.ts
import { avatarAnatomy as parts3 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers3,
  defineStyle as defineStyle2
} from "@chakra-ui/styled-system";
import { isDark, mode as mode2, randomColor } from "@chakra-ui/theme-tools";

// src/foundations/spacing.ts
var spacing = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem"
};

// src/foundations/sizes.ts
var largeSizes = {
  max: "max-content",
  min: "min-content",
  full: "100%",
  "3xs": "14rem",
  "2xs": "16rem",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",
  "8xl": "90rem",
  prose: "60ch"
};
var container = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px"
};
var sizes = {
  ...spacing,
  ...largeSizes,
  container
};
var sizes_default = sizes;

// src/utils/run-if-fn.ts
var isFunction = (value) => typeof value === "function";
function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

// src/components/avatar.ts
var { definePartsStyle: definePartsStyle3, defineMultiStyleConfig: defineMultiStyleConfig3 } = createMultiStyleConfigHelpers3(parts3.keys);
var baseStyleBadge = defineStyle2((props) => {
  return {
    borderRadius: "full",
    border: "0.2em solid",
    borderColor: mode2("white", "gray.800")(props)
  };
});
var baseStyleExcessLabel = defineStyle2((props) => {
  return {
    bg: mode2("gray.200", "whiteAlpha.400")(props)
  };
});
var baseStyleContainer2 = defineStyle2((props) => {
  const { name, theme: theme2 } = props;
  const bg = name ? randomColor({ string: name }) : "gray.400";
  const isBgDark = isDark(bg)(theme2);
  let color = "white";
  if (!isBgDark)
    color = "gray.800";
  const borderColor = mode2("white", "gray.800")(props);
  return {
    bg,
    color,
    borderColor,
    verticalAlign: "top"
  };
});
var baseStyle3 = definePartsStyle3((props) => ({
  badge: runIfFn(baseStyleBadge, props),
  excessLabel: runIfFn(baseStyleExcessLabel, props),
  container: runIfFn(baseStyleContainer2, props)
}));
function getSize(size2) {
  const themeSize = size2 !== "100%" ? sizes_default[size2] : void 0;
  return definePartsStyle3({
    container: {
      width: size2,
      height: size2,
      fontSize: `calc(${themeSize ?? size2} / 2.5)`
    },
    excessLabel: {
      width: size2,
      height: size2
    },
    label: {
      fontSize: `calc(${themeSize ?? size2} / 2.5)`,
      lineHeight: size2 !== "100%" ? themeSize ?? size2 : void 0
    }
  });
}
var sizes2 = {
  "2xs": getSize(4),
  xs: getSize(6),
  sm: getSize(8),
  md: getSize(12),
  lg: getSize(16),
  xl: getSize(24),
  "2xl": getSize(32),
  full: getSize("100%")
};
var avatarTheme = defineMultiStyleConfig3({
  baseStyle: baseStyle3,
  sizes: sizes2,
  defaultProps: { size: "md" }
});

// src/components/badge.ts
import { defineStyle as defineStyle3, defineStyleConfig } from "@chakra-ui/styled-system";
import { getColor as getColor2, mode as mode3, transparentize as transparentize2 } from "@chakra-ui/theme-tools";
var baseStyle4 = defineStyle3({
  px: 1,
  textTransform: "uppercase",
  fontSize: "xs",
  borderRadius: "sm",
  fontWeight: "bold"
});
var variantSolid2 = defineStyle3((props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const dark = transparentize2(`${c}.500`, 0.6)(theme2);
  return {
    bg: mode3(`${c}.500`, dark)(props),
    color: mode3(`white`, `whiteAlpha.800`)(props)
  };
});
var variantSubtle2 = defineStyle3((props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const darkBg = transparentize2(`${c}.200`, 0.16)(theme2);
  return {
    bg: mode3(`${c}.100`, darkBg)(props),
    color: mode3(`${c}.800`, `${c}.200`)(props)
  };
});
var variantOutline = defineStyle3((props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const darkColor = transparentize2(`${c}.200`, 0.8)(theme2);
  const lightColor = getColor2(theme2, `${c}.500`);
  const color = mode3(lightColor, darkColor)(props);
  return {
    color,
    boxShadow: `inset 0 0 0px 1px ${color}`
  };
});
var variants2 = {
  solid: variantSolid2,
  subtle: variantSubtle2,
  outline: variantOutline
};
var badgeTheme = defineStyleConfig({
  baseStyle: baseStyle4,
  variants: variants2,
  defaultProps: {
    variant: "subtle",
    colorScheme: "gray"
  }
});

// src/components/breadcrumb.ts
import { breadcrumbAnatomy as parts4 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers4,
  defineStyle as defineStyle4
} from "@chakra-ui/styled-system";
var { defineMultiStyleConfig: defineMultiStyleConfig4, definePartsStyle: definePartsStyle4 } = createMultiStyleConfigHelpers4(parts4.keys);
var baseStyleLink = defineStyle4({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
});
var baseStyle5 = definePartsStyle4({
  link: baseStyleLink
});
var breadcrumbTheme = defineMultiStyleConfig4({
  baseStyle: baseStyle5
});

// src/components/button.ts
import { defineStyle as defineStyle5, defineStyleConfig as defineStyleConfig2 } from "@chakra-ui/styled-system";
import { mode as mode4, transparentize as transparentize3 } from "@chakra-ui/theme-tools";
var baseStyle6 = defineStyle5({
  lineHeight: "1.2",
  borderRadius: "md",
  fontWeight: "semibold",
  transitionProperty: "common",
  transitionDuration: "normal",
  _focusVisible: {
    boxShadow: "outline"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none"
  },
  _hover: {
    _disabled: {
      bg: "initial"
    }
  }
});
var variantGhost = defineStyle5((props) => {
  const { colorScheme: c, theme: theme2 } = props;
  if (c === "gray") {
    return {
      color: mode4(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode4(`gray.100`, `whiteAlpha.200`)(props)
      },
      _active: { bg: mode4(`gray.200`, `whiteAlpha.300`)(props) }
    };
  }
  const darkHoverBg = transparentize3(`${c}.200`, 0.12)(theme2);
  const darkActiveBg = transparentize3(`${c}.200`, 0.24)(theme2);
  return {
    color: mode4(`${c}.600`, `${c}.200`)(props),
    bg: "transparent",
    _hover: {
      bg: mode4(`${c}.50`, darkHoverBg)(props)
    },
    _active: {
      bg: mode4(`${c}.100`, darkActiveBg)(props)
    }
  };
});
var variantOutline2 = defineStyle5((props) => {
  const { colorScheme: c } = props;
  const borderColor = mode4(`gray.200`, `whiteAlpha.300`)(props);
  return {
    border: "1px solid",
    borderColor: c === "gray" ? borderColor : "currentColor",
    ".chakra-button__group[data-attached] > &:not(:last-of-type)": {
      marginEnd: "-1px"
    },
    ...runIfFn(variantGhost, props)
  };
});
var accessibleColorMap = {
  yellow: {
    bg: "yellow.400",
    color: "black",
    hoverBg: "yellow.500",
    activeBg: "yellow.600"
  },
  cyan: {
    bg: "cyan.400",
    color: "black",
    hoverBg: "cyan.500",
    activeBg: "cyan.600"
  }
};
var variantSolid3 = defineStyle5((props) => {
  const { colorScheme: c } = props;
  if (c === "gray") {
    const bg2 = mode4(`gray.100`, `whiteAlpha.200`)(props);
    return {
      bg: bg2,
      _hover: {
        bg: mode4(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg: bg2
        }
      },
      _active: { bg: mode4(`gray.300`, `whiteAlpha.400`)(props) }
    };
  }
  const {
    bg = `${c}.500`,
    color = "white",
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`
  } = accessibleColorMap[c] ?? {};
  const background = mode4(bg, `${c}.200`)(props);
  return {
    bg: background,
    color: mode4(color, `gray.800`)(props),
    _hover: {
      bg: mode4(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background
      }
    },
    _active: { bg: mode4(activeBg, `${c}.400`)(props) }
  };
});
var variantLink = defineStyle5((props) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: "auto",
    lineHeight: "normal",
    verticalAlign: "baseline",
    color: mode4(`${c}.500`, `${c}.200`)(props),
    _hover: {
      textDecoration: "underline",
      _disabled: {
        textDecoration: "none"
      }
    },
    _active: {
      color: mode4(`${c}.700`, `${c}.500`)(props)
    }
  };
});
var variantUnstyled = defineStyle5({
  bg: "none",
  color: "inherit",
  display: "inline",
  lineHeight: "inherit",
  m: "0",
  p: "0"
});
var variants3 = {
  ghost: variantGhost,
  outline: variantOutline2,
  solid: variantSolid3,
  link: variantLink,
  unstyled: variantUnstyled
};
var sizes3 = {
  lg: defineStyle5({
    h: "12",
    minW: "12",
    fontSize: "lg",
    px: "6"
  }),
  md: defineStyle5({
    h: "10",
    minW: "10",
    fontSize: "md",
    px: "4"
  }),
  sm: defineStyle5({
    h: "8",
    minW: "8",
    fontSize: "sm",
    px: "3"
  }),
  xs: defineStyle5({
    h: "6",
    minW: "6",
    fontSize: "xs",
    px: "2"
  })
};
var buttonTheme = defineStyleConfig2({
  baseStyle: baseStyle6,
  variants: variants3,
  sizes: sizes3,
  defaultProps: {
    variant: "solid",
    size: "md",
    colorScheme: "gray"
  }
});

// src/components/checkbox.ts
import { checkboxAnatomy as parts5 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers5,
  cssVar as cssVar2,
  defineStyle as defineStyle6
} from "@chakra-ui/styled-system";
import { mode as mode5 } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle5, defineMultiStyleConfig: defineMultiStyleConfig5 } = createMultiStyleConfigHelpers5(parts5.keys);
var $size = cssVar2("checkbox-size");
var baseStyleControl = defineStyle6((props) => {
  const { colorScheme: c } = props;
  return {
    w: $size.reference,
    h: $size.reference,
    transitionProperty: "box-shadow",
    transitionDuration: "normal",
    border: "2px solid",
    borderRadius: "sm",
    borderColor: "inherit",
    color: "white",
    _checked: {
      bg: mode5(`${c}.500`, `${c}.200`)(props),
      borderColor: mode5(`${c}.500`, `${c}.200`)(props),
      color: mode5("white", "gray.900")(props),
      _hover: {
        bg: mode5(`${c}.600`, `${c}.300`)(props),
        borderColor: mode5(`${c}.600`, `${c}.300`)(props)
      },
      _disabled: {
        borderColor: mode5("gray.200", "transparent")(props),
        bg: mode5("gray.200", "whiteAlpha.300")(props),
        color: mode5("gray.500", "whiteAlpha.500")(props)
      }
    },
    _indeterminate: {
      bg: mode5(`${c}.500`, `${c}.200`)(props),
      borderColor: mode5(`${c}.500`, `${c}.200`)(props),
      color: mode5("white", "gray.900")(props)
    },
    _disabled: {
      bg: mode5("gray.100", "whiteAlpha.100")(props),
      borderColor: mode5("gray.100", "transparent")(props)
    },
    _focusVisible: {
      boxShadow: "outline"
    },
    _invalid: {
      borderColor: mode5("red.500", "red.300")(props)
    }
  };
});
var baseStyleContainer3 = defineStyle6({
  _disabled: { cursor: "not-allowed" }
});
var baseStyleLabel = defineStyle6({
  userSelect: "none",
  _disabled: { opacity: 0.4 }
});
var baseStyleIcon2 = defineStyle6({
  transitionProperty: "transform",
  transitionDuration: "normal"
});
var baseStyle7 = definePartsStyle5((props) => ({
  icon: baseStyleIcon2,
  container: baseStyleContainer3,
  control: runIfFn(baseStyleControl, props),
  label: baseStyleLabel
}));
var sizes4 = {
  sm: definePartsStyle5({
    control: { [$size.variable]: "sizes.3" },
    label: { fontSize: "sm" },
    icon: { fontSize: "3xs" }
  }),
  md: definePartsStyle5({
    control: { [$size.variable]: "sizes.4" },
    label: { fontSize: "md" },
    icon: { fontSize: "2xs" }
  }),
  lg: definePartsStyle5({
    control: { [$size.variable]: "sizes.5" },
    label: { fontSize: "lg" },
    icon: { fontSize: "2xs" }
  })
};
var checkboxTheme = defineMultiStyleConfig5({
  baseStyle: baseStyle7,
  sizes: sizes4,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/close-button.ts
import { defineStyle as defineStyle7, defineStyleConfig as defineStyleConfig3 } from "@chakra-ui/styled-system";
import { cssVar as cssVar3, mode as mode6 } from "@chakra-ui/theme-tools";
var $size2 = cssVar3("close-button-size");
var baseStyle8 = defineStyle7((props) => {
  const hoverBg = mode6(`blackAlpha.100`, `whiteAlpha.100`)(props);
  const activeBg = mode6(`blackAlpha.200`, `whiteAlpha.200`)(props);
  return {
    w: [$size2.reference],
    h: [$size2.reference],
    borderRadius: "md",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none"
    },
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
    _focusVisible: {
      boxShadow: "outline"
    }
  };
});
var sizes5 = {
  lg: defineStyle7({
    [$size2.variable]: "sizes.10",
    fontSize: "md"
  }),
  md: defineStyle7({
    [$size2.variable]: "sizes.8",
    fontSize: "xs"
  }),
  sm: defineStyle7({
    [$size2.variable]: "sizes.6",
    fontSize: "2xs"
  })
};
var closeButtonTheme = defineStyleConfig3({
  baseStyle: baseStyle8,
  sizes: sizes5,
  defaultProps: {
    size: "md"
  }
});

// src/components/code.ts
import { defineStyle as defineStyle8, defineStyleConfig as defineStyleConfig4 } from "@chakra-ui/styled-system";
var { variants: variants4, defaultProps } = badgeTheme;
var baseStyle9 = defineStyle8({
  fontFamily: "mono",
  fontSize: "sm",
  px: "0.2em",
  borderRadius: "sm"
});
var codeTheme = defineStyleConfig4({
  baseStyle: baseStyle9,
  variants: variants4,
  defaultProps
});

// src/components/container.ts
import { defineStyle as defineStyle9, defineStyleConfig as defineStyleConfig5 } from "@chakra-ui/styled-system";
var baseStyle10 = defineStyle9({
  w: "100%",
  mx: "auto",
  maxW: "prose",
  px: "4"
});
var containerTheme = defineStyleConfig5({
  baseStyle: baseStyle10
});

// src/components/divider.ts
import { defineStyle as defineStyle10, defineStyleConfig as defineStyleConfig6 } from "@chakra-ui/styled-system";
var baseStyle11 = defineStyle10({
  opacity: 0.6,
  borderColor: "inherit"
});
var variantSolid4 = defineStyle10({
  borderStyle: "solid"
});
var variantDashed = defineStyle10({
  borderStyle: "dashed"
});
var variants5 = {
  solid: variantSolid4,
  dashed: variantDashed
};
var dividerTheme = defineStyleConfig6({
  baseStyle: baseStyle11,
  variants: variants5,
  defaultProps: {
    variant: "solid"
  }
});

// src/components/drawer.ts
import { drawerAnatomy as parts6 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers6,
  defineStyle as defineStyle11
} from "@chakra-ui/styled-system";
import { mode as mode7 } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle6, defineMultiStyleConfig: defineMultiStyleConfig6 } = createMultiStyleConfigHelpers6(parts6.keys);
function getSize2(value) {
  if (value === "full") {
    return definePartsStyle6({
      dialog: { maxW: "100vw", h: "100vh" }
    });
  }
  return definePartsStyle6({
    dialog: { maxW: value }
  });
}
var baseStyleOverlay = defineStyle11({
  bg: "blackAlpha.600",
  zIndex: "overlay"
});
var baseStyleDialogContainer = defineStyle11({
  display: "flex",
  zIndex: "modal",
  justifyContent: "center"
});
var baseStyleDialog = defineStyle11((props) => {
  const { isFullHeight } = props;
  return {
    ...isFullHeight && { height: "100vh" },
    zIndex: "modal",
    maxH: "100vh",
    bg: mode7("white", "gray.700")(props),
    color: "inherit",
    boxShadow: mode7("lg", "dark-lg")(props)
  };
});
var baseStyleHeader = defineStyle11({
  px: "6",
  py: "4",
  fontSize: "xl",
  fontWeight: "semibold"
});
var baseStyleCloseButton = defineStyle11({
  position: "absolute",
  top: "2",
  insetEnd: "3"
});
var baseStyleBody = defineStyle11({
  px: "6",
  py: "2",
  flex: "1",
  overflow: "auto"
});
var baseStyleFooter = defineStyle11({
  px: "6",
  py: "4"
});
var baseStyle12 = definePartsStyle6((props) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer,
  dialog: runIfFn(baseStyleDialog, props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter
}));
var sizes6 = {
  xs: getSize2("xs"),
  sm: getSize2("md"),
  md: getSize2("lg"),
  lg: getSize2("2xl"),
  xl: getSize2("4xl"),
  full: getSize2("full")
};
var drawerTheme = defineMultiStyleConfig6({
  baseStyle: baseStyle12,
  sizes: sizes6,
  defaultProps: {
    size: "xs"
  }
});

// src/components/editable.ts
import { editableAnatomy as parts7 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers7,
  defineStyle as defineStyle12
} from "@chakra-ui/styled-system";
var { definePartsStyle: definePartsStyle7, defineMultiStyleConfig: defineMultiStyleConfig7 } = createMultiStyleConfigHelpers7(parts7.keys);
var baseStylePreview = defineStyle12({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal"
});
var baseStyleInput = defineStyle12({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: { boxShadow: "outline" },
  _placeholder: { opacity: 0.6 }
});
var baseStyleTextarea = defineStyle12({
  borderRadius: "md",
  py: "1",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: { boxShadow: "outline" },
  _placeholder: { opacity: 0.6 }
});
var baseStyle13 = definePartsStyle7({
  preview: baseStylePreview,
  input: baseStyleInput,
  textarea: baseStyleTextarea
});
var editableTheme = defineMultiStyleConfig7({
  baseStyle: baseStyle13
});

// src/components/form-control.ts
import { formAnatomy as parts8 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers8,
  defineStyle as defineStyle13
} from "@chakra-ui/styled-system";
import { mode as mode8 } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle8, defineMultiStyleConfig: defineMultiStyleConfig8 } = createMultiStyleConfigHelpers8(parts8.keys);
var baseStyleRequiredIndicator = defineStyle13((props) => {
  return {
    marginStart: "1",
    color: mode8("red.500", "red.300")(props)
  };
});
var baseStyleHelperText = defineStyle13((props) => {
  return {
    mt: "2",
    color: mode8("gray.600", "whiteAlpha.600")(props),
    lineHeight: "normal",
    fontSize: "sm"
  };
});
var baseStyle14 = definePartsStyle8((props) => ({
  container: {
    width: "100%",
    position: "relative"
  },
  requiredIndicator: runIfFn(baseStyleRequiredIndicator, props),
  helperText: runIfFn(baseStyleHelperText, props)
}));
var formTheme = defineMultiStyleConfig8({
  baseStyle: baseStyle14
});

// src/components/form-error.ts
import { formErrorAnatomy as parts9 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers9,
  defineStyle as defineStyle14
} from "@chakra-ui/styled-system";
import { mode as mode9 } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle9, defineMultiStyleConfig: defineMultiStyleConfig9 } = createMultiStyleConfigHelpers9(parts9.keys);
var baseStyleText = defineStyle14((props) => {
  return {
    color: mode9("red.500", "red.300")(props),
    mt: "2",
    fontSize: "sm",
    lineHeight: "normal"
  };
});
var baseStyleIcon3 = defineStyle14((props) => {
  return {
    marginEnd: "0.5em",
    color: mode9("red.500", "red.300")(props)
  };
});
var baseStyle15 = definePartsStyle9((props) => ({
  text: runIfFn(baseStyleText, props),
  icon: runIfFn(baseStyleIcon3, props)
}));
var formErrorTheme = defineMultiStyleConfig9({
  baseStyle: baseStyle15
});

// src/components/form-label.ts
import { defineStyle as defineStyle15, defineStyleConfig as defineStyleConfig7 } from "@chakra-ui/styled-system";
var baseStyle16 = defineStyle15({
  fontSize: "md",
  marginEnd: "3",
  mb: "2",
  fontWeight: "medium",
  transitionProperty: "common",
  transitionDuration: "normal",
  opacity: 1,
  _disabled: {
    opacity: 0.4
  }
});
var formLabelTheme = defineStyleConfig7({
  baseStyle: baseStyle16
});

// src/components/heading.ts
import { defineStyle as defineStyle16, defineStyleConfig as defineStyleConfig8 } from "@chakra-ui/styled-system";
var baseStyle17 = defineStyle16({
  fontFamily: "heading",
  fontWeight: "bold"
});
var sizes7 = {
  "4xl": defineStyle16({
    fontSize: ["6xl", null, "7xl"],
    lineHeight: 1
  }),
  "3xl": defineStyle16({
    fontSize: ["5xl", null, "6xl"],
    lineHeight: 1
  }),
  "2xl": defineStyle16({
    fontSize: ["4xl", null, "5xl"],
    lineHeight: [1.2, null, 1]
  }),
  xl: defineStyle16({
    fontSize: ["3xl", null, "4xl"],
    lineHeight: [1.33, null, 1.2]
  }),
  lg: defineStyle16({
    fontSize: ["2xl", null, "3xl"],
    lineHeight: [1.33, null, 1.2]
  }),
  md: defineStyle16({
    fontSize: "xl",
    lineHeight: 1.2
  }),
  sm: defineStyle16({
    fontSize: "md",
    lineHeight: 1.2
  }),
  xs: defineStyle16({
    fontSize: "sm",
    lineHeight: 1.2
  })
};
var headingTheme = defineStyleConfig8({
  baseStyle: baseStyle17,
  sizes: sizes7,
  defaultProps: {
    size: "xl"
  }
});

// src/components/input.ts
import { inputAnatomy as parts10 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers10,
  defineStyle as defineStyle17
} from "@chakra-ui/styled-system";
import { getColor as getColor3, mode as mode10 } from "@chakra-ui/theme-tools";
var { definePartsStyle: definePartsStyle10, defineMultiStyleConfig: defineMultiStyleConfig10 } = createMultiStyleConfigHelpers10(parts10.keys);
var baseStyle18 = definePartsStyle10({
  field: {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  }
});
var size = {
  lg: defineStyle17({
    fontSize: "lg",
    px: "4",
    h: "12",
    borderRadius: "md"
  }),
  md: defineStyle17({
    fontSize: "md",
    px: "4",
    h: "10",
    borderRadius: "md"
  }),
  sm: defineStyle17({
    fontSize: "sm",
    px: "3",
    h: "8",
    borderRadius: "sm"
  }),
  xs: defineStyle17({
    fontSize: "xs",
    px: "2",
    h: "6",
    borderRadius: "sm"
  })
};
var sizes8 = {
  lg: definePartsStyle10({
    field: size.lg,
    addon: size.lg
  }),
  md: definePartsStyle10({
    field: size.md,
    addon: size.md
  }),
  sm: definePartsStyle10({
    field: size.sm,
    addon: size.sm
  }),
  xs: definePartsStyle10({
    field: size.xs,
    addon: size.xs
  })
};
function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode10("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode10("red.500", "red.300")(props)
  };
}
var variantOutline3 = definePartsStyle10((props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      border: "1px solid",
      borderColor: "inherit",
      bg: "inherit",
      _hover: {
        borderColor: mode10("gray.300", "whiteAlpha.400")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec),
        boxShadow: `0 0 0 1px ${getColor3(theme2, ec)}`
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: getColor3(theme2, fc),
        boxShadow: `0 0 0 1px ${getColor3(theme2, fc)}`
      }
    },
    addon: {
      border: "1px solid",
      borderColor: mode10("inherit", "whiteAlpha.50")(props),
      bg: mode10("gray.100", "whiteAlpha.300")(props)
    }
  };
});
var variantFilled = definePartsStyle10((props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode10("gray.100", "whiteAlpha.50")(props),
      _hover: {
        bg: mode10("gray.200", "whiteAlpha.100")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec)
      },
      _focusVisible: {
        bg: "transparent",
        borderColor: getColor3(theme2, fc)
      }
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode10("gray.100", "whiteAlpha.50")(props)
    }
  };
});
var variantFlushed = definePartsStyle10((props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      borderBottom: "1px solid",
      borderColor: "inherit",
      borderRadius: "0",
      px: "0",
      bg: "transparent",
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec),
        boxShadow: `0px 1px 0px 0px ${getColor3(theme2, ec)}`
      },
      _focusVisible: {
        borderColor: getColor3(theme2, fc),
        boxShadow: `0px 1px 0px 0px ${getColor3(theme2, fc)}`
      }
    },
    addon: {
      borderBottom: "2px solid",
      borderColor: "inherit",
      borderRadius: "0",
      px: "0",
      bg: "transparent"
    }
  };
});
var variantUnstyled2 = definePartsStyle10({
  field: {
    bg: "transparent",
    px: "0",
    height: "auto"
  },
  addon: {
    bg: "transparent",
    px: "0",
    height: "auto"
  }
});
var variants6 = {
  outline: variantOutline3,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled2
};
var inputTheme = defineMultiStyleConfig10({
  baseStyle: baseStyle18,
  sizes: sizes8,
  variants: variants6,
  defaultProps: {
    size: "md",
    variant: "outline"
  }
});

// src/components/kbd.ts
import { defineStyle as defineStyle18, defineStyleConfig as defineStyleConfig9 } from "@chakra-ui/styled-system";
import { mode as mode11 } from "@chakra-ui/theme-tools";
var baseStyle19 = defineStyle18((props) => {
  return {
    bg: mode11("gray.100", "whiteAlpha")(props),
    borderRadius: "md",
    borderWidth: "1px",
    borderBottomWidth: "3px",
    fontSize: "0.8em",
    fontWeight: "bold",
    lineHeight: "normal",
    px: "0.4em",
    whiteSpace: "nowrap"
  };
});
var kbdTheme = defineStyleConfig9({
  baseStyle: baseStyle19
});

// src/components/link.ts
import { defineStyle as defineStyle19, defineStyleConfig as defineStyleConfig10 } from "@chakra-ui/styled-system";
var baseStyle20 = defineStyle19({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
});
var linkTheme = defineStyleConfig10({
  baseStyle: baseStyle20
});

// src/components/list.ts
import { listAnatomy as parts11 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers11,
  defineStyle as defineStyle20
} from "@chakra-ui/styled-system";
var { defineMultiStyleConfig: defineMultiStyleConfig11, definePartsStyle: definePartsStyle11 } = createMultiStyleConfigHelpers11(parts11.keys);
var baseStyleIcon4 = defineStyle20({
  marginEnd: "2",
  display: "inline",
  verticalAlign: "text-bottom"
});
var baseStyle21 = definePartsStyle11({
  icon: baseStyleIcon4
});
var listTheme = defineMultiStyleConfig11({
  baseStyle: baseStyle21
});

// src/components/menu.ts
import { menuAnatomy as parts12 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers12,
  defineStyle as defineStyle21
} from "@chakra-ui/styled-system";
import { mode as mode12 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig12, definePartsStyle: definePartsStyle12 } = createMultiStyleConfigHelpers12(parts12.keys);
var baseStyleList = defineStyle21((props) => {
  return {
    bg: mode12("#fff", "gray.700")(props),
    boxShadow: mode12("sm", "dark-lg")(props),
    color: "inherit",
    minW: "3xs",
    py: "2",
    zIndex: 1,
    borderRadius: "md",
    borderWidth: "1px"
  };
});
var baseStyleItem = defineStyle21((props) => {
  return {
    py: "1.5",
    px: "3",
    transitionProperty: "background",
    transitionDuration: "ultra-fast",
    transitionTimingFunction: "ease-in",
    _focus: {
      bg: mode12("gray.100", "whiteAlpha.100")(props)
    },
    _active: {
      bg: mode12("gray.200", "whiteAlpha.200")(props)
    },
    _expanded: {
      bg: mode12("gray.100", "whiteAlpha.100")(props)
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
});
var baseStyleGroupTitle = defineStyle21({
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm"
});
var baseStyleCommand = defineStyle21({
  opacity: 0.6
});
var baseStyleDivider = defineStyle21({
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "2",
  opacity: 0.6
});
var baseStyleButton2 = defineStyle21({
  transitionProperty: "common",
  transitionDuration: "normal"
});
var baseStyle22 = definePartsStyle12((props) => ({
  button: baseStyleButton2,
  list: runIfFn(baseStyleList, props),
  item: runIfFn(baseStyleItem, props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider
}));
var menuTheme = defineMultiStyleConfig12({
  baseStyle: baseStyle22
});

// src/components/modal.ts
import { modalAnatomy as parts13 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers13,
  defineStyle as defineStyle22
} from "@chakra-ui/styled-system";
import { mode as mode13 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig13, definePartsStyle: definePartsStyle13 } = createMultiStyleConfigHelpers13(parts13.keys);
var baseStyleOverlay2 = defineStyle22({
  bg: "blackAlpha.600",
  zIndex: "modal"
});
var baseStyleDialogContainer2 = defineStyle22((props) => {
  const { isCentered, scrollBehavior } = props;
  return {
    display: "flex",
    zIndex: "modal",
    justifyContent: "center",
    alignItems: isCentered ? "center" : "flex-start",
    overflow: scrollBehavior === "inside" ? "hidden" : "auto"
  };
});
var baseStyleDialog2 = defineStyle22((props) => {
  const { scrollBehavior } = props;
  return {
    borderRadius: "md",
    bg: mode13("white", "gray.700")(props),
    color: "inherit",
    my: "16",
    zIndex: "modal",
    maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : void 0,
    boxShadow: mode13("lg", "dark-lg")(props)
  };
});
var baseStyleHeader2 = defineStyle22({
  px: "6",
  py: "4",
  fontSize: "xl",
  fontWeight: "semibold"
});
var baseStyleCloseButton2 = defineStyle22({
  position: "absolute",
  top: "2",
  insetEnd: "3"
});
var baseStyleBody2 = defineStyle22((props) => {
  const { scrollBehavior } = props;
  return {
    px: "6",
    py: "2",
    flex: "1",
    overflow: scrollBehavior === "inside" ? "auto" : void 0
  };
});
var baseStyleFooter2 = defineStyle22({
  px: "6",
  py: "4"
});
var baseStyle23 = definePartsStyle13((props) => ({
  overlay: baseStyleOverlay2,
  dialogContainer: runIfFn(baseStyleDialogContainer2, props),
  dialog: runIfFn(baseStyleDialog2, props),
  header: baseStyleHeader2,
  closeButton: baseStyleCloseButton2,
  body: runIfFn(baseStyleBody2, props),
  footer: baseStyleFooter2
}));
function getSize3(value) {
  if (value === "full") {
    return definePartsStyle13({
      dialog: {
        maxW: "100vw",
        minH: "$100vh",
        my: "0",
        borderRadius: "0"
      }
    });
  }
  return definePartsStyle13({
    dialog: { maxW: value }
  });
}
var sizes9 = {
  xs: getSize3("xs"),
  sm: getSize3("sm"),
  md: getSize3("md"),
  lg: getSize3("lg"),
  xl: getSize3("xl"),
  "2xl": getSize3("2xl"),
  "3xl": getSize3("3xl"),
  "4xl": getSize3("4xl"),
  "5xl": getSize3("5xl"),
  "6xl": getSize3("6xl"),
  full: getSize3("full")
};
var modalTheme = defineMultiStyleConfig13({
  baseStyle: baseStyle23,
  sizes: sizes9,
  defaultProps: { size: "md" }
});

// src/components/number-input.ts
import { numberInputAnatomy as parts14 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers14,
  defineStyle as defineStyle23
} from "@chakra-ui/styled-system";
import { calc, cssVar as cssVar4, mode as mode14 } from "@chakra-ui/theme-tools";

// src/foundations/typography.ts
var typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em"
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem"
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`
  },
  fontSizes: {
    "3xs": "0.45rem",
    "2xs": "0.625rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem"
  }
};
var typography_default = typography;

// src/components/number-input.ts
var { defineMultiStyleConfig: defineMultiStyleConfig14, definePartsStyle: definePartsStyle14 } = createMultiStyleConfigHelpers14(parts14.keys);
var $stepperWidth = cssVar4("number-input-stepper-width");
var $inputPadding = cssVar4("number-input-input-padding");
var inputPaddingValue = calc($stepperWidth).add("0.5rem").toString();
var baseStyleRoot = defineStyle23({
  [$stepperWidth.variable]: "sizes.6",
  [$inputPadding.variable]: inputPaddingValue
});
var baseStyleField = defineStyle23((props) => {
  var _a7;
  return ((_a7 = runIfFn(inputTheme.baseStyle, props)) == null ? void 0 : _a7.field) ?? {};
});
var baseStyleStepperGroup = defineStyle23({
  width: [$stepperWidth.reference]
});
var baseStyleStepper = defineStyle23((props) => {
  return {
    borderStart: "1px solid",
    borderStartColor: mode14("inherit", "whiteAlpha.300")(props),
    color: mode14("inherit", "whiteAlpha.800")(props),
    _active: {
      bg: mode14("gray.200", "whiteAlpha.300")(props)
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
});
var baseStyle24 = definePartsStyle14((props) => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: runIfFn(baseStyleStepper, props) ?? {}
}));
function getSize4(size2) {
  var _a7, _b3;
  const sizeStyle = (_a7 = inputTheme.sizes) == null ? void 0 : _a7[size2];
  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm"
  };
  const _fontSize = ((_b3 = sizeStyle.field) == null ? void 0 : _b3.fontSize) ?? "md";
  const fontSize = typography_default.fontSizes[_fontSize];
  return definePartsStyle14({
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: "top"
    },
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size2]
      },
      _last: {
        borderBottomEndRadius: radius[size2],
        mt: "-1px",
        borderTopWidth: 1
      }
    }
  });
}
var sizes10 = {
  xs: getSize4("xs"),
  sm: getSize4("sm"),
  md: getSize4("md"),
  lg: getSize4("lg")
};
var numberInputTheme = defineMultiStyleConfig14({
  baseStyle: baseStyle24,
  sizes: sizes10,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});

// src/components/pin-input.ts
import { defineStyle as defineStyle24, defineStyleConfig as defineStyleConfig11 } from "@chakra-ui/styled-system";
var _a;
var baseStyle25 = defineStyle24({
  ...(_a = inputTheme.baseStyle) == null ? void 0 : _a.field,
  textAlign: "center"
});
var sizes11 = {
  lg: defineStyle24({
    fontSize: "lg",
    w: 12,
    h: 12,
    borderRadius: "md"
  }),
  md: defineStyle24({
    fontSize: "md",
    w: 10,
    h: 10,
    borderRadius: "md"
  }),
  sm: defineStyle24({
    fontSize: "sm",
    w: 8,
    h: 8,
    borderRadius: "sm"
  }),
  xs: defineStyle24({
    fontSize: "xs",
    w: 6,
    h: 6,
    borderRadius: "sm"
  })
};
var _a2;
var variants7 = {
  outline: defineStyle24((props) => {
    var _a7, _b3;
    return ((_b3 = runIfFn((_a7 = inputTheme.variants) == null ? void 0 : _a7.outline, props)) == null ? void 0 : _b3.field) ?? {};
  }),
  flushed: defineStyle24((props) => {
    var _a7, _b3;
    return ((_b3 = runIfFn((_a7 = inputTheme.variants) == null ? void 0 : _a7.flushed, props)) == null ? void 0 : _b3.field) ?? {};
  }),
  filled: defineStyle24((props) => {
    var _a7, _b3;
    return ((_b3 = runIfFn((_a7 = inputTheme.variants) == null ? void 0 : _a7.filled, props)) == null ? void 0 : _b3.field) ?? {};
  }),
  unstyled: ((_a2 = inputTheme.variants) == null ? void 0 : _a2.unstyled.field) ?? {}
};
var pinInputTheme = defineStyleConfig11({
  baseStyle: baseStyle25,
  sizes: sizes11,
  variants: variants7,
  defaultProps: inputTheme.defaultProps
});

// src/components/popover.ts
import { popoverAnatomy as parts15 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers15,
  defineStyle as defineStyle25
} from "@chakra-ui/styled-system";
import { cssVar as cssVar5, mode as mode15 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig15, definePartsStyle: definePartsStyle15 } = createMultiStyleConfigHelpers15(parts15.keys);
var $popperBg = cssVar5("popper-bg");
var $arrowBg = cssVar5("popper-arrow-bg");
var $arrowShadowColor = cssVar5("popper-arrow-shadow-color");
var baseStylePopper = defineStyle25({ zIndex: 10 });
var baseStyleContent = defineStyle25((props) => {
  const bg = mode15("white", "gray.700")(props);
  const shadowColor = mode15("gray.200", "whiteAlpha.300")(props);
  return {
    [$popperBg.variable]: `colors.${bg}`,
    bg: $popperBg.reference,
    [$arrowBg.variable]: $popperBg.reference,
    [$arrowShadowColor.variable]: `colors.${shadowColor}`,
    width: "xs",
    border: "1px solid",
    borderColor: "inherit",
    borderRadius: "md",
    boxShadow: "sm",
    zIndex: "inherit",
    _focusVisible: {
      outline: 0,
      boxShadow: "outline"
    }
  };
});
var baseStyleHeader3 = defineStyle25({
  px: 3,
  py: 2,
  borderBottomWidth: "1px"
});
var baseStyleBody3 = defineStyle25({
  px: 3,
  py: 2
});
var baseStyleFooter3 = defineStyle25({
  px: 3,
  py: 2,
  borderTopWidth: "1px"
});
var baseStyleCloseButton3 = defineStyle25({
  position: "absolute",
  borderRadius: "md",
  top: 1,
  insetEnd: 2,
  padding: 2
});
var baseStyle26 = definePartsStyle15((props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader3,
  body: baseStyleBody3,
  footer: baseStyleFooter3,
  closeButton: baseStyleCloseButton3
}));
var popoverTheme = defineMultiStyleConfig15({
  baseStyle: baseStyle26
});

// src/components/progress.ts
import { progressAnatomy as parts16 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers16,
  defineStyle as defineStyle26
} from "@chakra-ui/styled-system";
import { generateStripe, getColor as getColor4, mode as mode16 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig16, definePartsStyle: definePartsStyle16 } = createMultiStyleConfigHelpers16(parts16.keys);
var filledStyle = defineStyle26((props) => {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;
  const stripeStyle = mode16(generateStripe(), generateStripe("1rem", "rgba(0,0,0,0.1)"))(props);
  const bgColor = mode16(`${c}.500`, `${c}.200`)(props);
  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor4(t, bgColor)} 50%,
    transparent 100%
  )`;
  const addStripe = !isIndeterminate && hasStripe;
  return {
    ...addStripe && stripeStyle,
    ...isIndeterminate ? { bgImage: gradient } : { bgColor }
  };
});
var baseStyleLabel2 = defineStyle26({
  lineHeight: "1",
  fontSize: "0.25em",
  fontWeight: "bold",
  color: "white"
});
var baseStyleTrack = defineStyle26((props) => {
  return {
    bg: mode16("gray.100", "whiteAlpha.300")(props)
  };
});
var baseStyleFilledTrack = defineStyle26((props) => {
  return {
    transitionProperty: "common",
    transitionDuration: "slow",
    ...filledStyle(props)
  };
});
var baseStyle27 = definePartsStyle16((props) => ({
  label: baseStyleLabel2,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props)
}));
var sizes12 = {
  xs: definePartsStyle16({
    track: { h: "1" }
  }),
  sm: definePartsStyle16({
    track: { h: "2" }
  }),
  md: definePartsStyle16({
    track: { h: "3" }
  }),
  lg: definePartsStyle16({
    track: { h: "4" }
  })
};
var progressTheme = defineMultiStyleConfig16({
  sizes: sizes12,
  baseStyle: baseStyle27,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/radio.ts
import { radioAnatomy as parts17 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers17,
  defineStyle as defineStyle27
} from "@chakra-ui/styled-system";
var { defineMultiStyleConfig: defineMultiStyleConfig17, definePartsStyle: definePartsStyle17 } = createMultiStyleConfigHelpers17(parts17.keys);
var baseStyleControl2 = defineStyle27((props) => {
  var _a7;
  const controlStyle = (_a7 = runIfFn(checkboxTheme.baseStyle, props)) == null ? void 0 : _a7.control;
  return {
    ...controlStyle,
    borderRadius: "full",
    _checked: {
      ...controlStyle == null ? void 0 : controlStyle["_checked"],
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        w: "50%",
        h: "50%",
        borderRadius: "50%",
        bg: "currentColor"
      }
    }
  };
});
var baseStyle28 = definePartsStyle17((props) => {
  var _a7, _b3, _c3, _d3;
  return {
    label: (_b3 = (_a7 = checkboxTheme).baseStyle) == null ? void 0 : _b3.call(_a7, props).label,
    container: (_d3 = (_c3 = checkboxTheme).baseStyle) == null ? void 0 : _d3.call(_c3, props).container,
    control: baseStyleControl2(props)
  };
});
var sizes13 = {
  md: definePartsStyle17({
    control: { w: "4", h: "4" },
    label: { fontSize: "md" }
  }),
  lg: definePartsStyle17({
    control: { w: "5", h: "5" },
    label: { fontSize: "lg" }
  }),
  sm: definePartsStyle17({
    control: { width: "3", height: "3" },
    label: { fontSize: "sm" }
  })
};
var radioTheme = defineMultiStyleConfig17({
  baseStyle: baseStyle28,
  sizes: sizes13,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/select.ts
import { selectAnatomy as parts18 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers18,
  defineStyle as defineStyle28
} from "@chakra-ui/styled-system";
import { mode as mode17 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig18, definePartsStyle: definePartsStyle18 } = createMultiStyleConfigHelpers18(parts18.keys);
var baseStyleField2 = defineStyle28((props) => {
  var _a7;
  return {
    ...(_a7 = inputTheme.baseStyle) == null ? void 0 : _a7.field,
    bg: mode17("white", "gray.700")(props),
    appearance: "none",
    paddingBottom: "1px",
    lineHeight: "normal",
    "> option, > optgroup": {
      bg: mode17("white", "gray.700")(props)
    }
  };
});
var baseStyleIcon5 = defineStyle28({
  width: "6",
  height: "100%",
  insetEnd: "2",
  position: "relative",
  color: "currentColor",
  fontSize: "xl",
  _disabled: {
    opacity: 0.5
  }
});
var baseStyle29 = definePartsStyle18((props) => ({
  field: baseStyleField2(props),
  icon: baseStyleIcon5
}));
var iconSpacing = defineStyle28({
  paddingInlineEnd: "8"
});
var _a3, _b, _c, _d, _e, _f, _g, _h;
var sizes14 = {
  lg: {
    ...(_a3 = inputTheme.sizes) == null ? void 0 : _a3.lg,
    field: {
      ...(_b = inputTheme.sizes) == null ? void 0 : _b.lg.field,
      ...iconSpacing
    }
  },
  md: {
    ...(_c = inputTheme.sizes) == null ? void 0 : _c.md,
    field: {
      ...(_d = inputTheme.sizes) == null ? void 0 : _d.md.field,
      ...iconSpacing
    }
  },
  sm: {
    ...(_e = inputTheme.sizes) == null ? void 0 : _e.sm,
    field: {
      ...(_f = inputTheme.sizes) == null ? void 0 : _f.sm.field,
      ...iconSpacing
    }
  },
  xs: {
    ...(_g = inputTheme.sizes) == null ? void 0 : _g.xs,
    field: {
      ...(_h = inputTheme.sizes) == null ? void 0 : _h.sm.field,
      ...iconSpacing
    },
    icon: {
      insetEnd: "1"
    }
  }
};
var selectTheme = defineMultiStyleConfig18({
  baseStyle: baseStyle29,
  sizes: sizes14,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});

// src/components/skeleton.ts
import {
  cssVar as cssVar6,
  defineStyle as defineStyle29,
  defineStyleConfig as defineStyleConfig12
} from "@chakra-ui/styled-system";
import { getColor as getColor5, mode as mode18 } from "@chakra-ui/theme-tools";
var $startColor = cssVar6("skeleton-start-color");
var $endColor = cssVar6("skeleton-end-color");
var baseStyle30 = defineStyle29((props) => {
  const defaultStartColor = mode18("gray.100", "gray.800")(props);
  const defaultEndColor = mode18("gray.400", "gray.600")(props);
  const {
    startColor = defaultStartColor,
    endColor = defaultEndColor,
    theme: theme2
  } = props;
  const start = getColor5(theme2, startColor);
  const end = getColor5(theme2, endColor);
  return {
    [$startColor.variable]: start,
    [$endColor.variable]: end,
    opacity: 0.7,
    borderRadius: "2px",
    borderColor: start,
    background: end
  };
});
var skeletonTheme = defineStyleConfig12({
  baseStyle: baseStyle30
});

// src/components/skip-link.ts
import { defineStyle as defineStyle30, defineStyleConfig as defineStyleConfig13 } from "@chakra-ui/styled-system";
import { mode as mode19 } from "@chakra-ui/theme-tools";
var baseStyle31 = defineStyle30((props) => ({
  borderRadius: "md",
  fontWeight: "semibold",
  _focusVisible: {
    boxShadow: "outline",
    padding: "4",
    position: "fixed",
    top: "6",
    insetStart: "6",
    bg: mode19("white", "gray.700")(props)
  }
}));
var skipLinkTheme = defineStyleConfig13({
  baseStyle: baseStyle31
});

// src/components/slider.ts
import { sliderAnatomy as parts19 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers19,
  cssVar as cssVar7,
  defineStyle as defineStyle31
} from "@chakra-ui/styled-system";
import { mode as mode20, orient } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig19, definePartsStyle: definePartsStyle19 } = createMultiStyleConfigHelpers19(parts19.keys);
var $thumbSize = cssVar7("slider-thumb-size");
var $trackSize = cssVar7("slider-track-size");
var baseStyleContainer4 = defineStyle31((props) => {
  const { orientation } = props;
  return {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    _disabled: {
      opacity: 0.6,
      cursor: "default",
      pointerEvents: "none"
    },
    ...orient({
      orientation,
      vertical: { h: "100%" },
      horizontal: { w: "100%" }
    })
  };
});
var baseStyleTrack2 = defineStyle31((props) => {
  const orientationStyles = orient({
    orientation: props.orientation,
    horizontal: { h: $trackSize.reference },
    vertical: { w: $trackSize.reference }
  });
  return {
    ...orientationStyles,
    overflow: "hidden",
    borderRadius: "sm",
    bg: mode20("gray.200", "whiteAlpha.200")(props),
    _disabled: {
      bg: mode20("gray.300", "whiteAlpha.300")(props)
    }
  };
});
var baseStyleThumb = defineStyle31((props) => {
  const { orientation } = props;
  const orientationStyle = orient({
    orientation,
    vertical: {
      left: "50%",
      transform: `translateX(-50%)`,
      _active: {
        transform: `translateX(-50%) scale(1.15)`
      }
    },
    horizontal: {
      top: "50%",
      transform: `translateY(-50%)`,
      _active: {
        transform: `translateY(-50%) scale(1.15)`
      }
    }
  });
  return {
    ...orientationStyle,
    w: $thumbSize.reference,
    h: $thumbSize.reference,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    outline: 0,
    zIndex: 1,
    borderRadius: "full",
    bg: "white",
    boxShadow: "base",
    border: "1px solid",
    borderColor: "transparent",
    transitionProperty: "transform",
    transitionDuration: "normal",
    _focusVisible: {
      boxShadow: "outline"
    },
    _disabled: {
      bg: "gray.300"
    }
  };
});
var baseStyleFilledTrack2 = defineStyle31((props) => {
  const { colorScheme: c } = props;
  return {
    width: "inherit",
    height: "inherit",
    bg: mode20(`${c}.500`, `${c}.200`)(props)
  };
});
var baseStyle32 = definePartsStyle19((props) => ({
  container: baseStyleContainer4(props),
  track: baseStyleTrack2(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack2(props)
}));
var sizeLg = definePartsStyle19({
  container: {
    [$thumbSize.variable]: `sizes.4`,
    [$trackSize.variable]: `sizes.1`
  }
});
var sizeMd = definePartsStyle19({
  container: {
    [$thumbSize.variable]: `sizes.3.5`,
    [$trackSize.variable]: `sizes.1`
  }
});
var sizeSm = definePartsStyle19({
  container: {
    [$thumbSize.variable]: `sizes.2.5`,
    [$trackSize.variable]: `sizes.0.5`
  }
});
var sizes15 = {
  lg: sizeLg,
  md: sizeMd,
  sm: sizeSm
};
var sliderTheme = defineMultiStyleConfig19({
  baseStyle: baseStyle32,
  sizes: sizes15,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/spinner.ts
import { defineStyle as defineStyle32, defineStyleConfig as defineStyleConfig14 } from "@chakra-ui/styled-system";
import { cssVar as cssVar8 } from "@chakra-ui/theme-tools";
var $size3 = cssVar8("spinner-size");
var baseStyle33 = defineStyle32({
  width: [$size3.reference],
  height: [$size3.reference]
});
var sizes16 = {
  xs: defineStyle32({
    [$size3.variable]: "sizes.3"
  }),
  sm: defineStyle32({
    [$size3.variable]: "sizes.4"
  }),
  md: defineStyle32({
    [$size3.variable]: "sizes.6"
  }),
  lg: defineStyle32({
    [$size3.variable]: "sizes.8"
  }),
  xl: defineStyle32({
    [$size3.variable]: "sizes.12"
  })
};
var spinnerTheme = defineStyleConfig14({
  baseStyle: baseStyle33,
  sizes: sizes16,
  defaultProps: {
    size: "md"
  }
});

// src/components/stat.ts
import { statAnatomy as parts20 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers20,
  defineStyle as defineStyle33
} from "@chakra-ui/styled-system";
var { defineMultiStyleConfig: defineMultiStyleConfig20, definePartsStyle: definePartsStyle20 } = createMultiStyleConfigHelpers20(parts20.keys);
var baseStyleLabel3 = defineStyle33({
  fontWeight: "medium"
});
var baseStyleHelpText = defineStyle33({
  opacity: 0.8,
  marginBottom: "2"
});
var baseStyleNumber = defineStyle33({
  verticalAlign: "baseline",
  fontWeight: "semibold"
});
var baseStyleIcon6 = defineStyle33({
  marginEnd: 1,
  w: "3.5",
  h: "3.5",
  verticalAlign: "middle"
});
var baseStyle34 = definePartsStyle20({
  container: {},
  label: baseStyleLabel3,
  helpText: baseStyleHelpText,
  number: baseStyleNumber,
  icon: baseStyleIcon6
});
var sizes17 = {
  md: definePartsStyle20({
    label: { fontSize: "sm" },
    helpText: { fontSize: "sm" },
    number: { fontSize: "2xl" }
  })
};
var statTheme = defineMultiStyleConfig20({
  baseStyle: baseStyle34,
  sizes: sizes17,
  defaultProps: {
    size: "md"
  }
});

// src/components/switch.ts
import { switchAnatomy as parts21 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers21,
  defineStyle as defineStyle34
} from "@chakra-ui/styled-system";
import { calc as calc2, cssVar as cssVar9, mode as mode21 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig21, definePartsStyle: definePartsStyle21 } = createMultiStyleConfigHelpers21(parts21.keys);
var $width = cssVar9("switch-track-width");
var $height = cssVar9("switch-track-height");
var $diff = cssVar9("switch-track-diff");
var diffValue = calc2.subtract($width, $height);
var $translateX = cssVar9("switch-thumb-x");
var baseStyleTrack3 = defineStyle34((props) => {
  const { colorScheme: c } = props;
  return {
    borderRadius: "full",
    p: "0.5",
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: "common",
    transitionDuration: "fast",
    bg: mode21("gray.300", "whiteAlpha.400")(props),
    _focusVisible: {
      boxShadow: "outline"
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    },
    _checked: {
      bg: mode21(`${c}.500`, `${c}.200`)(props)
    }
  };
});
var baseStyleThumb2 = defineStyle34({
  bg: "white",
  transitionProperty: "transform",
  transitionDuration: "normal",
  borderRadius: "inherit",
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`
  }
});
var baseStyle35 = definePartsStyle21((props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc2($diff).negate().toString()
    }
  },
  track: baseStyleTrack3(props),
  thumb: baseStyleThumb2
}));
var sizes18 = {
  sm: definePartsStyle21({
    container: {
      [$width.variable]: "1.375rem",
      [$height.variable]: "sizes.3"
    }
  }),
  md: definePartsStyle21({
    container: {
      [$width.variable]: "1.875rem",
      [$height.variable]: "sizes.4"
    }
  }),
  lg: definePartsStyle21({
    container: {
      [$width.variable]: "2.875rem",
      [$height.variable]: "sizes.6"
    }
  })
};
var switchTheme = defineMultiStyleConfig21({
  baseStyle: baseStyle35,
  sizes: sizes18,
  defaultProps: {
    size: "md",
    colorScheme: "blue"
  }
});

// src/components/table.ts
import { tableAnatomy as parts22 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers22,
  defineStyle as defineStyle35
} from "@chakra-ui/styled-system";
import { mode as mode22 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig22, definePartsStyle: definePartsStyle22 } = createMultiStyleConfigHelpers22(parts22.keys);
var baseStyle36 = definePartsStyle22({
  table: {
    fontVariantNumeric: "lining-nums tabular-nums",
    borderCollapse: "collapse",
    width: "full"
  },
  th: {
    fontFamily: "heading",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    textAlign: "start"
  },
  td: {
    textAlign: "start"
  },
  caption: {
    mt: 4,
    fontFamily: "heading",
    textAlign: "center",
    fontWeight: "medium"
  }
});
var numericStyles = defineStyle35({
  "&[data-is-numeric=true]": {
    textAlign: "end"
  }
});
var variantSimple = definePartsStyle22((props) => {
  const { colorScheme: c } = props;
  return {
    th: {
      color: mode22("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    td: {
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    caption: {
      color: mode22("gray.600", "gray.100")(props)
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
});
var variantStripe = definePartsStyle22((props) => {
  const { colorScheme: c } = props;
  return {
    th: {
      color: mode22("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    td: {
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    caption: {
      color: mode22("gray.600", "gray.100")(props)
    },
    tbody: {
      tr: {
        "&:nth-of-type(odd)": {
          "th, td": {
            borderBottomWidth: "1px",
            borderColor: mode22(`${c}.100`, `${c}.700`)(props)
          },
          td: {
            background: mode22(`${c}.100`, `${c}.700`)(props)
          }
        }
      }
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
});
var variants8 = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: defineStyle35({})
};
var sizes19 = {
  sm: definePartsStyle22({
    th: {
      px: "4",
      py: "1",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "4",
      py: "2",
      fontSize: "sm",
      lineHeight: "4"
    },
    caption: {
      px: "4",
      py: "2",
      fontSize: "xs"
    }
  }),
  md: definePartsStyle22({
    th: {
      px: "6",
      py: "3",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "6",
      py: "4",
      lineHeight: "5"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "sm"
    }
  }),
  lg: definePartsStyle22({
    th: {
      px: "8",
      py: "4",
      lineHeight: "5",
      fontSize: "sm"
    },
    td: {
      px: "8",
      py: "5",
      lineHeight: "6"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "md"
    }
  })
};
var tableTheme = defineMultiStyleConfig22({
  baseStyle: baseStyle36,
  variants: variants8,
  sizes: sizes19,
  defaultProps: {
    variant: "simple",
    size: "md",
    colorScheme: "gray"
  }
});

// src/components/tabs.ts
import { tabsAnatomy as parts23 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers23,
  defineStyle as defineStyle36
} from "@chakra-ui/styled-system";
import { getColor as getColor6, mode as mode23 } from "@chakra-ui/theme-tools";
var { defineMultiStyleConfig: defineMultiStyleConfig23, definePartsStyle: definePartsStyle23 } = createMultiStyleConfigHelpers23(parts23.keys);
var baseStyleRoot2 = defineStyle36((props) => {
  const { orientation } = props;
  return {
    display: orientation === "vertical" ? "flex" : "block"
  };
});
var baseStyleTab = defineStyle36((props) => {
  const { isFitted } = props;
  return {
    flex: isFitted ? 1 : void 0,
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
      zIndex: 1,
      boxShadow: "outline"
    },
    _disabled: {
      cursor: "not-allowed",
      opacity: 0.4
    }
  };
});
var baseStyleTablist = defineStyle36((props) => {
  const { align = "start", orientation } = props;
  const alignments = {
    end: "flex-end",
    center: "center",
    start: "flex-start"
  };
  return {
    justifyContent: alignments[align],
    flexDirection: orientation === "vertical" ? "column" : "row"
  };
});
var baseStyleTabpanel = defineStyle36({
  p: 4
});
var baseStyle37 = definePartsStyle23((props) => ({
  root: baseStyleRoot2(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel
}));
var sizes20 = {
  sm: definePartsStyle23({
    tab: {
      py: 1,
      px: 4,
      fontSize: "sm"
    }
  }),
  md: definePartsStyle23({
    tab: {
      fontSize: "md",
      py: 2,
      px: 4
    }
  }),
  lg: definePartsStyle23({
    tab: {
      fontSize: "lg",
      py: 3,
      px: 4
    }
  })
};
var variantLine = definePartsStyle23((props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === "vertical";
  const borderProp = orientation === "vertical" ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";
  return {
    tablist: {
      [borderProp]: "2px solid",
      borderColor: "inherit"
    },
    tab: {
      [borderProp]: "2px solid",
      borderColor: "transparent",
      [marginProp]: "-2px",
      _selected: {
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "currentColor"
      },
      _active: {
        bg: mode23("gray.200", "whiteAlpha.300")(props)
      },
      _disabled: {
        _active: { bg: "none" }
      }
    }
  };
});
var variantEnclosed = definePartsStyle23((props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderTopRadius: "md",
      border: "1px solid",
      borderColor: "transparent",
      mb: "-1px",
      _selected: {
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "inherit",
        borderBottomColor: mode23(`white`, `gray.800`)(props)
      }
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
});
var variantEnclosedColored = definePartsStyle23((props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      border: "1px solid",
      borderColor: "inherit",
      bg: mode23(`gray.50`, `whiteAlpha.50`)(props),
      mb: "-1px",
      _notLast: {
        marginEnd: "-1px"
      },
      _selected: {
        bg: mode23("#fff", "gray.800")(props),
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "inherit",
        borderTopColor: "currentColor",
        borderBottomColor: "transparent"
      }
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
});
var variantSoftRounded = definePartsStyle23((props) => {
  const { colorScheme: c, theme: theme2 } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      color: "gray.600",
      _selected: {
        color: getColor6(theme2, `${c}.700`),
        bg: getColor6(theme2, `${c}.100`)
      }
    }
  };
});
var variantSolidRounded = definePartsStyle23((props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      color: mode23("gray.600", "inherit")(props),
      _selected: {
        color: mode23(`#fff`, "gray.800")(props),
        bg: mode23(`${c}.600`, `${c}.300`)(props)
      }
    }
  };
});
var variantUnstyled3 = definePartsStyle23({});
var variants9 = {
  line: variantLine,
  enclosed: variantEnclosed,
  "enclosed-colored": variantEnclosedColored,
  "soft-rounded": variantSoftRounded,
  "solid-rounded": variantSolidRounded,
  unstyled: variantUnstyled3
};
var tabsTheme = defineMultiStyleConfig23({
  baseStyle: baseStyle37,
  sizes: sizes20,
  variants: variants9,
  defaultProps: {
    size: "md",
    variant: "line",
    colorScheme: "blue"
  }
});

// src/components/tag.ts
import { tagAnatomy as parts24 } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers as createMultiStyleConfigHelpers24,
  defineStyle as defineStyle37
} from "@chakra-ui/styled-system";
var { defineMultiStyleConfig: defineMultiStyleConfig24, definePartsStyle: definePartsStyle24 } = createMultiStyleConfigHelpers24(parts24.keys);
var baseStyleContainer5 = defineStyle37({
  fontWeight: "medium",
  lineHeight: 1.2,
  outline: 0,
  borderRadius: "md",
  _focusVisible: {
    boxShadow: "outline"
  }
});
var baseStyleLabel4 = defineStyle37({
  lineHeight: 1.2,
  overflow: "visible"
});
var baseStyleCloseButton4 = defineStyle37({
  fontSize: "lg",
  w: "5",
  h: "5",
  transitionProperty: "common",
  transitionDuration: "normal",
  borderRadius: "full",
  marginStart: "1.5",
  marginEnd: "-1",
  opacity: 0.5,
  _disabled: {
    opacity: 0.4
  },
  _focusVisible: {
    boxShadow: "outline",
    bg: "rgba(0, 0, 0, 0.14)"
  },
  _hover: {
    opacity: 0.8
  },
  _active: {
    opacity: 1
  }
});
var baseStyle38 = definePartsStyle24({
  container: baseStyleContainer5,
  label: baseStyleLabel4,
  closeButton: baseStyleCloseButton4
});
var sizes21 = {
  sm: definePartsStyle24({
    container: {
      minH: "5",
      minW: "5",
      fontSize: "xs",
      px: "2"
    },
    closeButton: {
      marginEnd: "-2px",
      marginStart: "0.35rem"
    }
  }),
  md: definePartsStyle24({
    container: {
      minH: "6",
      minW: "6",
      fontSize: "sm",
      px: "2"
    }
  }),
  lg: definePartsStyle24({
    container: {
      minH: "8",
      minW: "8",
      fontSize: "md",
      px: "3"
    }
  })
};
var variants10 = {
  subtle: definePartsStyle24((props) => {
    var _a7;
    return {
      container: (_a7 = badgeTheme.variants) == null ? void 0 : _a7.subtle(props)
    };
  }),
  solid: definePartsStyle24((props) => {
    var _a7;
    return {
      container: (_a7 = badgeTheme.variants) == null ? void 0 : _a7.solid(props)
    };
  }),
  outline: definePartsStyle24((props) => {
    var _a7;
    return {
      container: (_a7 = badgeTheme.variants) == null ? void 0 : _a7.outline(props)
    };
  })
};
var tagTheme = defineMultiStyleConfig24({
  variants: variants10,
  baseStyle: baseStyle38,
  sizes: sizes21,
  defaultProps: {
    size: "md",
    variant: "subtle",
    colorScheme: "gray"
  }
});

// src/components/textarea.ts
import { defineStyle as defineStyle38, defineStyleConfig as defineStyleConfig15 } from "@chakra-ui/styled-system";
var _a4;
var baseStyle39 = defineStyle38({
  ...(_a4 = inputTheme.baseStyle) == null ? void 0 : _a4.field,
  paddingY: "2",
  minHeight: "20",
  lineHeight: "short",
  verticalAlign: "top"
});
var _a5;
var variants11 = {
  outline: defineStyle38((props) => {
    var _a7;
    return ((_a7 = inputTheme.variants) == null ? void 0 : _a7.outline(props).field) ?? {};
  }),
  flushed: defineStyle38((props) => {
    var _a7;
    return ((_a7 = inputTheme.variants) == null ? void 0 : _a7.flushed(props).field) ?? {};
  }),
  filled: defineStyle38((props) => {
    var _a7;
    return ((_a7 = inputTheme.variants) == null ? void 0 : _a7.filled(props).field) ?? {};
  }),
  unstyled: ((_a5 = inputTheme.variants) == null ? void 0 : _a5.unstyled.field) ?? {}
};
var _a6, _b2, _c2, _d2;
var sizes22 = {
  xs: ((_a6 = inputTheme.sizes) == null ? void 0 : _a6.xs.field) ?? {},
  sm: ((_b2 = inputTheme.sizes) == null ? void 0 : _b2.sm.field) ?? {},
  md: ((_c2 = inputTheme.sizes) == null ? void 0 : _c2.md.field) ?? {},
  lg: ((_d2 = inputTheme.sizes) == null ? void 0 : _d2.lg.field) ?? {}
};
var textareaTheme = defineStyleConfig15({
  baseStyle: baseStyle39,
  sizes: sizes22,
  variants: variants11,
  defaultProps: {
    size: "md",
    variant: "outline"
  }
});

// src/components/tooltip.ts
import { defineStyle as defineStyle39, defineStyleConfig as defineStyleConfig16 } from "@chakra-ui/styled-system";
import { cssVar as cssVar10, mode as mode24 } from "@chakra-ui/theme-tools";
var $bg2 = cssVar10("tooltip-bg");
var $fg2 = cssVar10("tooltip-fg");
var $arrowBg2 = cssVar10("popper-arrow-bg");
var baseStyle40 = defineStyle39((props) => {
  const bg = mode24("gray.700", "gray.300")(props);
  const fg = mode24("whiteAlpha.900", "gray.900")(props);
  return {
    bg: $bg2.reference,
    color: $fg2.reference,
    [$bg2.variable]: `colors.${bg}`,
    [$fg2.variable]: `colors.${fg}`,
    [$arrowBg2.variable]: $bg2.reference,
    px: "2",
    py: "0.5",
    borderRadius: "sm",
    fontWeight: "medium",
    fontSize: "sm",
    boxShadow: "md",
    maxW: "xs",
    zIndex: "tooltip"
  };
});
var tooltipTheme = defineStyleConfig16({
  baseStyle: baseStyle40
});

// src/components/index.ts
var components = {
  Accordion: accordionTheme,
  Alert: alertTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Breadcrumb: breadcrumbTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  CloseButton: closeButtonTheme,
  Code: codeTheme,
  Container: containerTheme,
  Divider: dividerTheme,
  Drawer: drawerTheme,
  Editable: editableTheme,
  Form: formTheme,
  FormError: formErrorTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Kbd: kbdTheme,
  Link: linkTheme,
  List: listTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  NumberInput: numberInputTheme,
  PinInput: pinInputTheme,
  Popover: popoverTheme,
  Progress: progressTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Skeleton: skeletonTheme,
  SkipLink: skipLinkTheme,
  Slider: sliderTheme,
  Spinner: spinnerTheme,
  Stat: statTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme
};

// src/foundations/borders.ts
var borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid",
  "4px": "4px solid",
  "8px": "8px solid"
};
var borders_default = borders;

// src/foundations/breakpoints.ts
var breakpoints = {
  base: "0em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em"
};
var breakpoints_default = breakpoints;

// src/foundations/colors.ts
var colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000000",
  white: "#FFFFFF",
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)"
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)"
  },
  gray: {
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923"
  },
  red: {
    50: "#FFF5F5",
    100: "#FED7D7",
    200: "#FEB2B2",
    300: "#FC8181",
    400: "#F56565",
    500: "#E53E3E",
    600: "#C53030",
    700: "#9B2C2C",
    800: "#822727",
    900: "#63171B"
  },
  orange: {
    50: "#FFFAF0",
    100: "#FEEBC8",
    200: "#FBD38D",
    300: "#F6AD55",
    400: "#ED8936",
    500: "#DD6B20",
    600: "#C05621",
    700: "#9C4221",
    800: "#7B341E",
    900: "#652B19"
  },
  yellow: {
    50: "#FFFFF0",
    100: "#FEFCBF",
    200: "#FAF089",
    300: "#F6E05E",
    400: "#ECC94B",
    500: "#D69E2E",
    600: "#B7791F",
    700: "#975A16",
    800: "#744210",
    900: "#5F370E"
  },
  green: {
    50: "#F0FFF4",
    100: "#C6F6D5",
    200: "#9AE6B4",
    300: "#68D391",
    400: "#48BB78",
    500: "#38A169",
    600: "#2F855A",
    700: "#276749",
    800: "#22543D",
    900: "#1C4532"
  },
  teal: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#81E6D9",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#319795",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044"
  },
  blue: {
    50: "#ebf8ff",
    100: "#bee3f8",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2b6cb0",
    700: "#2c5282",
    800: "#2a4365",
    900: "#1A365D"
  },
  cyan: {
    50: "#EDFDFD",
    100: "#C4F1F9",
    200: "#9DECF9",
    300: "#76E4F7",
    400: "#0BC5EA",
    500: "#00B5D8",
    600: "#00A3C4",
    700: "#0987A0",
    800: "#086F83",
    900: "#065666"
  },
  purple: {
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659"
  },
  pink: {
    50: "#FFF5F7",
    100: "#FED7E2",
    200: "#FBB6CE",
    300: "#F687B3",
    400: "#ED64A6",
    500: "#D53F8C",
    600: "#B83280",
    700: "#97266D",
    800: "#702459",
    900: "#521B41"
  },
  linkedin: {
    50: "#E8F4F9",
    100: "#CFEDFB",
    200: "#9BDAF3",
    300: "#68C7EC",
    400: "#34B3E4",
    500: "#00A0DC",
    600: "#008CC9",
    700: "#0077B5",
    800: "#005E93",
    900: "#004471"
  },
  facebook: {
    50: "#E8F4F9",
    100: "#D9DEE9",
    200: "#B7C2DA",
    300: "#6482C0",
    400: "#4267B2",
    500: "#385898",
    600: "#314E89",
    700: "#29487D",
    800: "#223B67",
    900: "#1E355B"
  },
  messenger: {
    50: "#D0E6FF",
    100: "#B9DAFF",
    200: "#A2CDFF",
    300: "#7AB8FF",
    400: "#2E90FF",
    500: "#0078FF",
    600: "#0063D1",
    700: "#0052AC",
    800: "#003C7E",
    900: "#002C5C"
  },
  whatsapp: {
    50: "#dffeec",
    100: "#b9f5d0",
    200: "#90edb3",
    300: "#65e495",
    400: "#3cdd78",
    500: "#22c35e",
    600: "#179848",
    700: "#0c6c33",
    800: "#01421c",
    900: "#001803"
  },
  twitter: {
    50: "#E5F4FD",
    100: "#C8E9FB",
    200: "#A8DCFA",
    300: "#83CDF7",
    400: "#57BBF5",
    500: "#1DA1F2",
    600: "#1A94DA",
    700: "#1681BF",
    800: "#136B9E",
    900: "#0D4D71"
  },
  telegram: {
    50: "#E3F2F9",
    100: "#C5E4F3",
    200: "#A2D4EC",
    300: "#7AC1E4",
    400: "#47A9DA",
    500: "#0088CC",
    600: "#007AB8",
    700: "#006BA1",
    800: "#005885",
    900: "#003F5E"
  }
};
var colors_default = colors;

// src/foundations/radius.ts
var radii = {
  none: "0",
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px"
};
var radius_default = radii;

// src/foundations/shadows.ts
var shadows = {
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
  "dark-lg": "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px"
};
var shadows_default = shadows;

// src/foundations/transition.ts
var transitionProperty = {
  common: "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
  colors: "background-color, border-color, color, fill, stroke",
  dimensions: "width, height",
  position: "left, right, top, bottom",
  background: "background-color, background-image, background-position"
};
var transitionTimingFunction = {
  "ease-in": "cubic-bezier(0.4, 0, 1, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.2, 1)",
  "ease-in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
};
var transitionDuration = {
  "ultra-fast": "50ms",
  faster: "100ms",
  fast: "150ms",
  normal: "200ms",
  slow: "300ms",
  slower: "400ms",
  "ultra-slow": "500ms"
};
var transition = {
  property: transitionProperty,
  easing: transitionTimingFunction,
  duration: transitionDuration
};
var transition_default = transition;

// src/foundations/z-index.ts
var zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1e3,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
};
var z_index_default = zIndices;

// src/foundations/blur.ts
var blur = {
  none: 0,
  sm: "4px",
  base: "8px",
  md: "12px",
  lg: "16px",
  xl: "24px",
  "2xl": "40px",
  "3xl": "64px"
};
var blur_default = blur;

// src/foundations/index.ts
var foundations = {
  breakpoints: breakpoints_default,
  zIndices: z_index_default,
  radii: radius_default,
  blur: blur_default,
  colors: colors_default,
  ...typography_default,
  sizes: sizes_default,
  shadows: shadows_default,
  space: spacing,
  borders: borders_default,
  transition: transition_default
};

// src/semantic-tokens.ts
var semanticTokens = {
  colors: {
    "chakra-body-text": { _light: "gray.800", _dark: "whiteAlpha.900" },
    "chakra-body-bg": { _light: "white", _dark: "gray.800" },
    "chakra-border-color": { _light: "gray.200", _dark: "whiteAlpha.300" },
    "chakra-placeholder-color": { _light: "gray.500", _dark: "whiteAlpha.400" }
  }
};

// src/styles.ts
var styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "chakra-body-text",
      bg: "chakra-body-bg",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base"
    },
    "*::placeholder": {
      color: "chakra-placeholder-color"
    },
    "*, *::before, &::after": {
      borderColor: "chakra-border-color",
      wordWrap: "break-word"
    }
  }
};

// ../../utilities/shared-utils/dist/index.esm.js
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}

// src/utils/is-chakra-theme.ts
var requiredChakraThemeKeys = [
  "borders",
  "breakpoints",
  "colors",
  "components",
  "config",
  "direction",
  "fonts",
  "fontSizes",
  "fontWeights",
  "letterSpacings",
  "lineHeights",
  "radii",
  "shadows",
  "sizes",
  "space",
  "styles",
  "transition",
  "zIndices"
];
function isChakraTheme(unit) {
  if (!isObject(unit)) {
    return false;
  }
  return requiredChakraThemeKeys.every((propertyName) => Object.prototype.hasOwnProperty.call(unit, propertyName));
}

// src/index.ts
var direction = "ltr";
var config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra"
};
var theme = {
  semanticTokens,
  direction,
  ...foundations,
  components,
  styles,
  config
};
var src_default = theme;
export {
  src_default as default,
  isChakraTheme,
  requiredChakraThemeKeys,
  theme
};
