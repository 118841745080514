import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from './App';
import { theme, ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { SessionActionsContextProvider } from './services/session-action/session-action';
import { SessionStore } from './services/session-action/contexts/session-store';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <SessionStore.Provider>
        <SessionActionsContextProvider>
          <App />
        </SessionActionsContextProvider>
      </SessionStore.Provider>
    </BrowserRouter>
  </ChakraProvider>
);
