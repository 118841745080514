import { Permissions } from '../../screens/main-admin-page/model/permissions-formatter';

export const pages = [
  {
    id: 1,
    title: 'Управление производством',
    page: '/kitchen-leader',
    permission: Permissions.OrderManager,
  },
  {
    id: 4,
    title: 'Горячий цех',
    page: '/cook',
    link: `HOT`,
    permission: Permissions.HotDepartment,
  },
  {
    id: 5,
    title: 'Холодный цех',
    page: '/cook',
    link: 'COLD',
    permission: Permissions.ColdDepartment,
  },
  {
    id: 6,
    title: 'Овощной цех',
    page: '/cook',
    link: 'VEGETABLES',
    permission: Permissions.VegetablesDepartment,
  },
  {
    id: 7,
    title: 'Кондитерский цех',
    page: '/cook',
    link: 'CONFECTIONERY',
    permission: Permissions.Confectionery,
  },
  {
    id: 8,
    title: 'Мясной цех',
    page: '/cook',
    link: 'MEAT',
    permission: Permissions.MeatDepartment,
  },
  {
    id: 9,
    title: 'Административная панель',
    page: '/admin',
    permission: Permissions.Root,
  },
  {
    id: 10,
    title: 'Экран фасовки',
    page: '/packing-shop',
    permission: Permissions.Packer,
  },
  {
    id: 11,
    title: 'Управление планом',
    page: '/production-plan',
    permission: Permissions.PlanManager,
  },
  {
    id: 12,
    title: 'Внутренний заказ',
    page: '/production-plan',
    permission: Permissions.InternalPlanManager,
  },
];
