import {
  DatesStateEmpty,
  DatesStateError,
  DatesStateHasData,
  DatesStateInitial,
  DatesStateLoading,
} from './state-creator';
import { ArrayDates } from '../../interface';

export function getInitialState(): DatesStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): DatesStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): DatesStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): DatesStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(response: ArrayDates): DatesStateHasData {
  return {
    type: 'HAS_DATA',
    data: response,
    error: null,
  };
}
