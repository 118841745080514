import * as React from 'react';

import { SessionStore } from '../contexts/session-store';

export function useSignIn() {
  const sessionStore = SessionStore.use();

  return React.useCallback(
    async (login: string, password: string, permission: string) => {
      const data = { login, password, permission };

      console.log('PERMISSION useSignIn', permission);
      try {
        if (data) {
          const basicAuth = `${btoa(`${login}:${password}`)}`;
          localStorage.setItem('access', basicAuth);
          localStorage.setItem('permission', permission);
          sessionStore.setSession(basicAuth);
          if (permission[0]) {
            sessionStore.setPermission(permission[0]);
          }
        } else {
          sessionStore.setSessionError('UNKNOWN_ERROR');
        }
      } catch (e) {
        const error = e instanceof Error ? e : new Error('Failed to Log in');
        sessionStore.setSessionError(error.message);
      }
    },
    [sessionStore]
  );
}
