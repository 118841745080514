import React from 'react';
import {
  Button,
  Center,
  ChakraProvider,
  Flex,
  Grid,
  Input,
  theme,
} from '@chakra-ui/react';
import { AuthorizationModel } from '../model';

export const LoginView = AuthorizationModel.modelClient((props) => {
  const handleInputChangeLogin = (e: any) => {
    const inputValue = e.target.value;
    props.model.setLogin(inputValue);
  };

  const handleInputChangePassword = (e: any) => {
    const inputValue = e.target.value;
    props.model.setPassword(inputValue);
  };

  return (
    <ChakraProvider theme={theme}>
      <Flex
        width="100vw"
        height="100vh"
        alignContent="center"
        justifyContent="center"
      >
        <Center>
          <Grid templateColumns="repeat(1, 2fr)" gap={6}>
            <Input
              placeholder="Login"
              value={props.model.login}
              onChange={handleInputChangeLogin}
            />
            <Input
              type="password"
              placeholder="Password"
              value={props.model.password}
              onChange={handleInputChangePassword}
            />
            <Button
              // disabled={props.model.isButtonDisabled}
              onClick={() => props.model.authorization()}
              colorScheme="blue"
            >
              Login
            </Button>
          </Grid>
        </Center>
      </Flex>
    </ChakraProvider>
  );
});
