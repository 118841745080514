export const styles = {
  rowFlex: {
    alightItem: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: '5px',
    marginRight: '50px',
  },
};
