import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { PackingShopModel } from '../model';
import { PlateWithNumber } from '../../../components/core/plate-with-number';
import { PeriodicityBlock } from '../../сook-page/view/periodicity-block/periodicity-block';
import { SearchIcon } from '@chakra-ui/icons';
import { useSessionActions } from '../../../services/session-action/session-action';
import { AssemblyChartsItemsResponse } from '../interface/interface';
import { useFilter } from '../hooks/useFilter';
import { ModalPackingShop } from './modal-with-blur/modal-packing-shop';
import { useNavigate } from 'react-router-dom';

export const PackingShopView = PackingShopModel.modelClient((props) => {
  const { signOut } = useSessionActions();

  const { search, filterData, searchFilter, clearFilter } = useFilter(
    props.model.packingPlace.data
  );

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading>Цех фасовки</Heading>
        {props.model.error.length > 0 && (
          <Text fontSize="15px" color="tomato" marginLeft="20px">
            {props.model.error}
          </Text>
        )}
        <SimpleGrid>
          <Button marginTop="12px" colorScheme="red" onClick={signOut}>
            Выйти
          </Button>
        </SimpleGrid>
      </Flex>
      <Box h="24px" />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="tel"
          placeholder="Начните вводить название продукта"
          value={search}
          onChange={searchFilter}
        />
      </InputGroup>
      <Box h="24px" />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="left">Продукт</Th>
            <Th textAlign="center">Осталось упаковать</Th>
            <Th textAlign="center">Очередность</Th>
            <Th textAlign="left">Этикетка</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {filterData &&
            filterData.map((item) => (
              <ItemRow
                key={item.id}
                {...item}
                model={props.model}
                clearFilter={clearFilter}
              />
            ))}
        </Tbody>
      </Table>
    </div>
  );
});

interface ProductDoneProps extends AssemblyChartsItemsResponse {
  model: PackingShopModel;
  clearFilter: () => void;
}

const ItemRow = (props: ProductDoneProps) => {
  const navigate = useNavigate();
  const navigateToProductDetails = (id: number, parentCount: number) => {
    navigate(`/product/${id}`, {
      state: {
        parentCount,
        previousPage: '/packing-shop',
      },
    });
  };

  return (
    <Tr key={props.id}>
      <Td
        onClick={() => navigateToProductDetails(props.id, props.count)}
        textAlign="left"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>{props.productTitle}</Link>
      </Td>
      <Td textAlign="left">
        <Center>
          <PlateWithNumber title={Number(props.count)} />
        </Center>
      </Td>
      <Td isNumeric>
        <Center>
          <PeriodicityBlock title={Number(props.orderPacking)} />
        </Center>
      </Td>
      <Td
        onClick={() => navigateToProductDetails(props.id, props.count)}
        textAlign="left"
        justifyContent="center"
        alignItems="center"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>{props.label}</Link>
      </Td>
      <Td>
        <Center>
          <ModalPackingShop
            clearFilter={props.clearFilter}
            available={props.available}
            onChangeCount={props.model.setCountProduct}
            modalTitle={props?.forProduct?.productTitle}
            onChangeLabel={props.model.setIsAddLabel}
            handleSubmit={() => props.model.completeAssemblyChartItem(props.id)}
          />
        </Center>
      </Td>
    </Tr>
  );
};
