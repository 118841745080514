export const styles = {
  blockContainer: {
    backgroundColor: '#BCBCBC',
  },

  blockTextTitle: {
    fontFamily: 'Montserrat',
    width: '240px',
  },

  blockTextProduct: {
    fontFamily: 'Montserrat',
  },
};
