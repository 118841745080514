import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { styles } from './modal-kitchener-styles';
import { ButtonWithNumbers } from './button-with-numbers';
import { ProgressButton } from '../button-charka-progress';

interface ModalElementInterface {
  modalTitle: string;
  onChangeCount: (data: string) => void;
  onChangeLabel: (data: boolean) => void;
  handleSubmit: () => void;
  available: number;
  buttonAvailable?: boolean;
  productDetail?: boolean;
}

export enum Step {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Done = 'Done',
}

const DURATION_MS = 2000;
const UPDATE_INTERVAL_MS = 100;

const MAX_LENGTH = 9;

export const ModalKitchener: FC<ModalElementInterface> = ({
  modalTitle,
  onChangeCount,
  handleSubmit,
  available,
  buttonAvailable,
  productDetail,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [valueInput, setValueInput] = useState('');

  const handlePressNumberButton = (num: number | string) => {
    if (valueInput.length < MAX_LENGTH - 1) {
      const newValue = valueInput + String(num);
      setValueInput(newValue);
    }
  };

  const clearNumberInput = () => {
    setValueInput(valueInput.slice(0, -1));
  };

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    setValueInput(inputValue.replace(',', '.'));
  };

  const [step, setStep] = React.useState<Step>(Step.Initial);
  /* Progress percentage from 100 to 0 */
  const [percentage, setPercentage] = React.useState(100);
  const interval = React.useRef<number>();

  const clear = React.useCallback(() => {
    window.clearInterval(interval.current);
    interval.current = undefined;
    setPercentage(100);
  }, []);

  React.useEffect(() => {
    if (percentage <= 0) {
      onChangeCount(valueInput.replace(',', '.'));
      handleSubmit();
      clear();
      if (available === 0) {
        setStep(Step.Done);
      } else {
        setStep(Step.Initial);
      }
    }
  }, [percentage, clear]);

  React.useEffect(() => clear, [clear]);

  function handleStart() {
    onClose();
    setStep(Step.InProgress);
    interval.current = window.setInterval(() => {
      setPercentage((t) => t - 100 / (DURATION_MS / UPDATE_INTERVAL_MS));
    }, UPDATE_INTERVAL_MS);
  }

  function handleCancel() {
    setStep(Step.Initial);
    clear();
  }

  // console.log('BUTTON AVALIABLE', buttonAvailable);
  // console.log(' AVALIABLE', available);
  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        backgroundColor="transparent"
      >
        {productDetail ? (
          <>
            <ProgressButton
              disabled={available === 0}
              onOpen={onOpen}
              handleCancel={handleCancel}
              step={step}
              percentage={percentage}
              available={available}
            />
          </>
        ) : (
          <ProgressButton
            disabled={available === 0}
            onOpen={onOpen}
            handleCancel={handleCancel}
            step={step}
            percentage={percentage}
            available={available}
          />
        )}
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={styles.titleStyleFont}>
            {modalTitle}
            <Box style={styles.titleStyleFont}>
              Укажите, сколько продукции приготовлено
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap="20px">
              <GridItem>
                <SimpleGrid spacing="20px">
                  <Flex>
                    <ButtonWithNumbers
                      number={7}
                      onClick={() => handlePressNumberButton(7)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={8}
                      onClick={() => handlePressNumberButton(8)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={9}
                      onClick={() => handlePressNumberButton(9)}
                    />
                  </Flex>

                  <Flex>
                    <ButtonWithNumbers
                      number={4}
                      onClick={() => handlePressNumberButton(4)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={5}
                      onClick={() => handlePressNumberButton(5)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={6}
                      onClick={() => handlePressNumberButton(6)}
                    />
                  </Flex>

                  <Flex>
                    <ButtonWithNumbers
                      number={3}
                      onClick={() => handlePressNumberButton(3)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={2}
                      onClick={() => handlePressNumberButton(2)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={1}
                      onClick={() => handlePressNumberButton(1)}
                    />
                  </Flex>

                  <Flex marginBottom="46px">
                    <ButtonWithNumbers
                      number="."
                      onClick={() => handlePressNumberButton('.')}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number={0}
                      onClick={() => handlePressNumberButton(0)}
                    />
                    <Box marginLeft="6px" />
                    <ButtonWithNumbers
                      number="X"
                      onClick={() => clearNumberInput()}
                    />
                  </Flex>
                </SimpleGrid>
              </GridItem>

              <GridItem>
                <Flex>
                  <Input
                    style={
                      Number(valueInput) > available
                        ? styles.inputTextError
                        : styles.inputText
                    }
                    value={valueInput}
                    onChange={handleChange}
                  />

                  <Text style={styles.volumeNumbers}>/{available}</Text>
                </Flex>
                <div style={{ marginTop: '46px' }}>
                  <Button
                    disabled={Number(valueInput) > available}
                    onClick={handleStart}
                    size="lg"
                    colorScheme="blue"
                  >
                    Готово к отгрузке
                  </Button>
                </div>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
