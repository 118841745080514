import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-unresolved
// import { API_URL } from '@env';

import { HttpLogger } from './http-logger';
import { SessionStore } from '../session-action/contexts/session-store';
import React from 'react';

export interface HttpClientQueryParams {
  [key: string]: any;
}

export interface HttpMethodProps {
  data?: any;
  headers?: Record<string, string>;
  params?: HttpClientQueryParams;
  useAuthorization?: boolean;
}

export type HttpGetMethodProps = Omit<HttpMethodProps, 'data'>;

const LOGGER_ENABLED = process.env.NODE_ENV === 'development';

export class HttpService {
  private readonly _baseUrl: string;

  private readonly _axiosInstance: AxiosInstance;

  private _isRefreshingByInterceptor = false;

  public get axiosInstance() {
    return this._axiosInstance;
  }

  private _logger = LOGGER_ENABLED ? new HttpLogger() : null;

  public constructor(
    public options: AxiosRequestConfig = {},
    private readonly onLogout?: () => void
  ) {
    // const apiUrl = process.env.REACT_APP_API_URL;
    // if (!apiUrl) {
    //   throw new Error(
    //     'Variable API_URL not found. Specify it in .env or .env.development file'
    //   );
    // }

    this._baseUrl = '';
    this._axiosInstance = axios.create({ baseURL: this._baseUrl, ...options });
    this.attachInterceptors();
  }

  public async get<T>(
    relativeUrl: string,
    props?: HttpGetMethodProps
  ): Promise<AxiosResponse<T>> {
    const axiosConfig = await this.getAxiosConfig<T>(props);
    return this._axiosInstance.get<T, AxiosResponse<T>, T>(
      relativeUrl,
      axiosConfig
    );
  }

  public async post<T>(
    relativeUrl: string,
    props?: HttpMethodProps
  ): Promise<AxiosResponse<T>> {
    const axiosConfig = await this.getAxiosConfig<T>(props);
    return this._axiosInstance.post<T, AxiosResponse<T>, T>(
      relativeUrl,
      props?.data || {},
      axiosConfig
    );
  }

  public async put<T>(
    relativeUrl: string,
    props?: HttpMethodProps
  ): Promise<AxiosResponse<T>> {
    const axiosConfig = await this.getAxiosConfig<T>(props);
    return this._axiosInstance.put<T, AxiosResponse<T>, T>(
      relativeUrl,
      props?.data || {},
      axiosConfig
    );
  }

  public async delete<T>(
    relativeUrl: string,
    props?: HttpGetMethodProps
  ): Promise<AxiosResponse<T>> {
    const axiosConfig = await this.getAxiosConfig<T>(props);
    return this._axiosInstance.delete<T, AxiosResponse<T>, T>(
      relativeUrl,
      axiosConfig
    );
  }

  private async getAxiosConfig<T>(
    props: HttpMethodProps = {
      useAuthorization: true,
    }
  ): Promise<AxiosRequestConfig<T>> {
    const useAuthorization = props?.useAuthorization ?? true;

    if (!useAuthorization) {
      return props;
    }

    const token = await HttpService.getAccessTokenFromAsyncStorage();

    if (!token) {
      // todo: add logout logic
      this.logout();
    }

    return {
      params: props.params ?? {},
      headers: {
        ...props.headers,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Basic ${token}`,
      },
    };
  }

  private attachInterceptors() {
    this._axiosInstance.interceptors.response.use(
      (response) => {
        this._logger?.logRequestSuccess(response);
        return response;
      },
      async (error) => {
        if (!axios.isAxiosError(error) || this._isRefreshingByInterceptor) {
          this._isRefreshingByInterceptor = false;
          return Promise.reject(error);
        }

        // const originalRequest = error.config;

        this._logger?.logRequestFailed(error);

        if (error.response?.status === 401) {
          this._isRefreshingByInterceptor = true;
          const sessionStore = SessionStore.use();
          return React.useCallback(async () => {
            sessionStore.unsetSession();
          }, [sessionStore]);

          // return new Promise((resolve, reject) => {
          //   const token = localStorage.getItem('access');
          //   if (token) {
          //     axios({
          //       ...originalRequest,
          //       headers: {
          //         ...originalRequest.headers,
          //         Authorization: `Basic ${token}`,
          //       },
          //     })
          //       .then((response) => {
          //         this._logger?.logRetrySuccess(response);
          //         resolve(response);
          //       })
          //       .catch((error) => {
          //         this._logger?.logRetryFailed(error);
          //         reject(error);
          //       });
          //   }
          // });
        }

        return Promise.reject(error);
      }
    );
  }

  // private async refreshTokensSilently() {
  //   const refreshToken = await HttpService.getRefreshTokenFromAsyncStorage();
  //
  //   if (!refreshToken) {
  //     await this.logout();
  //     return Promise.reject();
  //   }
  //
  //   try {
  //     const response = await this._axiosInstance.post<TokensPair>(
  //       '/v1/login/refresh',
  //       {
  //         refresh_token: refreshToken,
  //       }
  //     );
  //
  //     if (response.status === 200) {
  //       this._logger?.logNewTokens(response);
  //       await localStorage.setItem('access', response.data.token);
  //       await localStorage.setItem('refresh', response.data.refresh_token);
  //       return Promise.resolve(response.data.token);
  //     }
  //
  //     return Promise.reject();
  //   } catch (error) {
  //     if (!axios.isAxiosError(error)) {
  //       console.error(`Cannot refresh token.\nUnknown error`);
  //       return Promise.reject(error);
  //     }
  //
  //     this._logger?.logRequestFailed(error);
  //
  //     if (error.response?.status === 401) {
  //       await this.logout();
  //     }
  //
  //     return Promise.reject(error);
  //   } finally {
  //     this._isRefreshingByInterceptor = false;
  //   }
  //
  //   return Promise.reject();
  // }

  private static async getAccessTokenFromAsyncStorage() {
    return localStorage.getItem('access');
  }

  private async logout() {
    await localStorage.removeItem('access');
    this.onLogout?.();
  }
}
