import React, { FC } from 'react';
import { styles } from './modal-kitchener-styles';
import { Box, Center, Text } from '@chakra-ui/react';

interface ButtonWithNumbersInterface {
  number: number | string;
  onClick: () => void;
}

export const ButtonWithNumbers: FC<ButtonWithNumbersInterface> = ({
  number,
  onClick,
}) => (
  <Box as="button" style={styles.numberBox} onClick={onClick}>
    <Center>
      <Text style={styles.buttonTextStyle}>{number}</Text>
    </Center>
  </Box>
);
