import React from 'react';

export const SplitIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0375 1.87093L18.0775 3.91093L13.9975 7.99093L16.0091 10.0026L20.0891 5.92259L22.1291 7.96259C22.229 8.06049 22.3556 8.12663 22.493 8.15269C22.6304 8.17874 22.7724 8.16354 22.9012 8.109C23.03 8.05446 23.1397 7.96302 23.2166 7.84621C23.2935 7.7294 23.3341 7.59244 23.3333 7.45259V1.37509C23.3333 0.978427 23.0216 0.66676 22.625 0.66676H16.5475C16.4076 0.665939 16.2707 0.706531 16.1538 0.783422C16.037 0.860313 15.9456 0.970062 15.8911 1.09884C15.8365 1.22761 15.8213 1.36965 15.8474 1.50705C15.8734 1.64445 15.9396 1.77106 16.0375 1.87093ZM7.45246 0.66676H1.37496C0.978293 0.66676 0.666626 0.978427 0.666626 1.37509V7.45259C0.666626 8.09009 1.43163 8.40176 1.87079 7.94843L3.91079 5.90843L10.5833 12.5668V21.9168C10.5833 22.6959 11.2208 23.3334 12 23.3334C12.7791 23.3334 13.4166 22.6959 13.4166 21.9168V12.0001C13.4166 11.6318 13.2608 11.2634 13.0058 10.9943L5.92246 3.89676L7.96246 1.85676C8.40163 1.43176 8.08996 0.66676 7.45246 0.66676Z"
      fill="#5A6184"
    />
  </svg>
);
