import React from 'react';
import { AuthorizationModel } from './model';
import { LoginView } from './view';
import { useSessionActions } from '../../services/session-action/session-action';

export const Login = (props: any) => {
  const { signIn } = useSessionActions();

  return (
    <AuthorizationModel.Provider onAuthorize={signIn}>
      <LoginView />
    </AuthorizationModel.Provider>
  );
};
