import {
  ProductCardPageStateEmpty,
  ProductCardPageStateError,
  ProductCardPageStateHasData,
  ProductCardPageStateInitial,
  ProductCardPageStateLoading,
} from './state-creator';
import { AssemblyChartItemChildResponse } from '../../interface/type';

export function getInitialState(): ProductCardPageStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): ProductCardPageStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): ProductCardPageStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): ProductCardPageStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(
    response: Array<AssemblyChartItemChildResponse>
): ProductCardPageStateHasData {
  return { type: 'HAS_DATA', data: response, error: null };
}
