import * as React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import '@fontsource/montserrat';
import { AdminPage } from './screens/main-admin-page';
import { CookPage } from './screens/сook-page';
import { useSessionRedirect } from './services/session-action/hook/use-session';
import { ProductCard } from './screens/сook-page/product-card';
import { PackingShop } from './screens/packing-shop';
import { KitchenLeader } from './screens/kitchen-leader-page';
import { SessionStore } from './services/session-action/contexts/session-store';
import { Permissions } from './screens/main-admin-page/model/permissions-formatter';
import { MenuContext } from './context/menu-context';
import { Login } from './screens/login';
import { ProductionPlan } from './screens/production-plan';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = SessionStore.modelClient((props) => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log('PERMISSIONS APP TSX', props.model.permissions);

  useSessionRedirect({
    isAuthRoute: location.pathname.includes('/login'),
    onNavigateToDefault: () => {
      switch (props.model.permissions) {
        case Permissions.Root:
          navigate('/admin');
          break;
        case Permissions.OrderManager:
          navigate('/kitchen-leader');
          break;
        case Permissions.Packer:
          navigate('/packing-shop');
          break;
        case Permissions.InternalPlanManager:
          navigate('/production-plan');
          break;
        case Permissions.PlanManager:
          navigate('/production-plan');
          break;
        default:
          navigate('/cook');
          break;
      }
    },
    onNavigateToAuth: () => {
      navigate('/login');
    },
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <MenuContext permission={props.model.permissions}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/cook" element={<CookPage />} />
          <Route path="/product/:productId" element={<ProductCard />} />
          <Route path="/packing-shop" element={<PackingShop />} />
          <Route path="/kitchen-leader" element={<KitchenLeader />} />
          <Route path="/production-plan" element={<ProductionPlan />} />
        </Routes>
      </MenuContext>
    </>
  );
});
