import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Input,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useSessionActions } from '../../../services/session-action/session-action';
import { ModalAddNewItem } from './modal/modal-add-new-item';
import { ProductionPlanModel } from '../model';
import Select from 'react-select';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { PlanClass } from '../model/plan/plan-class';
import { ApprovePlan } from './modal/approve-plan';
import { ProgressBar } from 'react-loader-spinner';
import { Plan, UpdatePlanData } from '../interface';
import { Permissions } from '../../main-admin-page/model/permissions-formatter';
import { Popconfirm } from 'antd';

require('core-js/actual/array/group-by');
require('core-js/actual/array/group-by-to-map');

export const ProductionPlanView = ProductionPlanModel.modelClient((props) => {
  const { signOut } = useSessionActions();

  const statusOptions = props.model.dates.data?.map((data) => ({
    label: `Заказ на ${data.date} - ${
      data.approved ? 'Утвержден' : 'Не утвержден'
    }`,
    status: data.approved,
    date: data.date,
  }));
  const styleMap: any = {
    false: '#C11D1D',
    true: '#2557B8',
  };
  const colourStyles: any = {
    singleValue: (
      provided: {
        boxSizing: string;
        color: string;
        gridArea: string;
        label: string;
        marginLeft: number;
        marginRight: number;
        maxWidth: string;
        overflow: string;
        textOverflow: string;
        whiteSpace: string;
      },
      { data }: { data: { date: string; label: string; status: string } }
    ) => ({
      ...provided,
      color: styleMap[data.status] ?? 'defaultColor',
      fontWeight: 'bold',
    }),
  };
  function guidGenerator() {
    const S4 = function () {
      // eslint-disable-next-line no-bitwise
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const result = props.model.planForTotalRender
    .map((item, i) => item.plan)
    .flat()
    .groupBy((item: Plan) => item.sku);

  const permission = localStorage.getItem('permission');
  const permissionArr: string[] | undefined = permission?.split(',');

  const text = 'Внимание!';
  const description = 'Обновить уверены, что хотите обновить заявку?';

  const confirm = () => {
    props.model.planRefresh();
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '48px auto 0' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <SimpleGrid columns={1} spacing={10}>
          {/* <Heading>Смена от 21 апреля</Heading> */}
          <Flex alignSelf="flex-start" bg="#fff">
            <Grid>
              <Text fontSize="32px" fontWeight="500" lineHeight="32px">
                Статус сбора заказа
              </Text>

              {props.model.parsingStates.type === 'HAS_DATA' &&
                props.model.parsingStates.data.data.map((el) => (
                  <Flex key={guidGenerator()}>
                    <Center>
                      <Text
                        fontSize="18px"
                        fontWeight="500"
                        lineHeight="20px"
                        marginTop="20px"
                      >
                        {el.source}
                      </Text>
                      <Box marginLeft="20px" pt="10px">
                        {el.isParsed ? (
                          <CheckIcon color="blue" />
                        ) : (
                          <CloseIcon color="red" />
                        )}
                      </Box>
                    </Center>
                  </Flex>
                ))}
            </Grid>

            <Grid templateRows="repeat(2, 1fr)">
              <Center marginLeft="105px" marginTop="20px">
                {!permissionArr?.includes(Permissions.InternalPlanManager) && (
                  <ApprovePlan />
                )}

                <ModalAddNewItem modalTitle="Добавление новой позиции" />

                {!permissionArr?.includes(Permissions.InternalPlanManager) && (
                  <Popconfirm
                    placement="bottomLeft"
                    title={text}
                    description={description}
                    onConfirm={confirm}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button
                      disabled={props.model.refreshButtonDisabled}
                      whiteSpace="initial"
                      colorScheme="blue"
                      marginLeft="16px"
                    >
                      {props.model.refreshButtonDisabled ? (
                        <ProgressBar
                          height="80"
                          width="80"
                          ariaLabel="progress-bar-loading"
                          wrapperStyle={{}}
                          wrapperClass="progress-bar-wrapper"
                          borderColor="#F4442E"
                          barColor="#51E5FF"
                        />
                      ) : (
                        'Обновить заявку'
                      )}
                    </Button>
                  </Popconfirm>
                )}
              </Center>

              <Center>
                <Box style={{ width: '400px' }}>
                  {props.model.dates.data &&
                    props.model.dates.type === 'HAS_DATA' && (
                      <Select
                        isSearchable={false}
                        value={props.model.selectedPlanValue}
                        placeholder="Выбрать дату"
                        styles={colourStyles}
                        options={statusOptions}
                        onChange={(data: any) => {
                          if (data) {
                            props.model.setDate(data.date);
                            props.model.setApprove(data.status);
                            props.model.setPlanDate(data);
                          }
                        }}
                      />
                    )}
                </Box>
              </Center>
            </Grid>

            <Button
              style={{
                marginLeft: '90px',
                marginTop: '20px',
              }}
              colorScheme="red"
              onClick={signOut}
            >
              Выйти
            </Button>
          </Flex>
          {/* <Heading>Общая заявка</Heading> */}
        </SimpleGrid>
      </Flex>
      <Box h="24px" />

      <Box
        style={{
          backgroundColor: '#E0E0EA',
          borderRadius: '12px',
          padding: '12px',
          marginTop: '20px',
        }}
        onClick={props.model.setHideTotalOrder}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            style={{
              fontSize: '32px',
              fontWeight: '500',
              fontFamily: 'Montserrat',
            }}
          >
            Общий заказ
          </Heading>
          {!props.model.hideTotalOrder ? (
            <ChevronDownIcon fontSize="30px" />
          ) : (
            <ChevronUpIcon fontSize="30px" />
          )}
        </Flex>
      </Box>

      <Table variant="simple">
        {props.model.hideTotalOrder && (
          <>
            <Thead>
              <Tr>
                <Th textAlign="left">Артикул</Th>
                <Th textAlign="left">Продукт</Th>
                <Th textAlign="center">Количество</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(result).map((key) => {
                const list = result[key];
                return (
                  <Tr key={list[0].id}>
                    <Td textAlign="left">{list[0].sku}</Td>
                    <Td textAlign="left">{list[0].title}</Td>
                    <Td isNumeric textAlign="left">
                      <Input
                        disabled
                        w="150px"
                        value={list.reduce(
                          (t: number, v: Plan) => (t += v.count),
                          0
                        )}
                        placeholder="Количество"
                        marginLeft="20px"
                        borderColor="#BCBCBC"
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </>
        )}
      </Table>

      {props.model.plan.type === 'HAS_DATA' &&
        props.model.plan.data.map((item) => (
          <ItemRow key={item.source.shop.id} item={item} />
        ))}
    </div>
  );
});

interface ItemRowProps {
  item: PlanClass;
}

const ItemRow: FC<ItemRowProps> = ProductionPlanModel.modelClient((props) => (
  // const navigate = useNavigate();
  <React.Fragment key={props.item.source.shop.id}>
    <Box
      style={{
        backgroundColor: '#E0E0EA',
        borderRadius: '12px',
        padding: '12px',
        marginTop: '20px',
      }}
      onClick={props.item.setIsHidePlanElements}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading
          style={{
            fontSize: '32px',
            fontWeight: '500',
            fontFamily: 'Montserrat',
          }}
        >
          {props.item.source.shop.address === 'Суточные'
            ? `${props.item.source.shop.address}`
            : `${props.item.source.shop.jurpersonName}. 
          ${props.item.source.shop.address}`}
        </Heading>
        {!props.item.isHidePlanElements ? (
          <ChevronDownIcon fontSize="30px" />
        ) : (
          <ChevronUpIcon fontSize="30px" />
        )}
      </Flex>
    </Box>

    <Table variant="simple">
      {props.item.isHidePlanElements && (
        <>
          <Thead>
            <Tr>
              <Th textAlign="left">Артикул</Th>
              <Th textAlign="left">Продукт</Th>
              <Th textAlign="center">Количество</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.item.plan().map((data) => {
              const handleBlur = () => {
                const dataPose: Array<UpdatePlanData> = [
                  {
                    shopId: props.item.source.shop.id,
                    plan: [
                      {
                        sku: data.plan.sku,
                        title: data.plan.title,
                        count: data.quantity(),
                      },
                    ],
                  },
                ];
                props.model.updatePlan(dataPose);
              };

              const handleChange = async (content: string) => {
                await data.setQuantity(content);
                handleBlur();
              };

              return (
                <Tr key={data.plan.id}>
                  <Td textAlign="left">{data.plan.sku}</Td>
                  <Td textAlign="left">{data.plan.title}</Td>
                  <Td isNumeric textAlign="left">
                    <Input
                      disabled={props.model.approve}
                      w="150px"
                      value={data.quantity()}
                      onChange={(event) => handleChange(event.target.value)}
                      placeholder="Количество"
                      marginLeft="20px"
                      borderColor="#BCBCBC"
                      // onBlur={handleBlur}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </>
      )}
    </Table>
  </React.Fragment>
));
