import {
  ItemsStateEmpty,
  ItemsStateError,
  ItemsStateHasData,
  ItemsStateInitial,
  ItemsStateLoading,
} from './state-creator';
import { ItemsResponse } from '../../interface';
import { Items } from '../item';

export function getInitialState(): ItemsStateInitial {
  return { type: 'INITIAL', data: [], error: null };
}

export function getLoadingState(data: []): ItemsStateLoading {
  return { type: 'LOADING', data, error: null };
}

export function getEmptyState(): ItemsStateEmpty {
  return { type: 'EMPTY', data: [], error: null };
}

export function getErrorState(error: Error): ItemsStateError {
  return { type: 'ERROR', data: [], error };
}

export function getHasDataState(
  response: Array<ItemsResponse>
): ItemsStateHasData {
  return {
    type: 'HAS_DATA',
    data: response.map((data) => new Items(data)),
    error: null,
  };
}

export function addArrayForEditing(
  prevArray: Array<Items>,
  nextElem: Items
): ItemsStateHasData {
  return { type: 'HAS_DATA', data: [...prevArray, nextElem], error: null };
}
