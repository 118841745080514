import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { styles } from '../../../сook-page/view/modal-with-blur/modal-kitchener-styles';
import { ProductionPlanModel } from '../../model';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale';
import { addDays, format } from 'date-fns';
import { AddIcon } from '@chakra-ui/icons';
import { Permissions } from '../../../main-admin-page/model/permissions-formatter';
import { runInAction } from 'mobx';

registerLocale('ru', ru);

interface ModalAddNewItemProps {
  modalTitle: string;
}

export const ModalAddNewItem: FC<ModalAddNewItemProps> =
  ProductionPlanModel.modelClient((props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    function guidGenerator() {
      const S4 = function () {
        // eslint-disable-next-line no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
    }

    const handleClose = () => {
      props.model.setDisabledButtonCopy();
      props.model.cancelModalAddModels();
      onClose();
    };

    const permission = localStorage.getItem('permission');
    const permissionArr: string[] | undefined = permission?.split(',');

    const disabledDate = () => {
      if (props.model.dates.type === 'HAS_DATA') {
        return props.model.dates.data
          .filter((d) => d.approved)
          .map((d) => new Date(d.date));
      }
      return undefined;
    };

    return (
      <>
        <Box
          alignItems="center"
          justifyContent="center"
          backgroundColor="transparent"
        >
          <Button
            whiteSpace="initial"
            onClick={onOpen}
            colorScheme="blue"
            marginLeft="16px"
          >
            Добавить позицию
          </Button>
        </Box>
        <Modal onClose={handleClose} isOpen={isOpen} isCentered size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={styles.titleStyleFont}>
              {props.modalTitle}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid marginTop="10px">
                <GridItem colSpan={1}>
                  <Text>Дата исполнения заказа</Text>
                </GridItem>

                <GridItem colSpan={1} marginTop="24px">
                  {permissionArr?.includes(Permissions.InternalPlanManager) ? (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      minDate={addDays(new Date(), 1)}
                      selected={
                        new Date(props.model.dateSaveModal ?? new Date())
                      }
                      onChange={(date: Date) =>
                        props.model.setDateModal(
                          format(new Date(date), 'yyyy-MM-dd')
                        )
                      }
                      locale="ru"
                      excludeDates={disabledDate()}
                    />
                  ) : (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      minDate={addDays(new Date(), 1)}
                      selected={
                        new Date(props.model.dateSaveModal ?? new Date())
                      }
                      onChange={(date: Date) =>
                        props.model.setDateModal(
                          format(new Date(date), 'yyyy-MM-dd')
                        )
                      }
                      locale="ru"
                      excludeDates={disabledDate()}
                    />
                  )}
                </GridItem>
              </Grid>

              <Grid marginTop="30px">
                <GridItem colSpan={1}>
                  <Text>Заказчик</Text>
                </GridItem>

                <GridItem colSpan={1} marginTop="24px">
                  <Select
                    onChange={(event: any) => {
                      function handleSetShop() {
                        props.model.setShopId(
                          event?.target?.value?.match(/\d+(,\d+)?/)[0]
                        );
                      }
                      handleSetShop();
                    }}
                  >
                    <option selected hidden disabled value="">
                      Введите заказчика
                    </option>
                    {props.model.shops.data &&
                      props.model.shops.type === 'HAS_DATA' &&
                      props.model.shops.data.map((shop) => (
                        <option key={shop.id}>
                          {shop.id}. {shop.jurpersonName}. {shop.address}
                        </option>
                      ))}
                  </Select>
                </GridItem>
              </Grid>

              <Box marginTop="20px">
                <Text>Добавьте позиции</Text>
              </Box>

              {props.model.pickedItems.map((data) => {
                if (data.quantity === '') {
                  runInAction(() => {
                    props.model.setModalButtonSaveDisabled(true);
                  });
                } else {
                  runInAction(() => {
                    props.model.setModalButtonSaveDisabled(false);
                  });
                }
                const handleSelect = (event: any) => {
                  if (event.target.value) {
                    data.setTitle(
                      event.target.value.replace(/ *\([^)]*\) */g, '')
                    );
                    data.setSKU(
                      event.target.value.match(/\(([^)]+)\)/)[1]?.toString()
                    );
                  }
                };
                return (
                  <Grid key={data.id}>
                    <Flex marginBottom="5px" marginTop="5px">
                      <Select
                        value={
                          data.title ? `${data.title} (${data.sku})` : undefined
                        }
                        onChange={(event) => handleSelect(event)}
                      >
                        <option selected hidden disabled value="">
                          Выберите позицию
                        </option>
                        {props.model.items.data &&
                          props.model.items.type === 'HAS_DATA' &&
                          props.model.items.data.map((item) => (
                            <option key={item.sku}>
                              {item.title} ({item.sku})
                            </option>
                          ))}
                      </Select>
                      <Input
                        w="150px"
                        value={data.quantity}
                        onChange={(event) =>
                          data.setQuantity(event.target.value)
                        }
                        placeholder="Количество"
                        marginLeft="20px"
                        borderColor="#BCBCBC"
                      />
                    </Flex>
                  </Grid>
                );
              })}

              <IconButton
                onClick={() => props.model.setPickedProduct(guidGenerator())}
                variant="outline"
                colorScheme="blue"
                aria-label="Call Sage"
                fontSize="20px"
                icon={<AddIcon />}
                width="5%"
                mt="20px"
                mb="20px"
              />

              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={150}
                marginBottom="30px"
              >
                <GridItem>
                  <Button onClick={handleClose} size="lg" colorScheme="red">
                    Отменить
                  </Button>
                </GridItem>
                <GridItem>
                  <Flex>
                    <Button
                      disabled={props.model.disabledButtonCopy}
                      onClick={props.model.copyLastOrder}
                      size="lg"
                      colorScheme="gray"
                      marginRight="20px"
                    >
                      Скопировать прошлый заказ
                    </Button>
                    <Button
                      disabled={props.model.modalButtonSaveDisabled}
                      onClick={() =>
                        props.model.postPlan(
                          handleClose,
                          props.model.dateSaveModal
                        )
                      }
                      size="lg"
                      colorScheme="blue"
                    >
                      Сохранить
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  });
