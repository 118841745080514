/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { HttpService } from '../../../services/http-service';
import { CookingPageStates } from '../../сook-page/model/cooking-page-state';
import * as stateCreators from '../../сook-page/model/state-creator';
import { AssemblyChartsItemsArray } from '../../сook-page/interface/type';
import { toast } from 'react-toastify';

export class PackingShopModel {
  private readonly _httpService = new HttpService();

  private _isLoading = false;

  private _packingPlaceStore: CookingPageStates =
    stateCreators.getInitialState();

  public get packingPlace() {
    return this._packingPlaceStore;
  }

  public get isButtonDisabled() {
    return this._isLoading;
  }

  private _countProduct = '';

  public get countProduct() {
    return this._countProduct;
  }

  public setCountProduct(count: string) {
    this._countProduct = count;
    this._error = '';
  }

  private _error = '';

  private _isAddLabel = false;

  public get isAddLabel() {
    return this._isAddLabel;
  }

  public setIsAddLabel(data: boolean) {
    this._isAddLabel = data;
  }

  public setError(error: string) {
    this._error = error;
  }

  public get error() {
    return this._error;
  }

  public async getPackingOrders() {
    try {
      const url = '/api/assemblyChartsItems?cookingPlace=PACKING';
      await this._httpService.get<AssemblyChartsItemsArray>(url).then((res) => {
        if (res.data) {
          runInAction(() => {
            this._packingPlaceStore = stateCreators.getHasDataState(res.data);
          });
        }
        return res;
      });
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
      this.setError(e?.response?.data.message);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  public async completeAssemblyChartItem(id: number) {
    try {
      this._isLoading = true;
      const url = `/api/assemblyChartsItems`;

      await this._httpService
        .post(url, {
          data: this.formatData(id),
        })
        .then((res) => {
          console.log('RES COMPLETE: ', res.status);
        });
      this.getPackingOrders();
    } catch (e: any) {
      toast.error(
        `${e.response.data.message ? e.response.data.message : 'Error'}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  private formatData(id: number) {
    return {
      id,
      count: Number(this._countProduct),
      isAddLabel: this._isAddLabel,
    };
    this._countProduct = '';
    this._isAddLabel = false;
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private static makeModel() {
    const model = React.useMemo(() => new PackingShopModel(), []);
    useEffect(() => {
      model.getPackingOrders();
    }, [model]);

    return model;
  }

  private static ModelContext = React.createContext<PackingShopModel | null>(
    null
  );

  public static Provider(props: React.PropsWithChildren<object>) {
    const model = PackingShopModel.makeModel();

    return (
      <PackingShopModel.ModelContext.Provider value={model}>
        {props.children}
      </PackingShopModel.ModelContext.Provider>
    );
  }

  public static modelClient<P extends object>(
    Component: (props: P & { model: PackingShopModel }) => JSX.Element
  ) {
    const WrappedComponent = observer(Component);
    return function ModelClient(props: P) {
      const model = React.useContext(PackingShopModel.ModelContext);
      if (!model) {
        throw new Error('No model provider');
      }
      return <WrappedComponent {...props} model={model} />;
    };
  }
}
